import { WppButton } from '@platform-ui-kit/components-library-react'

import { t } from 'i18next'
import { AppDetailsLaunchApp } from 'pages/project/components/canvas/components/item/application/detailsModal/AppDetailsLaunchApp'
import { MayBeNull } from 'types/common/utils'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: MayBeNull<ApplicationItem>
  phaseId: string
  onClose: () => void
}

export const AppDetailsModalActions = ({ application, onClose, phaseId }: Props) => {
  return (
    <>
      {application ? (
        <AppDetailsLaunchApp application={application} onClose={onClose} phaseId={phaseId} />
      ) : (
        <WppButton variant="primary" size="m" onClick={onClose}>
          {t('common.btn_done')}
        </WppButton>
      )}
    </>
  )
}
