import { WppIconWarning, WppTooltip } from '@platform-ui-kit/components-library-react'

import { useCheckCaasRequirements } from 'hooks/application/useCheckCaasRequirements'
import { t } from 'i18next'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
  className?: string
}

export const LeanAppWarnings = ({ application, className }: Props) => {
  const { isCaasAppHavePermission, isCaasHasCurrentWorkspace } = useCheckCaasRequirements(application)

  if (!isCaasAppHavePermission) {
    return (
      <WppTooltip
        className={className}
        config={{ maxWidth: 'none' }}
        header={t('product.not_onboarded_alert.permission_title')!}
        text={t('product.not_onboarded_alert.permission_text')!}
      >
        <WppIconWarning />
      </WppTooltip>
    )
  }

  if (!isCaasHasCurrentWorkspace) {
    return (
      <WppTooltip
        className={className}
        config={{ maxWidth: 'none' }}
        header={t('product.not_onboarded_alert.workspace_title')!}
        text={t('product.not_onboarded_alert.workspace_text')!}
      >
        <WppIconWarning />
      </WppTooltip>
    )
  }

  if (application.isOutdated) {
    return (
      <WppTooltip
        className={className}
        config={{ maxWidth: 'none' }}
        text={t('product.outdated_app_alert.workspace_text')!}
        data-testid="phase-item-warning"
      >
        <WppIconWarning />
      </WppTooltip>
    )
  }

  return null
}
