import { WppTag } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { styleConfig } from 'pages/dashboard/components/projectsCardView/components/projectStatus/projectStatus.config'
import { ProjectStatus as Status } from 'types/projects/projects'

export const ProjectStatusTag = ({ status, className }: { status: keyof typeof Status; className?: string }) => {
  const { t } = useTranslation()

  return status !== Status.ACTIVE ? (
    <WppTag label={t(styleConfig[status].label)} variant={styleConfig[status].variant} className={className}>
      {styleConfig[status].icon}
    </WppTag>
  ) : null
}
