import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  showFileDetailsModal,
  useShowFileDetailsModal,
} from 'pages/project/components/files/components/fileDetailsModal/FileDetailsModal'
import { ProcessType, Project } from 'types/projects/projects'

export const useOpenFilePreviewModal = (project: Project) => {
  const { visible, hide } = useShowFileDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, id } = Object.fromEntries(params.entries()) as { view: string; id: string }

    if (view && view === 'filePreview') {
      showFileDetailsModal({
        fileId: id,
        isInFluid: project.processType === ProcessType.FLUID,
        onClose: setParams,
      })
    } else visible && hide()
  }, [hide, params, project.processType, setParams, visible])
}
