import { WppInlineMessage } from '@platform-ui-kit/components-library-react'

import { useCheckCaasRequirements } from 'hooks/application/useCheckCaasRequirements'
import { t } from 'i18next'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
  className?: string
}

export const NotOnboardedInfo = ({ application, className }: Props) => {
  const { isCaasAppHavePermission, isCaasHasCurrentWorkspace } = useCheckCaasRequirements(application)

  if (!isCaasAppHavePermission) {
    return (
      <WppInlineMessage
        className={className}
        size="m"
        type="warning"
        message={`${t('product.not_onboarded_alert.permission_title')!} ${t(
          'product.not_onboarded_alert.permission_text',
        )!}`}
      />
    )
  }

  if (!isCaasHasCurrentWorkspace) {
    return (
      <WppInlineMessage
        className={className}
        size="m"
        type="warning"
        message={`${t('product.not_onboarded_alert.workspace_title')!} ${t(
          'product.not_onboarded_alert.workspace_text',
        )!}`}
      />
    )
  }

  if (application.isOutdated) {
    return (
      <WppInlineMessage
        className={className}
        size="m"
        type="warning"
        message={t('product.outdated_app_alert.workspace_text')!}
      />
    )
  }

  return null
}
