import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Members } from 'types/members/members'

export const fetchMembersListApi = ({ id, page = 1, itemsPerPage = 30 }: PaginationParams<{ id: string }>) =>
  projectApi.get<PaginatedResponse<Members>>('/members', {
    params: {
      'filter[projectId]': id,
      page,
      itemsPerPage,
    },
  })
