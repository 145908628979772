import { useOs } from '@wpp-open/react'
import { forwardRef, useMemo } from 'react'

import { useAssignMember } from 'hooks/useAssignMember'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { Activity } from 'pages/project/components/canvas/components/item/activity/Activity'
import { Application } from 'pages/project/components/canvas/components/item/application/Application'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { useUpdateItem } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { isActivityItem, isApplicationItem } from 'pages/project/components/canvas/phaseUtils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { PhaseItem } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

interface Props {
  phaseItem: PhaseItem
  index: number
  isIAssignToThisPhase?: boolean
  isEditable?: boolean
  toggleEditModal?: () => void
  variant?: 'primary' | 'secondary'
  preview?: boolean
  isInactive?: boolean
  isDraggingDisabled?: boolean
}

export const Item = forwardRef<HTMLDivElement, Props>(
  (
    {
      phaseItem,
      index,
      isIAssignToThisPhase,
      isEditable,
      variant,
      toggleEditModal,
      preview,
      isInactive,
      isDraggingDisabled,
    },
    ref,
  ) => {
    const {
      osContext: { userDetails },
    } = useOs()

    const { project } = useProject()

    const assignMember = useAssignMember(phaseItem.item.assignUser)

    const { hasRole } = useHasProjectRole()
    const { isPermitted } = useIsPermitted()

    const isOwnerOrGlobalManage =
      hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

    const isMeAssignToThisApp = useMemo(
      () => isEqualEmails(userDetails.email, assignMember?.email),
      [userDetails, assignMember],
    )

    const showAction = !!isEditable && (isOwnerOrGlobalManage || isIAssignToThisPhase || isMeAssignToThisApp)

    const isDisabled = !!isEditable && !showAction
    const { updateItem } = useUpdateItem({
      id: phaseItem.item.id,
      type: phaseItem.itemType,
      name: phaseItem.item.name,
      projectId: project.id,
    })
    const toggleAssignee = async (newAssignee: ResponsibleUser) => {
      const isDeselecting = assignMember?.id === newAssignee.id
      await updateItem({ assignUser: isDeselecting ? null : newAssignee })
    }

    return (
      <div
        ref={ref}
        data-testid={`phase-item-card-${phaseItem.item.id}`}
        itemType={isApplicationItem(phaseItem) ? 'application' : 'activity'}
      >
        {isApplicationItem(phaseItem) && (
          <Application
            phaseId={phaseItem.phaseId}
            application={phaseItem.item}
            isEditable={isEditable}
            isDisabled={isDisabled}
            showAction={showAction}
            variant={variant}
            index={index}
            editApp={toggleEditModal}
            changeAssignee={toggleAssignee}
            changeDates={dates => updateItem({ dates })}
            changeStatus={status => updateItem({ status })}
            preview={preview}
            isInactive={isInactive}
            isDraggingDisabled={isDraggingDisabled}
          />
        )}
        {isActivityItem(phaseItem) && (
          <Activity
            activity={phaseItem.item}
            phaseItemId={phaseItem.id}
            isDisabled={isDisabled}
            isEditable={!!isEditable}
            showAction={showAction}
            isIAssignToThisPhase={isIAssignToThisPhase}
            variant={variant}
            index={index}
            changeAssignee={toggleAssignee}
            changeDates={dates => updateItem({ dates })}
            changeStatus={status => updateItem({ status })}
            editActivity={toggleEditModal}
            phaseId={phaseItem.phaseId}
            preview={preview}
            isInactive={isInactive}
            isDraggingDisabled={isDraggingDisabled}
          />
        )}
      </div>
    )
  },
)
