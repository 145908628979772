// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bQEvv{--wpp-typography-color: #000000;--wpp-typography-xs-body-color: #000000;position:absolute;z-index:999;background:rgba(238,232,255,.5);inset:8px 0 0;border-radius:8px;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}.GH9IL{margin-top:8px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/lockOverlay/LockOverlay.module.scss"],"names":[],"mappings":"AAAA,OACE,+BAAA,CACA,uCAAA,CAEA,iBAAA,CACA,WAAA,CACA,+BAAA,CACA,aAAA,CAEA,iBAAA,CACA,iCAAA,CAAA,yBAAA,CAGF,OACE,cAAA","sourcesContent":[".lockOverlay {\n  --wpp-typography-color: #000000;\n  --wpp-typography-xs-body-color: #000000;\n\n  position: absolute;\n  z-index: 999;\n  background: rgb(238 232 255 / 50%);\n  inset: 8px 0 0;\n  // --wpp-side-modal-z-index - 1\n  border-radius: 8px;\n  backdrop-filter: blur(5px);\n}\n\n.userContainer {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lockOverlay": `bQEvv`,
	"userContainer": `GH9IL`
};
export default ___CSS_LOADER_EXPORT___;
