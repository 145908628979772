import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { TemplateFilter } from 'types/projects/projects'
import { WorkflowTemplate } from 'types/projects/workflowTemplate'

export const fetchWorkflowTemplatesListApi = ({
  inputText,
  processType,
  page = 1,
  itemsPerPage = 30,
}: PaginationParams<TemplateFilter>) =>
  projectApi.post<PaginatedResponse<WorkflowTemplate>>(
    '/workflow-templates/search',
    {
      inputText,
      processType,
    },
    {
      params: {
        page,
        itemsPerPage,
      },
    },
  )
