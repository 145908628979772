// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qgp06:has(.ag-overlay-no-rows-wrapper,.ag-overlay-loading-wrapper) .ag-center-cols-viewport{overflow:hidden}.WkzRW{pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/common/table/tableInfinite/TableInfinite.module.scss"],"names":[],"mappings":"AAII,6FACE,eAAA,CAKN,OACE,mBAAA","sourcesContent":["// Hide horizontal scrollbar for empty states.\n// NOTE: not working in FF\n.root {\n  &:has(:global(.ag-overlay-no-rows-wrapper), :global(.ag-overlay-loading-wrapper)) {\n    :global(.ag-center-cols-viewport) {\n      overflow: hidden;\n    }\n  }\n}\n\n.loadingMoreRow {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `qgp06`,
	"loadingMoreRow": `WkzRW`
};
export default ___CSS_LOADER_EXPORT___;
