// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pKhYR{display:flex !important;align-items:center;height:100%}.IF9_q{color:var(--wpp-primary-color-500)}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/components/projectManageMenu/ProjectManageMenu.module.scss"],"names":[],"mappings":"AAAA,OAEE,uBAAA,CACA,kBAAA,CACA,WAAA,CAGF,OACE,kCAAA","sourcesContent":[".rowContextMenu {\n  // TODO: remove important after CL root selector specificity fix in WPPLONOP-7469\n  display: flex !important;\n  align-items: center;\n  height: 100%;\n}\n\n.textColor {\n  color: var(--wpp-primary-color-500);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowContextMenu": `pKhYR`,
	"textColor": `IF9_q`
};
export default ___CSS_LOADER_EXPORT___;
