import { getChildHierarchyLevels } from '@wpp-open/core/utils/hierarchy'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

export const useHierarchy = () => {
  const {
    osContext: { tenant },
  } = useOs()

  const hierarchyOrder = useMemo(() => getChildHierarchyLevels(tenant).map(({ type }) => type.toLowerCase()), [tenant])

  return { hierarchyOrder }
}
