import { WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/templatePreviewModal/TemplatePreviewModal.module.scss'
import { is400Error, is401Error, is403Error, is404Error, is5xxError } from 'utils/error'

const getErrorObject = (error: unknown): { title: string; description: string } => {
  switch (true) {
    case is400Error(error):
      return { title: 'modals.errors.400.title', description: 'modals.errors.400.description' }
    case is401Error(error):
      return { title: 'modals.errors.401.title', description: 'modals.errors.401.description' }
    case is403Error(error):
      return { title: 'modals.errors.403.title', description: 'modals.errors.403.description' }
    case is404Error(error):
      return { title: 'modals.errors.404.title', description: 'modals.errors.404.description' }
    case is5xxError(error):
      return { title: 'modals.errors.5xx.title', description: 'modals.errors.5xx.description' }
    default:
      return { title: 'modals.errors.default.title', description: 'modals.errors.default.description' }
  }
}

export const TemplateViewSkeleton = () => (
  <>
    <WppSkeleton slot="header" variant="rectangle" height="35px" width="320px" />
    <Flex direction="column" slot="body" gap={16}>
      <Flex justify="between">
        <WppSkeleton slot="header" variant="rectangle" height="24px" width="300px" />
        <WppSkeleton slot="header" variant="rectangle" height="24px" width="100px" />
      </Flex>
      <WppSkeleton variant="rectangle" height="600px" width="100%" />
    </Flex>
    <Flex slot="actions" gap={12} justify="end">
      <WppSkeleton slot="header" variant="rectangle" height="40px" width="87px" />
      <WppSkeleton slot="header" variant="rectangle" height="40px" width="87px" />
    </Flex>
  </>
)

export const TemplateViewError = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  const errorText = useMemo(() => getErrorObject(error), [error])

  return (
    <Flex slot="body" className={styles.noData} direction="column" justify="center" align="center" gap={8}>
      <WppTypography type="m-strong" data-testid="view-template-modal-error-title">
        {t(errorText.title)}
      </WppTypography>
      <WppTypography type="xs-body" data-testid="view-template-modal-error-description">
        {t(errorText.description)}
      </WppTypography>
    </Flex>
  )
}
