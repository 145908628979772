// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uVAJe{color:var(--wpp-grey-color-500)}.IUfAC{color:var(--wpp-grey-color-500)}.IUfAC::part(inner){text-transform:capitalize}`, "",{"version":3,"sources":["webpack://./src/pages/components/workspaceWarning/WorkspaceWarning.module.scss"],"names":[],"mappings":"AAAA,OACE,+BAAA,CAGF,OACE,+BAAA,CAEA,oBACE,yBAAA","sourcesContent":[".fontColor {\n  color: var(--wpp-grey-color-500);\n}\n\n.title {\n  color: var(--wpp-grey-color-500);\n\n  &::part(inner) {\n    text-transform: capitalize;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontColor": `uVAJe`,
	"title": `IUfAC`
};
export default ___CSS_LOADER_EXPORT___;
