import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { EmptyPieChartSvg } from 'components/svg/EmptyPieChartSvg'
import styles from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/TasksStatusBoard.module.scss'

export const EmptyPieChart = () => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" align="center" gap={24} className={styles.empty}>
      <EmptyPieChartSvg />
      <Flex gap={8} direction="column" align="center">
        <WppTypography type="l-strong">{t('project.tasks.no_tasks')}</WppTypography>
        <WppTypography type="s-body" className={styles.subTitle}>
          {t('project.tasks.have_not_assigned')}
        </WppTypography>
      </Flex>
    </Flex>
  )
}
