/**
 * @see https://gitlab.com/2sixty/chaos/osmium/public/core/wpp-open-core-fe/-/blob/develop/src/utils/links.ts#L3
 */
export const simulateLinkClick = ({
  href,
  target,
}: {
  href: string
  target?: '_self' | '_blank' | '_parent' | '_top'
}) => {
  const link = document.createElement('a')
  link.href = href

  if (target) {
    link.target = target
  }

  if (target === '_blank') {
    link.setAttribute('rel', 'noreferrer')
  }

  link.click()
  link.remove()
}
