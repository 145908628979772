import {
  WppActionButton,
  WppCard,
  WppDivider,
  WppIconEyeOn,
  WppIconMore,
  WppIconPlusCircle,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { UserInfo } from 'components/common/userInfo/UserInfo'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { PhasesIndicator } from 'pages/components/phasesIndicator/PhasesIndicator'
import styles from 'pages/components/templateCard/TemplateCard.module.scss'
import { AppPermissions } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { WorkflowTemplate } from 'types/projects/workflowTemplate'

type Props = {
  template: WorkflowTemplate
  withPreview?: boolean
  withContextMenu?: boolean

  handleOpenTemplate?(): void
  handleCreateFromTemplate?(): void
  handleRemoveTemplate?(): void
} & JSX.IntrinsicElements['div']

export const TEMPLATE_PREVIEW_CLASS = 'templateCardPreview'

export const TemplateCard: FC<Props> = ({
  template,
  withPreview,
  withContextMenu,
  handleOpenTemplate,
  handleCreateFromTemplate,
  handleRemoveTemplate,
  className,
}: Props) => {
  const { t } = useTranslation()
  const { isPermitted } = useIsPermitted()
  const {
    osContext: { userDetails },
  } = useOs()

  const isOwner = useMemo(() => {
    return template.createdBy.id === userDetails.id
  }, [template.createdBy.id, userDetails.id])

  const isOwnerOrGlobalManage = isOwner || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const cardStats = useMemo(() => {
    return {
      phasesCount: template.phasesStats.length,
      phaseItemsCount: template.phasesStats.reduce((acc, { numberOfItems }) => acc + numberOfItems, 0),
    }
  }, [template])

  return (
    <WppCard
      className={clsx(styles.templateCard, className)}
      value={template.id}
      interactive={!!handleOpenTemplate}
      data-testid={`template-card-${template.id}`}
    >
      <Flex className={styles.cardBody} direction="column" onClick={handleOpenTemplate}>
        <Flex gap={8} className={styles.phaseInfo}>
          {!!template.phasesStats.length && (
            <PhasesIndicator stats={template.phasesStats} processType={template.processType} />
          )}
          <WppTypography type="xs-body" data-testid="template-phases-stats">
            {template.processType === ProcessType.LINEAR
              ? t('templates.card.stats', { ...cardStats })
              : t('templates.card.stats_fluid', { count: cardStats.phaseItemsCount })}
          </WppTypography>
        </Flex>
        <WppTypography type="l-strong" data-testid="template-name">
          {template.name}
        </WppTypography>
        <WppTypography type="s-body" className={styles.description}>
          {template.description}
        </WppTypography>
        <WppDivider className={styles.divider} />
        <UserInfo className={styles.footer} user={template.createdBy} avatarUrl={template.createdBy.avatarUrl}>
          {withPreview && (
            <WppActionButton
              className={clsx(TEMPLATE_PREVIEW_CLASS, styles.btnPreview)}
              data-testid="template-preview-button"
            >
              {t('common.btn_preview')}
            </WppActionButton>
          )}
        </UserInfo>
      </Flex>

      {!!withContextMenu && (
        <WppMenuContext slot="actions">
          <WppActionButton slot="trigger-element" variant="secondary">
            <WppIconMore slot="icon-start" direction="horizontal" />
          </WppActionButton>

          <WppListItem onWppChangeListItem={handleOpenTemplate}>
            <WppIconEyeOn slot="left" />
            <WppTypography slot="label" type="s-body">
              {t('templates.card.view_template')}
            </WppTypography>
          </WppListItem>
          <WppListItem onWppChangeListItem={handleCreateFromTemplate} data-testid="create-project-option">
            <WppIconPlusCircle slot="left" />
            <WppTypography slot="label" type="s-body">
              {t('templates.card.create_from_template')}
            </WppTypography>
          </WppListItem>
          {/* TODO @v.novikov-star apply permissions: `Programme Manager / Tenant Admin / OS Admin role` */}
          {isOwnerOrGlobalManage && (
            <WppListItem onWppChangeListItem={handleRemoveTemplate} data-testid="context-remove-option">
              <WppIconTrash slot="left" />
              <WppTypography slot="label" type="s-body">
                {t('templates.card.remove')}
              </WppTypography>
            </WppListItem>
          )}
        </WppMenuContext>
      )}
    </WppCard>
  )
}
