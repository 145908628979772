import {
  WppCardGroup,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import { useField } from 'hooks/form/useField'
import { ProcessType } from 'types/projects/projects'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppCardGroup>, 'onChange' | 'onWppChange' | 'value'> {
  name: string
  templateId?: string
  resetTemplateId: () => void
}

export const SelectProcessType = forwardRef<HTMLWppSegmentedControlElement, Props>(
  ({ name, templateId, resetTemplateId }, ref) => {
    const innerRef = useRef<HTMLWppSegmentedControlElement>(null)
    const { t } = useTranslation()

    const {
      field: { value, onChange },
    } = useField({ name })

    return (
      <WppSegmentedControl
        width="100%"
        ref={mergeRefs([innerRef, ref])}
        size="m"
        value={value}
        onWppChange={event => {
          onChange(event.detail.value)
          if (templateId) {
            resetTemplateId()
          }
        }}
        data-testid="process-type-select"
      >
        <WppTooltip text={t('modals.create_project.field_process_type_linear_tooltip')!}>
          <WppSegmentedControlItem value={ProcessType.LINEAR}>
            {t(`overview.process_type_${ProcessType.LINEAR}`)}
          </WppSegmentedControlItem>
        </WppTooltip>
        <WppTooltip text={t('modals.create_project.field_process_type_fluid_tooltip')!}>
          <WppSegmentedControlItem value={ProcessType.FLUID}>
            {t(`overview.process_type_${ProcessType.FLUID}`)}
          </WppSegmentedControlItem>
        </WppTooltip>
      </WppSegmentedControl>
    )
  },
)
