import { useCallback, useRef } from 'react'

const STORAGE_KEY = 'WPP_OPEN_PROJECTS_VIEW'

export enum ViewType {
  CARDS = 'cards',
  TABLE = 'table',
}

export const useSessionViewType = () => {
  const viewTypeRef = useRef<ViewType>((window.sessionStorage.getItem(STORAGE_KEY) as ViewType) || ViewType.CARDS)
  const setViewType = useCallback((viewType: ViewType) => {
    viewTypeRef.current = viewType
    window.sessionStorage.setItem(STORAGE_KEY, viewType)
  }, [])

  return { viewType: viewTypeRef.current, setViewType }
}
