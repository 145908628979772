// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gF3dC{height:100%}.VkPks{display:grid;grid-gap:20px;grid-row:auto;grid-template-columns:1fr 1fr}.OHt6O{--wpp-input-select-dropdown-height: 300px}.lX1n8{display:grid;grid-template-columns:repeat(4, 1fr);gap:16px}.KNdfX{width:32px;height:32px;border-radius:7px}.UFrJT{background:var(--wpp-dataviz-color-cat-light-1)}.Y2X0O{background:var(--wpp-dataviz-color-cat-light-3)}.dz1Rr{background:var(--wpp-dataviz-color-cat-light-5)}.deHHp{background:var(--wpp-dataviz-color-cat-light-7)}.u5wEd{grid-column-start:1}.u5wEd::part(datepicker-container){width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/projectForm/ProjectForm.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,YAAA,CACA,aAAA,CACA,aAAA,CACA,6BAAA,CAGF,OACE,yCAAA,CAGF,OACE,YAAA,CACA,oCAAA,CACA,QAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,OACE,+CAAA,CAGF,OACE,+CAAA,CAGF,OACE,+CAAA,CAGF,OACE,+CAAA,CAGF,OACE,mBAAA,CAEA,mCACE,UAAA","sourcesContent":[".spinner {\n  height: 100%;\n}\n\n.grid {\n  display: grid;\n  grid-gap: 20px;\n  grid-row: auto;\n  grid-template-columns: 1fr 1fr;\n}\n\n.selectMaxHeight {\n  --wpp-input-select-dropdown-height: 300px;\n}\n\n.radioGroup {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 16px;\n}\n\n.cardIcon {\n  width: 32px;\n  height: 32px;\n  border-radius: 7px;\n}\n\n.BLANK {\n  background: var(--wpp-dataviz-color-cat-light-1);\n}\n\n.PITCH {\n  background: var(--wpp-dataviz-color-cat-light-3);\n}\n\n.WORKSHOP {\n  background: var(--wpp-dataviz-color-cat-light-5);\n}\n\n.CAMPAIGN {\n  background: var(--wpp-dataviz-color-cat-light-7);\n}\n\n.datePicker {\n  grid-column-start: 1;\n\n  &::part(datepicker-container) {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `gF3dC`,
	"grid": `VkPks`,
	"selectMaxHeight": `OHt6O`,
	"radioGroup": `lX1n8`,
	"cardIcon": `KNdfX`,
	"BLANK": `UFrJT`,
	"PITCH": `Y2X0O`,
	"WORKSHOP": `dz1Rr`,
	"CAMPAIGN": `deHHp`,
	"datePicker": `u5wEd`
};
export default ___CSS_LOADER_EXPORT___;
