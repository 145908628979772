import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { TaskStatus } from 'types/projects/tasks'

export interface TaskPatchItemRequest {
  id: string
  orderNumber?: MayBeNull<number>
  startDate?: MayBeNull<string>
  endDate?: MayBeNull<string>
  assignUser?: MayBeNull<string>
  status?: MayBeNull<TaskStatus>
  description?: MayBeNull<string>
  name?: MayBeNull<string>
}
export const patchTaskApi = ({ id, ...body }: TaskPatchItemRequest) => projectApi.patch(`/tasks/${id}`, body)
