import styles from 'components/svg/common.module.scss'

export const NoRecordsSvg = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="161" fill="none" viewBox="0 0 150 161" {...props}>
    <path
      className={styles.fillPrimary200}
      d="M75 150c41.421 0 75-33.579 75-75S116.421 0 75 0 0 33.579 0 75s33.579 75 75 75z"
    />
    <g filter="url(#filter0_d_3714_149989)">
      <path className={styles.fillWhite} d="M118 43H32a5 5 0 00-5 5v105a5 5 0 005 5h86a5 5 0 005-5V48a5 5 0 00-5-5z" />
    </g>
    <path className={styles.fillPrimary300} d="M65 58H39a3 3 0 100 6h26a3 3 0 100-6z" />
    <path className={styles.fillPrimary100} d="M83 71H39a3 3 0 100 6h44a3 3 0 100-6z" />
    <path className={styles.fillPrimary300} d="M65 85H39a3 3 0 100 6h26a3 3 0 100-6z" />
    <path className={styles.fillPrimary100} d="M83 98H39a3 3 0 100 6h44a3 3 0 100-6z" />
    <path className={styles.fillPrimary300} d="M65 131H39a3 3 0 100 6h26a3 3 0 100-6z" />
    <path className={styles.fillPrimary100} d="M83 144H39a3 3 0 100 6h44a3 3 0 100-6z" />
    <g filter="url(#filter1_d_3714_149989)">
      <path
        className={styles.fillPrimary400}
        d="M118 83H32a5 5 0 00-5 5v30a5 5 0 005 5h86a5 5 0 005-5V88a5 5 0 00-5-5z"
      />
    </g>
    <path className={styles.fillPrimary300} d="M65 94H39a3 3 0 100 6h26a3 3 0 100-6z" />
    <path className={styles.fillWhite} d="M83 107H39a3 3 0 100 6h44a3 3 0 100-6z" />
    <defs>
      <filter
        id="filter0_d_3714_149989"
        width="108"
        height="127"
        x="21"
        y="34"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3714_149989" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_3714_149989" result="shape" />
      </filter>
      <filter
        id="filter1_d_3714_149989"
        width="108"
        height="52"
        x="21"
        y="74"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3714_149989" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_3714_149989" result="shape" />
      </filter>
    </defs>
  </svg>
)
