import {
  WppActionButton,
  WppButton,
  WppIconTrash,
  WppListItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import styles from 'pages/project/components/canvas/components/appPikerModal/selectedAppsModal/SelectedAppsModal.module.scss'
import { SelectedApp } from 'pages/project/components/canvas/components/appPikerModal/types'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  selectedApps: SelectedApp[]
  onAddApps: (apps: SelectedApp[]) => Promise<void>
}
const SelectedAppsModal = ({
  isOpen,
  onClose,
  onCloseComplete,
  selectedApps: parentSelectedApps,
  onAddApps,
}: Props) => {
  const { t } = useTranslation()
  const [isCreating, setIsCreating] = useState(false)
  const [selectedApps, setSelectedApps] = useState<SelectedApp[]>(parentSelectedApps)

  const handleRemoveApp = ({ id }: SelectedApp) => {
    setSelectedApps(selectedApps.filter(app => id !== app.id))
  }

  const handleAddApps = async () => {
    try {
      setIsCreating(true)
      await onAddApps(selectedApps)
      onClose()
    } catch (error) {
      setIsCreating(false)
    }
  }

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="m"
      data-testid="selected-apps-modal"
    >
      <WppTypography slot="header" type="2xl-heading" data-testid="selected-apps-modal-name">
        {t('product.selected_apps.title')}
      </WppTypography>
      <div slot="body">
        {selectedApps.map(app => (
          <WppListItem key={app.id} className={styles.listItem} data-testid="app-item">
            <p slot="label">
              <Truncate lines={1} type="s-body" data-testid="app-name">
                {app.name}
              </Truncate>
            </p>
            <p slot="caption" data-testid="app-type">
              {t('project.canvas.native')}
            </p>

            <div slot="left">
              <Flex inline direction="column" className={styles.logo} justify="center" align="center">
                <ApplicationLogo logo={app.logo} />
              </Flex>
            </div>
            <WppActionButton
              className={styles.removeBtn}
              variant="secondary"
              slot="right"
              onClick={() => handleRemoveApp(app)}
              data-testid="remove-bin"
            >
              <WppIconTrash slot="icon-start" />
            </WppActionButton>
          </WppListItem>
        ))}
      </div>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="m" onClick={() => onClose()}>
          {t('common.btn_cancel')}
        </WppButton>
        <WppButton variant="primary" size="m" onClick={handleAddApps} loading={isCreating}>
          {t('product.selected_apps.add_n_selected', { count: selectedApps.length })}
        </WppButton>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showSelectedAppsModal } = createNiceModal<Props>(SelectedAppsModal, 'selected-apps-modal')
