import { CardGroupChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppCardGroupCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppCardGroup, WppSpinner } from '@platform-ui-kit/components-library-react'
import { RefCallback, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfiniteNativeAppsApi } from 'api/applications/queries/useInfiniteNativeAppsApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { NativeAppCard } from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeAppCard'
import styles from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeApps.module.scss'
import { SelectedApp } from 'pages/project/components/canvas/components/appPikerModal/types'
import { useIsMyMiroEnabled } from 'pages/project/hooks/useIsMyMiroEnabled'
import { NativeAppDTO, NativeAppsCategory, NativeAppsCommercialModel } from 'types/products/nativeApp'

interface Props {
  search: string
  filters: {
    categories: NativeAppsCategory[]
    commercialModel: NativeAppsCommercialModel
  }
  selectedApps: SelectedApp[]
  onSelectApp: (args: SelectedApp[]) => void
}

export const NativeApps = ({ search, filters, selectedApps, onSelectApp }: Props) => {
  const { t } = useTranslation()
  const [selectedVersions, setVerion] = useState<Record<string, string>>({})
  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  const isMiroEnabled = useIsMyMiroEnabled()
  const {
    data: nativeApps,
    response,
    isLoading: isNativeAppsLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteNativeAppsApi({
    params: { search, ...filters },
  })

  const isFetching = isRefetching || isFetchingNextPage
  const resultsNumber = response?.pages?.[response.pages.length - 1].data?.paginator?.totalItems || 0

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage: isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const handleSelectApp = (event: WppCardGroupCustomEvent<CardGroupChangeEventDetail>) => {
    const selectedIds = event.target.value as string[]

    const addedApps: SelectedApp[] = selectedIds?.map(nativeAppId => {
      // search selected ids first in selected registry, if that's new ID - in actual apps list
      const selectedNativeApp = (selectedApps.find(({ id }) => id === nativeAppId) ||
        nativeApps.find(({ id }) => id === nativeAppId))!

      const logo = (selectedNativeApp as NativeAppDTO).logoUrl || (selectedNativeApp as SelectedApp).logo
      const versionId =
        selectedVersions[nativeAppId] ||
        (selectedNativeApp as NativeAppDTO).versions?.[0]?.id ||
        (selectedNativeApp as SelectedApp).versionId

      return {
        id: selectedNativeApp.id,
        type: selectedNativeApp.type,
        name: selectedNativeApp.name,
        logo,
        versionId,
      }
    })

    onSelectApp(addedApps)
  }

  return isNativeAppsLoading ? (
    <Flex justify="center">
      <WppSpinner size="m" />
    </Flex>
  ) : !resultsNumber ? (
    <EmptyState
      title={!!search ? t('product.no_results.title') : t('product.no_products.title')}
      filtersApplied={!!search}
      description={!!search ? t('common.no_results_description') : t('product.no_products.subtitle')}
      testToken="results"
    />
  ) : (
    <WppCardGroup
      value={selectedApps.map(({ id }) => id)}
      multiple
      withRadioOrCheckbox={false}
      className={styles.grid}
      onWppChange={handleSelectApp}
    >
      {nativeApps.map(app => (
        <NativeAppCard
          app={app}
          key={app.id}
          handleVersions={setVerion}
          selected={selectedVersions}
          isMiroEnabled={isMiroEnabled}
        />
      ))}
      <Flex justify="center" ref={setRef} className={styles.nextPageSpinner}>
        {isFetching && <WppSpinner size="m" />}
      </Flex>
    </WppCardGroup>
  )
}
