export enum ApiQueryKeys {
  PROJECTS = 'projects',
  PROJECTS_INFINITE = 'projects_infinite',
  PROJECTS_FETCHER = 'projects_fetcher',
  PROJECTS_FILES = 'projects_files',
  PROJECTS_FILES_INFINITE = 'projects_files_infinite',
  PROJECTS_FILES_FETCHER = 'projects_files_fetcher',
  PROJECTS_FILE = 'projects_file_fetcher',
  PROJECT = 'project',
  PROJECT_VIEW_SETTINGS = 'project_view_settings',
  PROJECT_WIDGETS_CONFIGURATION = 'project_widgets_configuration',
  PROJECT_PRESENTATION = 'project_presentation',
  PROJECT_DOWNLOAD_URLS = 'project_download_urls',
  PROJECT_APPLICATION = 'project_application',
  MEMBERS = 'members',
  USER_DETAILS_DOWNLOAD_URLS = 'user-details-download-urls',
  FILE_DETAILS_DOWNLOAD_URLS = 'file-details-download-urls',
  PROJECTS_DASHBOARD_DATA = 'projects_dashboard_data',
  PROJECTS_DASHBOARD_WORKFLOW_WIDGET = 'projects_dashboard_workflow_widget',
  USERS_LIST = 'users_list',
  LEAN_APP_INFINITE = 'lean_app/infinite',
  AGENCIES = 'agencies',
  PROJECT_WORKFLOW_LINEAR = 'project_workflow_linear',
  PROJECT_WORKFLOW_FLUID = 'project_workflow_fluid',
  PROJECT_CANVAS_LOCK = 'project_canvas_lock',
  WORKFLOW_TEMPLATES_BY_ID = 'workflow_templates_by_id',
  WORKFLOW_TEMPLATES = 'workflow_templates',
  WORKFLOW_TEMPLATES_COUNT = 'workflow_templates_count',
  WORKFLOW_TEMPLATES_INFINITE = 'workflow_templates_infinite',
  MIRO_CONFIG_STATUS = 'MIRO_CONFIG_STATUS',
  MIRO_ME = 'MIRO_ME',
  NATIVE_APPS_INFINITE = 'NATIVE_APPS_INFINITE',
  PROJECT_TASKS_LIST = 'project_tasks_fetcher',
  PROJECT_TASK = 'project_task_fetcher',
}
