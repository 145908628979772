import { WppIconUser, WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/canvas/components/phase/ResponsiblePerson.module.scss'
import { Members } from 'types/members/members'
import { fullName } from 'utils/common'

//pick props from Members
type Member = Pick<Members, 'id' | 'firstname' | 'lastname' | 'avatarUrl'>
interface Props {
  assignMember?: Member
  isPreviewMode?: boolean
  hideIcon?: boolean
  showName?: boolean
  size?: 'xs' | 's'
}

export const ResponsiblePerson = ({ assignMember, hideIcon, size, showName }: Props) => {
  const { t } = useTranslation()

  return (
    <WppTooltip
      header={t('project.phase.responsible_person_tooltip_text')!}
      text={
        assignMember
          ? `${assignMember.firstname} ${assignMember.lastname}`
          : t('project.phase.responsible_person_not_set')!
      }
    >
      {assignMember ? (
        <Flex gap={4} className={styles.avatarFilled}>
          <Avatar
            size={size ?? 's'}
            key={assignMember.avatarUrl}
            src={assignMember.avatarUrl ?? ''}
            name={`${assignMember.firstname} ${assignMember.lastname}`}
            data-testid={`responsible-person-${assignMember.id}`}
          />

          {showName && (
            <WppTypography type="s-strong">{fullName(assignMember.firstname, assignMember.lastname)}</WppTypography>
          )}
        </Flex>
      ) : (
        !hideIcon && <WppIconUser className={styles.avatarEmpty} />
      )}
    </WppTooltip>
  )
}
