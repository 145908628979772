import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/canvas/components/item/activity/AppActivityItem.module.scss'
import { ExternalLinkView } from 'pages/project/components/canvas/components/item/ExternalLinkView'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import { ExternalLink } from 'types/projects/workflow'

interface Props {
  activityId: string
  links: ExternalLink[]
  isDisabled: boolean
  variant?: 'primary' | 'secondary'
}
export const ExternalLinksPreviewList = ({ links, activityId, isDisabled, variant }: Props) => {
  return (
    <Flex direction="column" className={styles.externalLinkPreviewList} data-testid="activity-links">
      <ShowMoreItems maxToShow={2}>
        {links.map(link => (
          <ExternalLinkView
            variant={variant}
            activityId={activityId}
            link={link}
            isDisabled={isDisabled}
            key={link.id}
            isPreview
          />
        ))}
      </ShowMoreItems>
    </Flex>
  )
}
