import { WppTypography, WppTooltip } from '@platform-ui-kit/components-library-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { DataArrowSvg } from 'components/svg/DataArrowSvg'
import { SequenceArrowSvg } from 'components/svg/SequenceArrowSvg'
import styles from 'pages/project/components/canvas/components/fluidCanvas/components/fluidLegend/FluidLegend.module.scss'
import { HIDE_DATA_CONNECTION } from 'pages/project/components/canvas/components/fluidCanvas/utils'

export const FluidLegend = memo(() => {
  const { t } = useTranslation()
  return (
    <Flex className={styles.Container} gap={8}>
      <WppTooltip className={styles.Tooltip} text={t('project.canvas.legend_sequence_description')!}>
        <Flex align="center" className={styles.Item} gap={8} data-testid="sequence-legend">
          <SequenceArrowSvg />
          <WppTypography type="s-body">{t('project.canvas.legend_sequence')}</WppTypography>
        </Flex>
      </WppTooltip>
      {!HIDE_DATA_CONNECTION && (
        <WppTooltip className={styles.Tooltip} text={t('project.canvas.legend_data_description')!}>
          <Flex align="center" className={styles.Item} gap={8}>
            <DataArrowSvg />
            <WppTypography type="s-body">{t('project.canvas.legend_data')}</WppTypography>
          </Flex>
        </WppTooltip>
      )}
    </Flex>
  )
})
