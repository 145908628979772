import {
  WppButton,
  WppFilterButton,
  WppIconDataViewList,
  WppIconDataViewCards,
  WppIconPlus,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppInput,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, Route, Routes } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ACTION_ANALYTICS } from 'constants/analytics'
import { useTrackAction } from 'hooks/useAnalytics'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useHierarchy } from 'hooks/useHierarchy'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { showCreateProjectModal } from 'pages/components/projectModal/CreateProjectModal'
import { DashboardNavigation } from 'pages/dashboard/components/dashboardNavigation/DashboardNavigation'
import { OnRouteEnter } from 'pages/dashboard/components/onRouteEnter/OnRouteEnter'
import { showProjectFilterModal } from 'pages/dashboard/components/projectFilterModal/ProjectFilterModal'
import { ProjectCardView } from 'pages/dashboard/components/projectsCardView/ProjectCardView'
import { ProjectsTableView } from 'pages/dashboard/components/projectsTableView/ProjectsTableView'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { useSessionViewType, ViewType } from 'pages/dashboard/utils/useSessionViewType'
import { AppPermissions } from 'types/permissions/permissions'
import { ProjectFilter, ProjectOwnership, ProjectStatus } from 'types/projects/projects'
import { routesManager } from 'utils/routesManager'

const filtersCount = ({ status, type, ownership, search, workspace, ...hierarchy }: ProjectFilter) => {
  const restCount = Object.values(hierarchy).reduce((acc, curr) => acc + (curr?.length || 0), 0)

  return (status as any[]).concat(type, ownership).length + restCount
}

export const initialProjectFilters: ProjectFilter = {
  status: [ProjectStatus.ACTIVE],
  type: [],
  search: undefined,
  ownership: ProjectOwnership.ALL,
}

export const Dashboard = () => {
  const { t } = useTranslation()

  const { trackAction } = useTrackAction()
  const { isPermitted } = useIsPermitted()
  const canCreateProject =
    isPermitted(AppPermissions.ORCHESTRATION_PROJECTS_CREATE) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const { hierarchyOrder } = useHierarchy()

  const [filter, setFilter] = useState<ProjectFilter>({
    ...initialProjectFilters,
    ...hierarchyOrder.reduce(
      (pre, curr) => ({
        ...pre,
        [curr]: [],
      }),
      {},
    ),
  })

  const { viewType, setViewType } = useSessionViewType()

  const filtersCounts = useMemo(() => filtersCount(filter), [filter])

  useEffect(() => trackAction(ACTION_ANALYTICS.ACTION_PAGE_PROJECTS, filter), [trackAction, filter])

  const setSearchDebounced = useDebounceFn((search: string) => {
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setFilter(filters => ({ ...filters, search: searchQuery }))
  }, 300)

  const handleCloseFilterModal = (newFilter: ProjectFilter) => {
    setFilter({ ...filter, ...newFilter })
  }

  return (
    <Flex direction="column" className={styles.container}>
      <div className={styles.headerContainer}>
        <DashboardNavigation />
      </div>

      <Flex direction="row" justify="between" className={styles.filtersContainer}>
        <Flex gap={12}>
          <WppInput
            size="s"
            name="search"
            placeholder={t('dashboard.field_search_placeholder')!}
            onWppChange={e => setSearchDebounced(e.detail.value || '')}
            type="search"
            data-testid="dashboard-project-search"
            className={styles.searchInput}
          />

          <WppSegmentedControl size="s" variant="icon" value={viewType}>
            <Link to={routesManager.projects.cards.pattern}>
              <WppSegmentedControlItem variant="icon" value={ViewType.CARDS} data-testid="projects-card-view-button">
                <WppIconDataViewCards />
              </WppSegmentedControlItem>
            </Link>

            <Link to={routesManager.projects.table.pattern}>
              <WppSegmentedControlItem variant="icon" value={ViewType.TABLE} data-testid="projects-table-view-button">
                <WppIconDataViewList />
              </WppSegmentedControlItem>
            </Link>
          </WppSegmentedControl>
          <WppFilterButton
            counter={filtersCounts}
            onClick={() =>
              showProjectFilterModal({
                filter,
                handleCloseModal: handleCloseFilterModal,
              })
            }
          >
            {t('dashboard.btn_filter')!}
          </WppFilterButton>
        </Flex>
        <Flex>
          {canCreateProject && (
            <WppButton size="s" onClick={() => showCreateProjectModal()} data-testid="create-new-project">
              <WppIconPlus slot="icon-start" /> {t('dashboard.btn_add_project')}
            </WppButton>
          )}
        </Flex>
      </Flex>

      <div
        className={clsx(styles.viewContainer, {
          [styles.scroll]: viewType === ViewType.CARDS,
        })}
      >
        <Routes>
          <Route
            path="/"
            element={
              viewType === ViewType.CARDS ? (
                <Navigate to={routesManager.projects.cards.shortPattern} replace />
              ) : (
                <Navigate to={routesManager.projects.table.shortPattern} replace />
              )
            }
          />

          <Route
            path={routesManager.projects.cards.shortPattern}
            element={
              <OnRouteEnter onEnter={() => setViewType(ViewType.CARDS)}>
                <ProjectCardView filter={filter} />
              </OnRouteEnter>
            }
          />
          <Route
            path={routesManager.projects.table.shortPattern}
            element={
              <OnRouteEnter onEnter={() => setViewType(ViewType.TABLE)}>
                <ProjectsTableView filter={filter} />
              </OnRouteEnter>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Flex>
  )
}
