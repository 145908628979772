import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeletePhaseApi } from 'api/canvas/mutation/useDeletePhaseApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { LinearData } from 'pages/project/components/canvas/utils'
import { queryClient } from 'providers/osQueryClient/utils'

export function useDeleteLinearPhase({ projectId }: { projectId: string }) {
  const { showToast } = useToast()
  const { t } = useTranslation()

  const { mutateAsync: handleDeletePhase } = useDeletePhaseApi({
    onMutate: async deletePhaseData => {
      const projectLinearQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }]
      await queryClient.cancelQueries(projectLinearQueryKey)

      const previousLinear = queryClient.getQueryData<{ data: LinearData }>(projectLinearQueryKey)

      if (previousLinear) {
        const phases = { ...previousLinear.data.columns }
        delete phases[deletePhaseData.phaseId]

        const updatedData = {
          ...previousLinear.data,
          columns: phases,
          columnOrder: previousLinear.data.columnOrder.filter(phaseId => phaseId !== deletePhaseData.phaseId),
        }

        const newData = { ...previousLinear, data: updatedData }

        queryClient.setQueryData(projectLinearQueryKey, newData)
      }

      return { previousLinear }
    },
    onError: (_error, _, context) => {
      if (context?.previousLinear) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }], context.previousLinear)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
    },
  })

  const deletePhase = useCallback(
    async (id: string, name: string) => {
      try {
        await handleDeletePhase({ phaseId: id })

        showToast({ type: 'success', message: t('project.canvas.toast.delete_phase', { query: name }) })
      } catch (error) {
        showToast({
          type: 'error',
          message: t('common.generic_error'),
        })
      }
    },
    [handleDeletePhase, showToast, t],
  )

  return { deletePhase }
}
