// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oFYMf{margin:0 12px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/item/activity/DragActivityItem.module.scss"],"names":[],"mappings":"AAAA,OACE,aAAA","sourcesContent":[".dropZone {\n  margin: 0 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropZone": `oFYMf`
};
export default ___CSS_LOADER_EXPORT___;
