import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/canvas/components/lockOverlay/LockOverlay.module.scss'

export const LockOverlay = () => {
  const { t } = useTranslation()

  return (
    <Flex align="center" justify="center" className={styles.lockOverlay} data-testid="canvas-lock-overlay">
      <Flex direction="column" align="center">
        <WppTypography type="s-body" data-testid="canvas-user-editing">
          {t('project.canvas.lock.user_editing')}
        </WppTypography>
        <WppTypography type="xs-body" data-testid="canvas-user-wait">
          {t('project.canvas.lock.wait')}
        </WppTypography>
      </Flex>
    </Flex>
  )
}
