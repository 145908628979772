import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'

import { useProject } from 'hooks/useProject'
import { Members } from 'types/members/members'
import { isEqualEmails } from 'utils/common'

export const useAssignMember = (email?: MayBeNull<string>): Members | undefined => {
  const context = useProject()

  // TODO temporary solution, to prevent errors when hook is called not in the project context
  const assignMember = useMemo(
    () => context?.members.find(member => isEqualEmails(member.email, email)),
    [context?.members, email],
  )

  if (!assignMember) {
    return
  }

  return assignMember
}
