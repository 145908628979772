// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V0ye4{--wpp-list-item-width: 100%;--li-width: 100%;width:100%}.V0ye4+.V0ye4{margin-top:8px}.V0ye4 .xsgAj{display:none}.V0ye4:hover .xsgAj{display:block}.qCFCi{width:40px;height:40px;padding:8px;border:1px solid var(--wpp-grey-color-300);border-radius:8px}.qCFCi img{width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/appPikerModal/selectedAppsModal/SelectedAppsModal.module.scss"],"names":[],"mappings":"AAAA,OACE,2BAAA,CACA,gBAAA,CAEA,UAAA,CAEA,cACE,cAAA,CAGF,cACE,YAAA,CAIA,oBACE,aAAA,CAKN,OACE,UAAA,CACA,WAAA,CACA,WAAA,CACA,0CAAA,CACA,iBAAA,CAEA,WACE,UAAA,CACA,WAAA","sourcesContent":[".listItem {\n  --wpp-list-item-width: 100%;\n  --li-width: 100%;\n\n  width: 100%;\n\n  & + & {\n    margin-top: 8px;\n  }\n\n  .removeBtn {\n    display: none;\n  }\n\n  &:hover {\n    .removeBtn {\n      display: block;\n    }\n  }\n}\n\n.logo {\n  width: 40px;\n  height: 40px;\n  padding: 8px;\n  border: 1px solid var(--wpp-grey-color-300);\n  border-radius: 8px;\n\n  img {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `V0ye4`,
	"removeBtn": `xsgAj`,
	"logo": `qCFCi`
};
export default ___CSS_LOADER_EXPORT___;
