export enum NativeAppsCommercialModel {
  CONTRACT = 'CONTRACT',
  FREE = 'FREE',
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
}

export enum NativeAppsCategory {
  CREATIVE = 'CREATIVE',
  DATA = 'DATA',
  EXPERIENCE = 'EXPERIENCE',
  MEDIA = 'MEDIA',
}

export enum NativeAppVersionType {
  DEMO = 'DEMO',
  PRODUCTION = 'PRODUCTION',
  DEVELOPMENT = 'DEVELOPMENT',
}

export interface NativeAppMarket {
  region: string
}

export interface NativeAppVersionDTO {
  id: string
  name: string
  versionType: NativeAppVersionType
}

export enum NativeAppType {
  NATIVE = 'NATIVE',

  // no code app types
  CODE_UPLOAD = 'CODE_UPLOAD',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  EMBEDDED_CODE = 'EMBEDDED_CODE',
  EMBEDDED_LINK = 'EMBEDDED_LINK',
  MIRO_BOARD = 'MIRO_BOARD',
  PAGE_BUILDER = 'PAGE_BUILDER',
}

export interface NativeAppDTO {
  id: string
  name: string
  ownerName: string
  shortDescription: string
  categories: NativeAppsCategory[]
  commercialModel: NativeAppsCommercialModel
  certificationLevel: ['COMING_SOON']
  markets: NativeAppMarket[]
  logoUrl?: string
  versions: NativeAppVersionDTO[]
  type: NativeAppType
}
