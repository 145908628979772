// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{background-color:var(--wpp-grey-color-100)}.qOYeG{height:calc(100vh - var(--wpp-os-header-height));overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,MACE,0CAAA,CAGF,OACE,gDAAA,CACA,eAAA","sourcesContent":[":root {\n  background-color: var(--wpp-grey-color-100);\n}\n\n.heightContainer {\n  height: calc(100vh - var(--wpp-os-header-height));\n  overflow: hidden;\n}\n\n@mixin containerWidthBoundaries {\n  width: 100%;\n  max-width: 1920px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heightContainer": `qOYeG`
};
export default ___CSS_LOADER_EXPORT___;
