import {
  WppCard,
  WppTypography,
  WppActionButton,
  WppIconChevron,
  WppSkeleton,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchDashboardWorkflowWidgetApi } from 'api/projects/queries/useFetchDashboardWorkflowWidgetApi'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/projectDashboard/coponents/workflowWidget/WorkflowWidget.module.scss'

const WorkflowWidgetSkeleton = () => (
  <Flex className={styles.skeletonContainer}>
    <WppSkeleton variant="rectangle" height="224px" width="100%" />
  </Flex>
)

export const WorkflowWidget = () => {
  const { t } = useTranslation()

  const { project } = useProject()

  const { data: workflowWidgetData, isLoading } = useFetchDashboardWorkflowWidgetApi({
    params: { projectId: project.id },
  })

  const descriptionExists = useMemo(() => {
    return !!workflowWidgetData?.phases.find(phase => !!phase.description)
  }, [workflowWidgetData])

  return (
    <Flex direction="column" className={styles.workflowWidgetWrapper}>
      {isLoading ? (
        <WorkflowWidgetSkeleton />
      ) : (
        <>
          {!!workflowWidgetData?.phases.length && (
            <WppCard className={styles.card} data-testid="workflow-widget">
              <WppTypography slot="header" type="l-strong" data-testid="workflow-widget-title">
                {t('project.workflow_widget.title')}
              </WppTypography>
              <div className={styles.grid}>
                {workflowWidgetData?.phases.map((phase, index) => (
                  <WppCard
                    key={phase.name + index}
                    variant="secondary"
                    className={styles.subCard}
                    data-testid="workflow-widget-phase"
                  >
                    <WppTypography slot="header" type="m-strong" data-testid="workflow-phase-name">
                      {phase.name}
                    </WppTypography>
                    <Flex direction="column" gap={12}>
                      <Truncate
                        lines={2}
                        type="s-body"
                        className={clsx({ [styles.placeholder]: descriptionExists })}
                        data-testid="workflow-phase-description"
                      >
                        {phase?.description}
                      </Truncate>
                      <WppActionButton>
                        {t('project.workflow_widget.view')}
                        <WppIconChevron direction="right" slot="icon-end" />
                      </WppActionButton>
                    </Flex>
                  </WppCard>
                ))}
              </div>
            </WppCard>
          )}
        </>
      )}
    </Flex>
  )
}
