// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CDv8r{display:flex;align-items:center;justify-content:center;width:24px;height:24px;color:var(--wpp-grey-color-800);font-size:12px;line-height:1;background:var(--wpp-white-color);border:2px solid var(--wpp-grey-color-800);border-radius:50%;cursor:pointer;opacity:0}.react-flow__edge-customEdge:hover .CDv8r{opacity:1}.E8uxf div{display:flex;align-items:center;justify-content:center;width:24px;height:24px;min-height:24px;background:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/fluidCanvas/components/fluidEdges/CustomEdge.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA,CACA,cAAA,CACA,aAAA,CACA,iCAAA,CACA,0CAAA,CACA,iBAAA,CACA,cAAA,CACA,SAAA,CAKE,0CACE,SAAA,CAKN,WACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,wBAAA","sourcesContent":[".edgeButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 24px;\n  height: 24px;\n  color: var(--wpp-grey-color-800);\n  font-size: 12px;\n  line-height: 1;\n  background: var(--wpp-white-color);\n  border: 2px solid var(--wpp-grey-color-800);\n  border-radius: 50%;\n  cursor: pointer;\n  opacity: 0;\n}\n\n:global(.react-flow__edge-customEdge) {\n  &:hover {\n    .edgeButton {\n      opacity: 1;\n    }\n  }\n}\n\n.edgebuttonForeignObject div {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 24px;\n  height: 24px;\n  min-height: 24px;\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edgeButton": `CDv8r`,
	"edgebuttonForeignObject": `E8uxf`
};
export default ___CSS_LOADER_EXPORT___;
