// Product Category
import { NativeAppsCategory, NativeAppsCommercialModel } from 'types/products/nativeApp'

export const NativeAppsCategoryLabel = {
  [NativeAppsCategory.CREATIVE]: 'Creative',
  [NativeAppsCategory.DATA]: 'Data',
  [NativeAppsCategory.EXPERIENCE]: 'Experience',
  [NativeAppsCategory.MEDIA]: 'Media',
}

export const nativeAppsCategoryOptions = [
  ...Object.entries(NativeAppsCategoryLabel).map(([key, value]) => ({ label: value, value: key })),
]

// Product Commercial model
export const NativeAppsCommercialModelLabel = {
  [NativeAppsCommercialModel.CONTRACT]: 'Contract',
  [NativeAppsCommercialModel.FREE]: 'Free',
  [NativeAppsCommercialModel.PAY_AS_YOU_GO]: 'Pay-as-you-go',
}

export const nativeAppsCommercialModelOptions = [
  ...Object.entries(NativeAppsCommercialModelLabel).map(([key, value]) => ({ label: value, value: key })),
]
