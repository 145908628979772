import { projectApi } from 'api'
import { DashboardOwnership, DashboardProgressData } from 'types/projects/DashboardData'

interface Props {
  projectId: string
  subset: DashboardOwnership
}

export interface DashboardProgressResponse {
  phases: DashboardProgressData[]
}

export const fetchDashboardDataApi = ({ projectId, subset }: Props) =>
  projectApi.get<DashboardProgressResponse>(`/projects/${projectId}/dashboard/tasks-progress`, { params: { subset } })
