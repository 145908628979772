import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { PermissionsArg, useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { NativeAppType } from 'types/products/nativeApp'
import { ApplicationItem } from 'types/projects/workflow'
import { findNavigationTreeParentNodeAzId } from 'utils/navigationTree'

export const useCheckCaasRequirements = (application: ApplicationItem) => {
  const { isPermitted } = useIsPermitted()
  const projectContext = useProject()

  // @TODO that's a temporary workaround. All project-coupled logic should be move out of common components
  const project = projectContext ? projectContext.project : null

  const { config, type } = application
  const {
    osContext: { tenant, navigationTree },
  } = useOs()

  const isCaasAppHavePermission = useMemo(
    () => (!!config?.view_permission_name ? isPermitted(config?.view_permission_name as PermissionsArg) : true),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config],
  )

  /*
   required_hierarchy: ['BRAND']  Client -> Market -> Brand -- valid
   required_hierarchy: ['Market'] Client -> Market -> Brand -- invalid, Client -> Market -- valid
   required_hierarchy: ['TENANT'] without workspace -- valid, with any workspace -- invalid
   required_hierarchy: [] - any workspace lvl is valid
   */

  const isCaasHasCurrentWorkspace = useMemo(() => {
    if (!(type === NativeAppType.NATIVE) || !config?.required_hierarchy?.length) return true

    const currWorkspaces = findNavigationTreeParentNodeAzId(project?.contextWorkspace, navigationTree.mapping)
    const currIndx = currWorkspaces.map(lvl => tenant.hierarchyLevels.findIndex(tLvl => tLvl.type === lvl.type)) || []
    const appIndx =
      config?.required_hierarchy?.map(lvl => tenant.hierarchyLevels.findIndex(tLvl => tLvl.type === lvl)) || []

    return appIndx.includes(Math.max(...currIndx))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, config?.required_hierarchy, project?.contextWorkspace])

  return { isCaasAppHavePermission, isCaasHasCurrentWorkspace }
}
