import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { usePatchPhaseApi } from 'api/canvas/mutation/usePatchPhaseApi'
import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/canvas/components/editPhaseModal/EditPhaseModal.module.scss'
import { invalidateCanvas } from 'pages/project/components/canvas/components/item/utils'
import { SelectPerson } from 'pages/project/components/canvas/components/selectPerson/SelectPerson'
import {
  ResponsibleUser,
  useGetBaseFormValues,
  getFormattedDates,
} from 'pages/project/components/canvas/components/selectPerson/utils'
import { Column } from 'pages/project/components/canvas/utils'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { makeStringShorter } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  phase: Column
  isDisabled?: boolean
}

const phaseValidationSchema = zod
  .object({
    name: zod
      .string()
      .min(1, 'Name should contain at least 1 character')
      .max(64, 'Name should have 64 characters at most'),
    description: zod.string().max(150, 'Description should have 150 characters at most').optional(),
  })
  .passthrough()

const EditPhaseModal = ({ phase, onClose, onCloseComplete, isOpen, isDisabled }: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const defaultValues = { ...phase, description: phase.description || '', ...useGetBaseFormValues(phase) }
  const form = useForm({ defaultValues, validationSchema: phaseValidationSchema })

  const { mutateAsync: handleUpdatePhase } = usePatchPhaseApi()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(async ({ dueDate, description, name, assignUser: assignedUsers }) => {
    try {
      const assignUser = assignedUsers[0] as ResponsibleUser | undefined

      await handleUpdatePhase({
        ...getFormattedDates(dueDate),
        name: name.trim(),
        phaseId: phase.id,
        assignUser: assignUser?.email || null,
        description: description?.trim() || null,
      })

      await invalidateCanvas(!assignUser?.isMember)
      showToast({
        type: 'success',
        message: t('project.canvas.toast.update_phase', { query: makeStringShorter(name) }),
      })

      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_operation_edit', { query: 'phase' }),
      })
      console.log(e)
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('modals.settings.phase_settings')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <FormInput
            name="name"
            labelConfig={{ text: t('project.phase.settings_modal.name_label') }}
            placeholder={t('project.phase.settings_modal.name_placeholder')}
            required
            disabled={isDisabled}
            data-testid="modal-phase-name"
          />
          <FormTextareaInput
            name="description"
            labelConfig={{ text: t('project.phase.settings_modal.description_label') }}
            placeholder={t('project.phase.settings_modal.description_placeholder')}
            warningThreshold={130}
            charactersLimit={150}
            disabled={isDisabled}
            data-testid="modal-phase-description"
          />
          <SelectPerson disabled={isDisabled} />
          <FormDatepicker
            className={styles.datePicker}
            name="dueDate"
            placeholder={t('project.phase.settings_modal.dates_placeholder')}
            labelConfig={{ text: t('project.phase.settings_modal.dates_label') }}
            range
            disabled={isDisabled}
          />
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} disabled={isDisabled}>
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}
export const { showModal: showEditPhaseModal } = createProjectModal<Props>(EditPhaseModal, 'edit-phase-modal')
