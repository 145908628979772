import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { Phase } from 'types/projects/workflow'

export interface PatchPhase {
  phaseId: string
  name?: string
  description?: MayBeNull<string>
  startDate?: MayBeNull<string>
  endDate?: MayBeNull<string>
  assignUser?: MayBeNull<string>
}

export const patchPhaseApi = ({ phaseId, ...body }: PatchPhase) => projectApi.patch<Phase>(`/phases/${phaseId}`, body)
