import { NavigationTreeNode, NavigationTreeMapping, HierarchyLevelType } from '@wpp-open/core'
export const findNavigationTreeParentNodeAzId = (childAzId: string = '', mapping: NavigationTreeMapping) => {
  if (!childAzId || !Object.keys(mapping).includes(childAzId)) return []

  const tree = [mapping[childAzId]]

  if (!tree[0].type) {
    return []
  }

  if (tree[0].type === HierarchyLevelType.Brand) {
    const parentNode = Object.values(mapping).find(({ children }) => children?.includes(tree[tree.length - 1].azId!))
    if (parentNode) tree.push(parentNode)
  }

  if (tree[tree.length - 1].type === HierarchyLevelType.Market) {
    const parentNode = Object.values(mapping).find(({ children }) => children?.includes(tree[tree.length - 1].azId!))
    if (parentNode) tree.push(parentNode)
  }

  return tree
}

export const findNavigationTreeType = (nodeType: HierarchyLevelType, nodes: NavigationTreeNode[]) =>
  nodes.find(({ type }) => nodeType === type)
