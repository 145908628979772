import { createUseQuery } from 'api/common/createUseQuery'
import { fetchWorkflowTemplateByIdApi } from 'api/templates/fetchers/fetchWorkflowTemplateByIdApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ProcessType } from 'types/projects/projects'
import { ActivityItem, ApplicationItem, ContainerType, PhaseItemType } from 'types/projects/workflow'
import { FluidTemplateDetail } from 'types/projects/workflowTemplate'

export const useFetchWorkflowTemplateByIdApi = createUseQuery({
  queryKey: ApiQueryKeys.WORKFLOW_TEMPLATES_BY_ID,
  fetcher: fetchWorkflowTemplateByIdApi,
  selector: res => {
    // TODO tmp hack#2 (I hope so), to fix BE bug - orderNumberInRoot is not correct (e.g. all eq 0)
    //  @see https://jira.uhub.biz/browse/WPPLONOP-15058
    if (res?.data && res?.data.processType === ProcessType.FLUID) {
      const data = res?.data as FluidTemplateDetail

      const containersMap = Object.fromEntries(data.containers.map(container => [container.id, container]))
      data.containers.forEach(container => {
        if (container.itemType === PhaseItemType.Application && container.containerType === ContainerType.NESTED) {
          const rootContainer = containersMap[container.rootContainerId!]
          const orderNumberInRoot = (rootContainer.item as ActivityItem).items.find(
            item => item.application.externalAppId === (container.item as ApplicationItem)?.externalAppId,
          )?.orderNumber

          // @see https://jira.uhub.biz/browse/WPPLONOP-15051
          if (!Number.isInteger(orderNumberInRoot)) {
            console.warn(
              `Incorrect response, activity from container ${container.id} has no item ${
                (container.item as ApplicationItem)?.externalAppId
              } `,
            )
            return
          }

          if (container.orderNumberInRoot !== orderNumberInRoot) {
            console.warn(
              `Order number in nested container ${container.id} is not correct, fixing  ${container.orderNumberInRoot} => ${orderNumberInRoot}`,
            )
          }

          container.orderNumberInRoot = orderNumberInRoot
        }
      })

      return data
    }

    return res?.data ?? null
  },
})
