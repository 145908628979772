import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { ExternalLink } from 'types/projects/workflow'

export interface ActivityPostItemRequest {
  projectId: string
  name: string
  description?: MayBeNull<string>
  phaseId?: string
  startDate?: MayBeNull<string>
  endDate?: MayBeNull<string>
  assignUser?: MayBeNull<string>
  coordinateX?: number
  coordinateY?: number
  externalLinks?: ExternalLink[]
}

export const createActivity = (body: ActivityPostItemRequest) => projectApi.post('/activities', body)
