import { WppDatepicker, WppLabel } from '@platform-ui-kit/components-library-react'
import { format } from 'date-fns'
import { ComponentProps, forwardRef, useMemo, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/form/formDatepicker/FormDatepicker.module.scss'
import { useField } from 'hooks/form/useField'
import { DATE_FORMAT } from 'utils/dateFormat'

const dateFormat = DATE_FORMAT.DD_MM_YYYY
const RANGE_DATES_SEPARATOR = ' | '

type Props = Omit<ComponentProps<typeof WppDatepicker>, 'name' | 'value' | 'onChange' | 'onWppChange'> & {
  id?: string
  name: string
  required?: boolean
}

export const FormDatepicker = forwardRef<HTMLWppDatepickerElement, Props>(
  ({ id, name, message, messageType, required, range, placeholder, labelConfig, disabled, ...rest }, ref) => {
    const {
      field: { value, onChange },
      fieldState: { isTouched, error },
    } = useField({
      name,
    })

    const identifier = id || name
    const errorText = error?.message
    const shouldShowError = isTouched && !!errorText

    const innerRef = useRef<HTMLWppDatepickerElement>(null)

    const mappedValue = useMemo(() => {
      const isRange = Array.isArray(value)
      const isEmpty = (isRange && !value.length) || !value

      const newValue = isEmpty
        ? ''
        : isRange
        ? value?.map(date => format(new Date(date), dateFormat))
        : format(new Date(value), dateFormat)

      return isEmpty ? undefined : newValue
    }, [value])

    const dateFormatTitle = dateFormat.toLowerCase()

    return (
      <Flex direction="column" gap={8} className={styles.root} data-testid="form-datepicker">
        {/* TODO: remove once WppDatepicker will support disabled styling for label */}
        <WppLabel config={labelConfig} optional={!required} disabled={disabled} htmlFor={id} />
        <WppDatepicker
          {...rest}
          ref={mergeRefs([innerRef, ref])}
          id={identifier}
          placeholder={
            placeholder || (range ? `${dateFormatTitle}${RANGE_DATES_SEPARATOR}${dateFormatTitle}` : dateFormatTitle)
          }
          value={mappedValue}
          onWppChange={e => onChange(e.detail.date || [])}
          onWppDateClear={() => onChange([])}
          message={shouldShowError ? errorText : message}
          messageType={shouldShowError ? 'error' : messageType}
          required={required}
          range={range}
          locale={{ dateFormat }}
          disabled={disabled}
        />
      </Flex>
    )
  },
)
