import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react'

import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useProject } from 'hooks/useProject'
import { useMoveLinearActivity } from 'pages/project/components/canvas/hooks/useMoveLinearActivity'
import { useMoveLinearApp } from 'pages/project/components/canvas/hooks/useMoveLinearApp'
import { usePatchLinearWorkflow } from 'pages/project/components/canvas/hooks/usePatchLinearWorkflow'
import { LinearData, DnDItem, DropPosition, HzDropPosition, initData } from 'pages/project/components/canvas/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import {
  ActivityApplicationItem,
  ActivityItem,
  ApplicationItem,
  PhaseItem,
  PhaseItemType,
} from 'types/projects/workflow'

interface ValueContextProps {
  canvasData: LinearData
}

interface DispatchContextProps {
  moveColumn: (targetId: string, sourceId: string, position: HzDropPosition) => void

  dropOnColumn: (targetColumnId: string, droppedItem: DnDItem, place: DropPosition) => void
  dropOnActivity: (activityId: string, droppedItem: DnDItem) => void
  dropOnInnerApp: (
    columnId: string,
    target: ActivityApplicationItem,
    droppedItem: DnDItem,
    direction: DropPosition,
  ) => void
  dropOnPhaseItem: (targetColumnId: string, target: PhaseItem, droppedItem: DnDItem, direction: DropPosition) => void
}

export const LinearDispatchContext = createContext<DispatchContextProps>(null!)
export const LinearValueContext = createContext<ValueContextProps>(null!)

export const LinearProvider = ({ children }: PropsWithChildren<{}>) => {
  const { canvas, project } = useProject()

  const { patchWorkflow } = usePatchLinearWorkflow({ projectId: project.id })
  const { moveActivity } = useMoveLinearActivity({ projectId: project.id })
  const { moveApp } = useMoveLinearApp({ projectId: project.id })

  // we need this local copy of the canvas data ONLY because updating canvas in the context is leading to
  // the items jump during DnD. That's a bug and should be fixed
  const [canvasData, setCanvasData] = useState(initData)

  useEffect(() => {
    setCanvasData(canvas)
  }, [canvas])

  // sync linear canvas with BE
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
    }
  }, [])

  const moveColumn = useCallback(
    async (dropColId: string, dragColId: string, position: HzDropPosition) => {
      const newColumnOrder = [...canvasData.columnOrder]
      const dragIndex = newColumnOrder.indexOf(dragColId)
      newColumnOrder.splice(dragIndex, 1)

      const dropIndex = newColumnOrder.indexOf(dropColId)
      newColumnOrder.splice(position === HzDropPosition.Left ? dropIndex : dropIndex + 1, 0, dragColId)

      setCanvasData({ ...canvasData, columnOrder: newColumnOrder })

      await patchWorkflow({
        id: project.id,
        phases: newColumnOrder.map((id, orderNumber) => ({ id, orderNumber })),
        optimisticData: {
          columnOrder: newColumnOrder,
        },
      })
    },
    [canvasData, patchWorkflow, project.id],
  )

  const dropOnColumn = useCallback(
    async (targetColumnId: string, droppedItem: DnDItem, place: DropPosition) => {
      const prevCanvas = canvasData

      const startColumn = prevCanvas.columns[droppedItem.columnId]
      const finishColumn = prevCanvas.columns[targetColumnId]
      const startActivityItem = prevCanvas.tasks[
        (prevCanvas.tasks[droppedItem.id] as unknown as ActivityApplicationItem)?.activityItemId
      ] as PhaseItem<ActivityItem>

      // case: activity-column
      if (startActivityItem) {
        // remove item from Activity
        const removedIndex = startActivityItem.item.items.findIndex(({ id }) => id === droppedItem.id)
        const startActivityAppItems = [...startActivityItem.item.items]
        const slicedApp = startActivityAppItems.splice(removedIndex, 1)

        const startActivity: PhaseItem<ActivityItem> = {
          ...startActivityItem,
          item: {
            ...startActivityItem.item,
            items: startActivityAppItems,
          },
        }

        // insert item into Column
        const finishTaskIds =
          place === DropPosition.Above
            ? [droppedItem.id, ...finishColumn.taskIds]
            : [...finishColumn.taskIds, droppedItem.id]
        const dropIndex = place === DropPosition.Above ? 0 : finishTaskIds.length - 1
        const newFinish = {
          ...finishColumn,
          taskIds: finishTaskIds,
        }

        const newPhaseItem: PhaseItem<ApplicationItem> = {
          item: slicedApp[0].application,
          orderNumber: dropIndex,
          phaseId: newFinish.id,
          itemType: PhaseItemType.Application,
          id: slicedApp[0].id,
        }

        const updatedCanvasData = {
          tasks: { ...prevCanvas.tasks, [newPhaseItem.id]: newPhaseItem, [startActivity.id]: startActivity },
          columns: { ...prevCanvas.columns, [newFinish.id]: newFinish },
        }

        setCanvasData({
          ...prevCanvas,
          ...updatedCanvasData,
        })

        await moveApp({
          id: newPhaseItem.item.id,
          phaseId: newPhaseItem.phaseId,
          orderNumber: newPhaseItem.orderNumber,
          optimisticData: updatedCanvasData,
        })

        return
      }

      // case: column-column
      // case: reorder in column
      if (startColumn === finishColumn) {
        // remove item from Column
        const taskIds = startColumn.taskIds.filter(taskId => taskId !== droppedItem.id)
        // insert item into Column
        const newTaskIds = place === DropPosition.Above ? [droppedItem.id, ...taskIds] : [...taskIds, droppedItem.id]

        const newColumn = {
          ...startColumn,
          taskIds: newTaskIds,
        }

        const updatedCanvasData = { columns: { ...prevCanvas.columns, [newColumn.id]: newColumn } }

        setCanvasData({
          ...prevCanvas,
          ...updatedCanvasData,
        })

        const phaseItem = prevCanvas.tasks[droppedItem.id] as PhaseItem<ApplicationItem | ActivityItem>
        if (droppedItem.type === PhaseItemType.Application) {
          await moveApp({
            phaseId: newColumn.id,
            id: phaseItem.item.id,
            orderNumber: newColumn.taskIds.indexOf(droppedItem.id),
            optimisticData: updatedCanvasData,
          })
        } else {
          await moveActivity({
            phaseId: newColumn.id,
            id: phaseItem.item.id,
            orderNumber: newColumn.taskIds.indexOf(droppedItem.id),
            optimisticData: updatedCanvasData,
          })
        }
        return
      }

      // remove item from Column
      const startTaskIds = startColumn.taskIds.filter(taskId => taskId !== droppedItem.id)
      const newStart = {
        ...startColumn,
        taskIds: startTaskIds,
      }

      // insert item into Column
      const finishTaskIds =
        place === DropPosition.Above
          ? [droppedItem.id, ...finishColumn.taskIds]
          : [...finishColumn.taskIds, droppedItem.id]
      const newFinish = {
        ...finishColumn,
        taskIds: finishTaskIds,
      }

      const movedItem = prevCanvas.tasks[droppedItem.id] as PhaseItem
      const updatedCanvasData = {
        columns: { ...prevCanvas.columns, [newStart.id]: newStart, [newFinish.id]: newFinish },
        tasks: {
          ...prevCanvas.tasks,
          [movedItem.id]: {
            ...movedItem,
            phaseId: finishColumn.id,
          },
        },
      }

      setCanvasData({
        ...prevCanvas,
        ...updatedCanvasData,
      })

      const phaseItem = prevCanvas.tasks[droppedItem.id] as PhaseItem<ActivityItem | ApplicationItem>
      if (droppedItem.type === PhaseItemType.Application) {
        await moveApp({
          phaseId: finishColumn.id,
          id: phaseItem.item.id,
          orderNumber: newFinish.taskIds.indexOf(droppedItem.id),
          optimisticData: updatedCanvasData,
        })
      } else {
        await moveActivity({
          phaseId: finishColumn.id,
          id: phaseItem.item.id,
          orderNumber: newFinish.taskIds.indexOf(droppedItem.id),
          optimisticData: updatedCanvasData,
        })
      }
    },
    [canvasData, moveApp, moveActivity],
  )

  const dropOnPhaseItem = useCallback(
    async (targetColumnId: string, target: PhaseItem, droppedItem: DnDItem, direction: DropPosition) => {
      const prevCanvas = canvasData

      const startColumn = prevCanvas.columns[droppedItem.columnId]
      const finishColumn = prevCanvas.columns[targetColumnId]
      const startActivityItem = prevCanvas.tasks[
        (prevCanvas.tasks[droppedItem.id] as ActivityApplicationItem)?.activityItemId
      ] as PhaseItem<ActivityItem>

      if (!(startColumn || startActivityItem) || !finishColumn) {
        throw new Error(`[dropOnPhaseItem] unable to locate ${JSON.stringify(droppedItem)}`)
      }

      // case: activity-column
      if (startActivityItem) {
        // remove item from Activity
        const removedIndex = startActivityItem.item.items.findIndex(({ id }) => id === droppedItem.id)
        const startActivityAppItems = [...startActivityItem.item.items]
        const slicedApp = startActivityAppItems.splice(removedIndex, 1)

        const startActivity: PhaseItem<ActivityItem> = {
          ...startActivityItem,
          item: {
            ...startActivityItem.item,
            items: startActivityAppItems,
          },
        }

        // insert item into Column
        const finishTaskIds = [...finishColumn.taskIds]
        const targetIndex = finishTaskIds.indexOf(target.id)
        const dropIndex = direction === DropPosition.Above ? targetIndex : targetIndex + 1
        finishTaskIds.splice(dropIndex, 0, droppedItem.id)
        const newFinish = {
          ...finishColumn,
          taskIds: finishTaskIds,
        }

        const newPhaseItem: PhaseItem<ApplicationItem> = {
          item: slicedApp[0].application,
          orderNumber: dropIndex,
          phaseId: newFinish.id,
          itemType: PhaseItemType.Application,
          id: slicedApp[0].id,
        }

        const updatedCanvasData = {
          tasks: { ...prevCanvas.tasks, [newPhaseItem.id]: newPhaseItem, [startActivity.id]: startActivity },
          columns: { ...prevCanvas.columns, [newFinish.id]: newFinish },
        }

        setCanvasData({
          ...prevCanvas,
          ...updatedCanvasData,
        })

        await moveApp({
          id: newPhaseItem.item.id,
          phaseId: newPhaseItem.phaseId,
          orderNumber: newPhaseItem.orderNumber,

          optimisticData: updatedCanvasData,
        })

        return
      }

      // case: column-column
      // case: reorder in column
      if (startColumn === finishColumn) {
        // remove item from Column
        const taskIds = startColumn.taskIds.filter(taskId => taskId !== droppedItem.id)
        const targetIndex = taskIds.indexOf(target.id)
        const dropIndex = direction === DropPosition.Above ? targetIndex : targetIndex + 1

        // insert item into Column
        taskIds.splice(dropIndex, 0, droppedItem.id)

        const newColumn = {
          ...startColumn,
          taskIds,
        }

        const updatedCanvasData = { columns: { ...prevCanvas.columns, [newColumn.id]: newColumn } }

        setCanvasData({
          ...prevCanvas,
          ...updatedCanvasData,
        })

        const phaseItem = prevCanvas.tasks[droppedItem.id] as PhaseItem<ApplicationItem | ActivityItem>
        if (droppedItem.type === PhaseItemType.Application) {
          await moveApp({
            phaseId: newColumn.id,
            id: phaseItem.item.id,
            orderNumber: dropIndex,
            optimisticData: updatedCanvasData,
          })
        } else {
          await moveActivity({
            phaseId: newColumn.id,
            id: phaseItem.item.id,
            orderNumber: dropIndex,
            optimisticData: updatedCanvasData,
          })
        }

        return
      }

      // case: move from one column to another
      // remove item from Column
      const startTaskIds = startColumn.taskIds.filter(taskId => taskId !== droppedItem.id)
      const newStart = {
        ...startColumn,
        taskIds: startTaskIds,
      }

      // insert item into Column
      const finishTaskIds = [...finishColumn.taskIds]
      const targetIndex = finishTaskIds.indexOf(target.id)
      const dropIndex = direction === DropPosition.Above ? targetIndex : targetIndex + 1

      finishTaskIds.splice(dropIndex, 0, droppedItem.id)
      const newFinish = {
        ...finishColumn,
        taskIds: finishTaskIds,
      }

      const movedItem = prevCanvas.tasks[droppedItem.id] as PhaseItem
      const updatedCanvasData = {
        columns: { ...prevCanvas.columns, [newStart.id]: newStart, [newFinish.id]: newFinish },
        tasks: {
          ...prevCanvas.tasks,
          [movedItem.id]: {
            ...movedItem,
            phaseId: finishColumn.id,
          },
        },
      }

      setCanvasData({
        ...prevCanvas,
        ...updatedCanvasData,
      })

      const phaseItem = prevCanvas.tasks[droppedItem.id] as PhaseItem<ApplicationItem | ActivityItem>
      if (droppedItem.type === PhaseItemType.Application) {
        await moveApp({
          phaseId: finishColumn.id,
          id: phaseItem.item.id,
          orderNumber: newFinish.taskIds.indexOf(droppedItem.id),
          optimisticData: updatedCanvasData,
        })
      } else {
        await moveActivity({
          phaseId: finishColumn.id,
          id: phaseItem.item.id,
          orderNumber: dropIndex,
          optimisticData: updatedCanvasData,
        })
      }
    },
    [canvasData, moveApp, moveActivity],
  )

  const dropOnActivity = useCallback(
    async (activityId: string, droppedItem: DnDItem) => {
      const prevCanvas = canvasData

      const startColumn = prevCanvas.columns[droppedItem.columnId]
      const sourceAppActivityItem = prevCanvas.tasks[droppedItem.id] as unknown as ActivityApplicationItem
      const startActivityItem = prevCanvas.tasks[sourceAppActivityItem.activityItemId] as PhaseItem<ActivityItem>
      const finishActivityItem = prevCanvas.tasks[activityId] as PhaseItem<ActivityItem>

      if (!finishActivityItem) {
        throw new Error('Unexpected case - cannot find target activity')
      }

      //  reorder in the same activity using root dropzone, add app to the end
      if (startActivityItem === finishActivityItem) {
        // move item in Activity
        const activityAppItems = [...startActivityItem.item.items]

        const appIndex = activityAppItems.findIndex(({ id }) => id === droppedItem.id)
        const movedApp = activityAppItems[appIndex]

        // remove from old position
        activityAppItems.splice(appIndex, 1)

        // add to the end
        activityAppItems.push(movedApp)
        movedApp.orderNumber = activityAppItems.length - 1

        const newActivity = {
          ...startActivityItem,
          item: {
            ...startActivityItem.item,
            items: activityAppItems,
          },
        }

        const updatedCanvasData = {
          tasks: {
            ...prevCanvas.tasks,
            [newActivity.id]: newActivity,
          },
        }
        setCanvasData({
          ...prevCanvas,
          ...updatedCanvasData,
        })

        await moveApp({
          id: movedApp.application.id,
          activityId: newActivity.item.id,
          orderNumber: movedApp.orderNumber,

          optimisticData: updatedCanvasData,
        })
        return
      }

      // case: activity-activity
      if (startActivityItem && finishActivityItem) {
        // remove item from Activity
        const startActivityAppItems = startActivityItem.item.items.filter(({ id }) => id !== droppedItem.id)
        const startActivity = {
          ...startActivityItem,
          item: {
            ...startActivityItem.item,
            items: startActivityAppItems,
          },
        }

        // insert item into Activity
        const finishItems = [...finishActivityItem.item.items]
        const newAppActivityItem: ActivityApplicationItem = {
          ...sourceAppActivityItem,
          orderNumber: 0,
          activityItemId: finishActivityItem.id,
        }

        finishItems.splice(0, 0, newAppActivityItem)
        const finishActivity = {
          ...finishActivityItem,
          item: {
            ...finishActivityItem.item,
            items: finishItems,
          },
        }

        const updatedCanvasData = {
          tasks: {
            ...prevCanvas.tasks,
            [startActivity.id]: startActivity,
            [finishActivity.id]: finishActivity,
            [newAppActivityItem.id]: newAppActivityItem,
          },
        }

        setCanvasData({
          ...prevCanvas,
          ...updatedCanvasData,
        })

        await moveApp({
          id: newAppActivityItem.application.id,
          activityId: finishActivity.item.id,
          orderNumber: newAppActivityItem.orderNumber,

          optimisticData: updatedCanvasData,
        })
        return
      }

      // case: column-activity
      // remove item from Column
      const startTaskIds = startColumn.taskIds.filter(taskId => taskId !== droppedItem.id)
      const newStart = {
        ...startColumn,
        taskIds: startTaskIds,
      }

      // insert item into Activity
      const movedPhaseItem = prevCanvas.tasks[droppedItem.id] as PhaseItem<ApplicationItem>

      const finishItems = [...finishActivityItem.item.items]

      const newAppItem: ActivityApplicationItem = {
        id: movedPhaseItem.id,
        orderNumber: 0,
        application: movedPhaseItem.item,
        activityItemId: finishActivityItem.id,
      }
      finishItems.splice(0, 0, newAppItem)
      const newFinish = {
        ...finishActivityItem,
        item: {
          ...finishActivityItem.item,
          items: finishItems,
        },
      }

      const updatedCanvasData = {
        columns: { ...prevCanvas.columns, [newStart.id]: newStart },
        tasks: { ...prevCanvas.tasks, [newFinish.id]: newFinish, [newAppItem.id]: newAppItem },
      }

      setCanvasData({
        ...prevCanvas,
        ...updatedCanvasData,
      })

      await moveApp({
        id: newAppItem.application.id,
        activityId: newFinish.item.id,
        orderNumber: newAppItem.orderNumber,

        optimisticData: updatedCanvasData,
      })
      return
    },
    [canvasData, moveApp],
  )

  const dropOnInnerApp = useCallback(
    async (columnId: string, target: ActivityApplicationItem, droppedItem: DnDItem, direction: DropPosition) => {
      const prevCanvas = canvasData
      const startColumn = prevCanvas.columns[droppedItem.columnId]
      const sourceAppActivityItem = prevCanvas.tasks[droppedItem.id] as unknown as ActivityApplicationItem
      const startActivityItem = prevCanvas.tasks[sourceAppActivityItem.activityItemId] as PhaseItem<ActivityItem>
      const finishActivityItem = prevCanvas.tasks[target.activityItemId] as PhaseItem<ActivityItem>

      // case: activity-activity
      if (startActivityItem && finishActivityItem) {
        // case: reorder in activity
        if (startActivityItem === finishActivityItem) {
          // remove item from Activity
          const activityAppItems = finishActivityItem.item.items.filter(({ id }) => id !== droppedItem.id)
          const targetIndex = activityAppItems.findIndex(({ id }) => id === target.id)
          const dropIndex = direction === DropPosition.Above ? targetIndex : targetIndex + 1

          // insert item into Activity
          const newAppActivityItem: ActivityApplicationItem = {
            ...sourceAppActivityItem,
            orderNumber: dropIndex,
            activityItemId: finishActivityItem.id,
          }
          activityAppItems.splice(dropIndex, 0, newAppActivityItem)

          const newActivity = {
            ...finishActivityItem,
            item: {
              ...finishActivityItem.item,
              items: activityAppItems,
            },
          }

          const updatedCanvasData = {
            tasks: {
              ...prevCanvas.tasks,
              [newActivity.id]: newActivity,
              [newAppActivityItem.id]: newAppActivityItem,
            },
          }
          setCanvasData({
            ...prevCanvas,
            ...updatedCanvasData,
          })

          await moveApp({
            id: newAppActivityItem.application.id,
            activityId: newActivity.item.id,
            orderNumber: newAppActivityItem.orderNumber,

            optimisticData: updatedCanvasData,
          })
          return
        }

        // remove item from Activity
        const startActivityAppItems = startActivityItem.item.items.filter(({ id }) => id !== droppedItem.id)
        const startActivity = {
          ...startActivityItem,
          item: {
            ...startActivityItem.item,
            items: startActivityAppItems,
          },
        }

        // insert item into Activity
        const finishItems = [...finishActivityItem.item.items]
        const targetIndex = finishItems.findIndex(({ id }) => id === target.id)
        const dropIndex = direction === DropPosition.Above ? targetIndex : targetIndex + 1
        const newAppActivityItem: ActivityApplicationItem = {
          ...sourceAppActivityItem,
          orderNumber: dropIndex,
          activityItemId: finishActivityItem.id,
        }

        finishItems.splice(dropIndex, 0, newAppActivityItem)
        const finishActivity = {
          ...finishActivityItem,
          item: {
            ...finishActivityItem.item,
            items: finishItems,
          },
        }

        const updatedCanvasData = {
          tasks: {
            ...prevCanvas.tasks,
            [startActivity.id]: startActivity,
            [finishActivity.id]: finishActivity,
            [newAppActivityItem.id]: newAppActivityItem,
          },
        }

        setCanvasData({
          ...prevCanvas,
          ...updatedCanvasData,
        })

        await moveApp({
          id: newAppActivityItem.application.id,
          activityId: finishActivity.item.id,
          orderNumber: newAppActivityItem.orderNumber,

          optimisticData: updatedCanvasData,
        })
        return
      }

      if (!startColumn || !finishActivityItem) {
        throw new Error(`[dropOnActivity] unable to locate ${JSON.stringify(droppedItem)}`)
      }

      // case: column-activity
      // remove item from Column
      const startTaskIds = startColumn.taskIds.filter(taskId => taskId !== droppedItem.id)
      const newStart = {
        ...startColumn,
        taskIds: startTaskIds,
      }

      // insert item into Activity
      const movedPhaseItem = prevCanvas.tasks[droppedItem.id] as PhaseItem<ApplicationItem>

      const finishItems = [...finishActivityItem.item.items]
      const targetIndex = finishItems.findIndex(({ id }) => id === target.id)
      const dropIndex = direction === DropPosition.Above ? targetIndex : targetIndex + 1

      const newAppItem: ActivityApplicationItem = {
        id: movedPhaseItem.id,
        orderNumber: dropIndex,
        application: movedPhaseItem.item,
        activityItemId: finishActivityItem.id,
      }

      finishItems.splice(dropIndex, 0, newAppItem)
      const newFinish = {
        ...finishActivityItem,
        item: {
          ...finishActivityItem.item,
          items: finishItems,
        },
      }

      const updatedCanvasData = {
        columns: { ...prevCanvas.columns, [newStart.id]: newStart },
        tasks: { ...prevCanvas.tasks, [newFinish.id]: newFinish, [newAppItem.id]: newAppItem },
      }

      setCanvasData({
        ...prevCanvas,
        ...updatedCanvasData,
      })

      await moveApp({
        id: newAppItem.application.id,
        activityId: newFinish.item.id,
        orderNumber: newAppItem.orderNumber,

        optimisticData: updatedCanvasData,
      })

      return
    },
    [canvasData, moveApp],
  )

  return (
    <LinearValueContext.Provider
      value={{
        canvasData,
      }}
    >
      <LinearDispatchContext.Provider
        value={{
          moveColumn,
          dropOnColumn,
          dropOnPhaseItem,
          dropOnActivity,
          dropOnInnerApp,
        }}
      >
        {children}
      </LinearDispatchContext.Provider>
    </LinearValueContext.Provider>
  )
}
