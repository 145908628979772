import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { FilesDTO } from 'types/projects/Files'

interface Props {
  projectId: string
  search?: string
  extensions?: string[]
  uploadedBy?: string[]
  pinned?: boolean
}

export const fetchProjectFilesApi = ({
  projectId,
  page = 1,
  itemsPerPage = 25,
  extensions,
  uploadedBy,
  search,
  pinned,
}: PaginationParams<Props>) =>
  projectApi.post<PaginatedResponse<FilesDTO>>(
    `/projects/${projectId}/files/search`,
    {
      ...(search?.length && { name: search }),
      ...(extensions?.length && { extensions }),
      ...(uploadedBy?.length && { uploadedByEmails: uploadedBy }),
    },
    {
      params: {
        page,
        itemsPerPage,
        ...(pinned && { pinned: true }),
      },
    },
  )
