import { ProjectRole } from 'types/permissions/permissions'
import { routesManager } from 'utils/routesManager'

export interface ProjectTab {
  id: string
  value: string
  roles?: ProjectRole[]
  forResponsible?: boolean
}

export const projectTabs: ProjectTab[] = [
  { id: routesManager.project.workflow.shortPattern, value: 'Workflow' },
  { id: routesManager.project.overview.shortPattern, value: 'About' },
  { id: routesManager.project.canvas.shortPattern, value: 'Canvas', roles: [ProjectRole.OWNER], forResponsible: true },
  { id: routesManager.project.members.shortPattern, value: 'Members' },
  { id: routesManager.project.tasks.shortPattern, value: 'Tasks' },
  { id: routesManager.project.files.shortPattern, value: 'Files' },
]
