import { WppCard, WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'

import { TasksParams } from 'api/projects/fetchers/fetchProjectTasksApi'
import { showConfirmModal } from 'components/common/confirmModal/ConfirmModal'
import { Flex } from 'components/common/flex/Flex'
import { Placeholder } from 'pages/project/components/canvas/components/placeholder/Placeholder'
import { DnDItem, DragContainerType } from 'pages/project/components/canvas/utils'
import { TaskItem } from 'pages/project/components/tasks/components/taskItem/TaskItem'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { Task, TaskStatus } from 'types/projects/tasks'

export const tasksSkeleton = () => (
  <Flex direction="row" gap={28} className={styles.tasksContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
  </Flex>
)

interface Props {
  statusKey: string
  tasks: Task[]
  projectId: string
  updateItem: ({ id, status }: { id: string; status: TaskStatus }) => Promise<void>
  filters: TasksParams
}

export const TasksStatusColumn = ({ statusKey, tasks, projectId, updateItem, filters }: Props) => {
  const { t } = useTranslation()
  const [placeholderHeight, setPlaceholderHeight] = useState<MayBeNull<number>>(null)
  const phaseItemRef = useRef<HTMLDivElement>(null)

  const updateStatus = useCallback(
    async (id: string, status: TaskStatus) => {
      await updateItem({ id, status })
    },
    [updateItem],
  )

  const [{ isOverItem, isOnSameColumn }, dropTaskItem] = useDrop(
    () => ({
      accept: DragContainerType.Task,
      hover(dndItem: DnDItem) {
        setPlaceholderHeight(dndItem.height ?? null)
      },
      drop(dndItem: DnDItem, monitor) {
        // if dropPhaseItem was already handled
        const didDrop = monitor.didDrop()

        if (didDrop) return

        if (!phaseItemRef.current) return

        if (dndItem.columnId === statusKey) return

        if (statusKey === TaskStatus.ARCHIVED || dndItem.columnId === TaskStatus.ARCHIVED) {
          return showConfirmModal({
            title:
              statusKey === TaskStatus.ARCHIVED
                ? t('project.tasks.archive_confirmation_title')
                : t('project.tasks.unarchive_confirmation_title'),
            btnSubmitText: t('common.btn_confirm')!,
            handleSubmit: () => updateStatus(dndItem.id, statusKey as TaskStatus),
            confirmMessage:
              statusKey === TaskStatus.ARCHIVED
                ? t('project.tasks.save_archive_confirmation_message')
                : t('project.tasks.save_unarchive_confirmation_message')!,
          })
        }
        updateStatus(dndItem.id, statusKey as TaskStatus)
      },
      collect: monitor => ({
        isOverItem: monitor.isOver({ shallow: true }),
        isOnSameColumn: monitor.getItem()?.columnId === statusKey,
      }),
    }),
    [],
  )
  dropTaskItem(phaseItemRef)

  return (
    <Flex ref={phaseItemRef} className={clsx(styles.column, styles.task, styles.columnOverview, styles.previewTask)}>
      {isOverItem && !isOnSameColumn && <Flex className={styles.dragOverContainer} />}

      <WppCard
        key={statusKey}
        size="m"
        className={clsx(styles.task, styles.columnOverview, styles.previewTask)}
        data-testid={`tasks-column-${statusKey}`}
      >
        <WppTypography
          slot="header"
          type="m-strong"
          title={t(`project.tasks.status.${statusKey}`)!}
          data-testid="task-column-name"
          className={styles.statusHeader}
        >
          {t(`project.tasks.status.${statusKey}`)!} ({tasks.length})
        </WppTypography>

        <Flex direction="column" gap={12}>
          {tasks.map((task, index) => (
            <TaskItem
              task={task}
              key={task.id}
              index={index}
              updateStatus={updateStatus}
              projectId={projectId}
              filters={filters}
            />
          ))}
          {isOverItem && <Placeholder height={placeholderHeight} />}
        </Flex>
      </WppCard>
    </Flex>
  )
}
