import {
  WppAvatarGroup,
  WppCard,
  WppDivider,
  WppIconMail,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useMemo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Avatar, getAvatarColor } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/projectDashboard/coponents/membersCard/MembersCard.module.scss'
import { Members } from 'types/members/members'
import { ProjectRole } from 'types/permissions/permissions'
import { fullName } from 'utils/common'

export const MembersCard = () => {
  const { t } = useTranslation()
  const { members } = useProject()

  const { owners, otherMembers } = useMemo(() => {
    return members.reduce<{ owners: Members[]; otherMembers: Members[] }>(
      (acc, member) => {
        if (member.role === ProjectRole.OWNER) {
          acc.owners.push(member)
        } else {
          acc.otherMembers.push(member)
        }

        return acc
      },
      { owners: [], otherMembers: [] },
    )
  }, [members])

  return (
    <WppCard variant="primary" className={styles.membersCard} size="l" data-testid="project-members-card">
      <WppTypography slot="header" type="l-strong">
        {t('overview.project_members')}
      </WppTypography>
      <Flex direction="column" gap={8}>
        <WppTypography type="2xs-strong" className={styles.subTitle}>
          {t('project.members_card.project_owners')}
        </WppTypography>

        <Flex direction="column" className={styles.membersWrapper} gap={12}>
          {owners.map(owner => (
            <Fragment key={owner.id}>
              <Flex gap={8} align="center">
                <Avatar size="s" name={fullName(owner.firstname, owner.lastname)} src={owner.avatarUrl ?? ''} />

                <Flex direction="column">
                  <WppTypography type="s-strong">{fullName(owner.firstname, owner.lastname)}</WppTypography>
                  <WppTypography type="s-body">{owner.email}</WppTypography>
                </Flex>

                <Link to={`mailto: ${owner.email}`} className={styles.emailIcon}>
                  <WppIconMail />
                </Link>
              </Flex>

              {owners[owners.length - 1].id !== owner.id && <WppDivider />}
            </Fragment>
          ))}
        </Flex>

        {!!otherMembers.length && (
          <>
            <WppDivider />
            <WppTypography type="2xs-strong" className={styles.subTitle}>
              {t('project.members_card.project_members')}
            </WppTypography>
            <WppAvatarGroup
              className={styles.otherMembers}
              maxAvatarsToDisplay={4}
              size="s"
              withTooltip
              users={otherMembers.map(member => ({
                name: fullName(member.firstname, member.lastname),
                src: member.avatarUrl ?? '',
                color: getAvatarColor({ name: member.firstname }),
              }))}
            />
          </>
        )}
      </Flex>
    </WppCard>
  )
}
