import { WppTooltip, WppIconInfo, WppActionButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useCheckCaasRequirements } from 'hooks/application/useCheckCaasRequirements'
import { useOpenApplication } from 'hooks/application/useOpenApplication'
import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import styles from 'pages/project/components/canvas/components/item/activity/AppActivityItem.module.scss'
import { useUiPartEnabled } from 'pages/project/components/canvas/components/item/utils'
import { Calendar } from 'pages/project/components/canvas/components/phase/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/phase/ResponsiblePerson'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
  isDisabled: boolean
  phaseId?: string
  inner?: boolean
  templateView?: boolean
}

export const PreviewApplication: FC<Props> = ({ application, isDisabled, inner, templateView, phaseId }) => {
  const projectContext = useProject()
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)
  const [, setSearchParams] = useSearchParams()
  const isUIPartEnabled = useUiPartEnabled(false)

  const { name, startDate, endDate, externalAppName, task } = application
  const assignMember = useAssignMember(application.assignUser)
  const { isCaasAppHavePermission, isCaasHasCurrentWorkspace } = useCheckCaasRequirements(application)
  const { handleOpenApplication } = useOpenApplication({ application })

  const showWarning =
    projectContext && (!isCaasAppHavePermission || !isCaasHasCurrentWorkspace || application.isOutdated)

  const openAppModal = useCallback(
    (id: string) => setSearchParams({ view: 'appDetailsPreview', id, phaseId: phaseId || '' }),
    [phaseId, setSearchParams],
  )

  return (
    <WppTooltip
      text={t('product.cant_launch_info.text')!}
      config={{ trigger: showWarning ? 'mouseenter' : 'manual', hideOnClick: false }}
      className={styles.tooltip}
    >
      <Flex
        direction="row"
        gap={12}
        align="center"
        className={clsx(styles.workflowItemContainer, {
          [styles.applicationCursor]: !showWarning,
          [styles.isDisabled]: isDisabled,
          [styles.inner]: inner,
          [styles.cursor]: isHover,
        })}
        style={{ width: '100%' }}
        onClick={!showWarning && !isDisabled ? handleOpenApplication : void 0}
        data-testid={`application-item-preview-${application.id}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isUIPartEnabled(ProjectPartKey.AppIcon) && (
          <Flex
            inline
            direction="column"
            className={styles.logoWorkflow}
            justify="center"
            align="center"
            data-testid="preview-application-logo"
          >
            <ApplicationLogo logo={application?.logoUrl} />
          </Flex>
        )}
        <Flex direction="column">
          <Truncate lines={1} title={name || externalAppName} type="s-midi" data-testid="preview-application-name">
            {name || externalAppName}
          </Truncate>

          {isUIPartEnabled(ProjectPartKey.StatusBar) &&
            (task?.status === TaskStatus.COMPLETED || task?.status === TaskStatus.ARCHIVED) && (
              <StatusText statusKey={task?.status} data-testid="preview-application-task-status" small />
            )}

          {isUIPartEnabled(ProjectPartKey.Dates) && startDate && endDate && (
            <>
              {!(
                isUIPartEnabled(ProjectPartKey.StatusBar) &&
                (task?.status === TaskStatus.COMPLETED || task?.status === TaskStatus.ARCHIVED)
              ) && <Calendar startDate={startDate} endDate={endDate} small hideIcon data-testid="phase-item-dates" />}
            </>
          )}
        </Flex>
        {/* @TODO: Need to fix this, create new component or change the way how we show this  */}
        <Flex justify="end" className={styles.responsiblePerson} gap={12} align="center">
          {isHover && !templateView ? (
            <WppActionButton
              slot="trigger-element"
              variant="secondary"
              data-testid="application-details-action"
              onClick={e => {
                e.stopPropagation()
                openAppModal(application.id)
              }}
            >
              <WppIconInfo slot="icon-start" />
            </WppActionButton>
          ) : (
            <>
              {isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && assignMember && (
                <ResponsiblePerson assignMember={assignMember} size="xs" hideIcon data-testid="phase-item-assignee" />
              )}
            </>
          )}
        </Flex>
      </Flex>
    </WppTooltip>
  )
}
