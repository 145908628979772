import {
  WppActionButton,
  WppCard,
  WppIconEdit,
  WppTag,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType, NavigationTreeNode } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useHierarchy } from 'hooks/useHierarchy'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { WorkspaceWarning } from 'pages/components/workspaceWarning/WorkspaceWarning'
import { ProjectTypeTag } from 'pages/dashboard/components/projectTypeTag/ProjectTypeTag'
import styles from 'pages/project/components/projectDashboard/ProjectDashboard.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { capitalizeFirstLetter } from 'utils/common'
import { formatDate } from 'utils/dateFormat'
import { findNavigationTreeParentNodeAzId } from 'utils/navigationTree'

const findType = (nodeType: HierarchyLevelType, nodes: NavigationTreeNode[]) =>
  nodes.find(({ type }) => nodeType === type)

const NotDefined = () => (
  <WppTypography type="l-strong" className={styles.notDefine}>
    -
  </WppTypography>
)

export const ProjectInfo = () => {
  const { t } = useTranslation()
  const {
    project,
    tree: { mapping },
    isInactive,
  } = useProject()
  const {
    osContext: {
      userDetails: { dateLocale },
    },
  } = useOs()
  const { hierarchyOrder } = useHierarchy()

  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const { contextWorkspace, description, type, processType, createdAt, startDate, endDate } = project
  const workspaceData = findNavigationTreeParentNodeAzId(contextWorkspace, mapping)

  const isWorkspaceValid = !!contextWorkspace && !!workspaceData.length

  return (
    <WppCard className={styles.detailsCard} size="l" data-testid="project-details-card">
      <WppTypography slot="header" type="l-strong">
        {t('overview.project_details')}
      </WppTypography>
      <Flex direction="column" gap={24}>
        <WorkspaceWarning contextHierarchy={isWorkspaceValid ? [] : project.contextHierarchy} />
        <Flex gap={12}>
          <Flex direction="column" className={styles.tagBlock} data-testid="project-type-details">
            <WppTooltip text={t('overview.project_type')!}>
              <ProjectTypeTag type={type} />
            </WppTooltip>
          </Flex>

          <Flex direction="column" data-testid="project-process-type">
            <WppTooltip text={t('overview.process_type')!}>
              <WppTag label={t(`overview.process_type_${processType}`)!} variant="neutral" />
            </WppTooltip>
          </Flex>
        </Flex>
        {isWorkspaceValid && (
          <Flex gap={80}>
            {hierarchyOrder.map(hierarchy => {
              return (
                <Flex key={hierarchy} direction="column" data-testid={`project-${hierarchy}`}>
                  <WppTypography type="s-midi" className={styles.label}>
                    {capitalizeFirstLetter(hierarchy)}
                  </WppTypography>
                  {findType(hierarchy.toUpperCase() as HierarchyLevelType, workspaceData) ? (
                    <WppTypography type="m-strong">
                      {findType(hierarchy.toUpperCase() as HierarchyLevelType, workspaceData)?.name}
                    </WppTypography>
                  ) : (
                    <NotDefined />
                  )}
                </Flex>
              )
            })}
          </Flex>
        )}
        {!!description && (
          <Flex direction="column" data-testid="project-goals-and-description">
            <WppTypography type="s-midi" className={styles.label}>
              {t('overview.goals_and_description')}
            </WppTypography>
            <WppTypography type="m-body">{description}</WppTypography>
          </Flex>
        )}

        <Flex gap={40}>
          {(startDate || endDate) && (
            <>
              <Flex direction="column" data-testid="project-start-date">
                <WppTypography type="s-midi" className={styles.label}>
                  {t('common.start_date')}
                </WppTypography>

                {startDate ? (
                  <WppTypography type="m-strong">{formatDate(startDate, dateLocale)}</WppTypography>
                ) : (
                  <NotDefined />
                )}
              </Flex>
              <Flex direction="column" data-testid="project-end-date">
                <WppTypography type="s-midi" className={styles.label}>
                  {t('common.end_date')}
                </WppTypography>

                {endDate ? (
                  <WppTypography type="m-strong">{formatDate(endDate, dateLocale)}</WppTypography>
                ) : (
                  <NotDefined />
                )}
              </Flex>
            </>
          )}
          {!startDate && !endDate && (
            <Flex direction="column" data-testid="project-creation-date">
              <WppTypography type="s-midi" className={styles.label}>
                {t('common.created_on')}
              </WppTypography>

              <WppTypography type="m-strong">{formatDate(createdAt, dateLocale, true)}</WppTypography>
            </Flex>
          )}
        </Flex>
      </Flex>
      {isOwnerOrGlobalManage && !isInactive && (
        <div slot="actions">
          <WppActionButton
            slot="trigger-element"
            variant="secondary"
            onClick={() => showProjectEditModal({ project: project })}
          >
            <WppIconEdit slot="icon-start" />
          </WppActionButton>
        </div>
      )}
    </WppCard>
  )
}
