import { CardGroupChangeEventDetail } from '@platform-ui-kit/components-library'
import {
  WppCard,
  WppCardGroup,
  WppTypography,
  WppSkeleton,
  WppSpinner,
} from '@platform-ui-kit/components-library-react'
import { RefCallback, useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useFetchWorkflowTemplatesInfiniteApi } from 'api/templates/queries/useFetchWorkflowTemplatesInfiniteApi'
import { Flex } from 'components/common/flex/Flex'
import { NoTemplateSvg } from 'components/svg/NoTemplateSvg'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import styles from 'pages/components/projectModal/components/projectTemplateForm/ProjectTemplateForm.module.scss'
import { TEMPLATE_PREVIEW_CLASS, TemplateCard } from 'pages/components/templateCard/TemplateCard'
import { showTemplatePreview } from 'pages/components/templatePreviewModal/TemplatePreviewModal'
import { ProcessType } from 'types/projects/projects'

export const ProjectTemplateForm = ({ processType }: { processType: ProcessType }) => {
  const { t } = useTranslation()
  const { setValue, getValues } = useFormContext()

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  const {
    data: templates,
    isLoading: isTemplatesLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchWorkflowTemplatesInfiniteApi({
    params: {
      processType,
    },
  })

  const isFetching = isRefetching || isFetchingNextPage

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage: isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const handleTemplateSelect = useCallback(
    ({ detail }: CustomEvent<CardGroupChangeEventDetail>) => {
      setValue('templateId', detail.value)
    },
    [setValue],
  )

  return (
    <Flex direction="column" className={styles.container} gap={16}>
      <WppCardGroup
        value={getValues('templateId')}
        withRadioOrCheckbox={false}
        onWppChange={handleTemplateSelect}
        data-testid="project-modal-templates"
      >
        <Flex direction="column" gap={16}>
          <WppCard value="">
            <Flex gap={24}>
              <NoTemplateSvg height="75" />
              <Flex direction="column">
                <WppTypography type="l-strong">{t('modals.create_project.no_template.title')}</WppTypography>
                <WppTypography type="s-body">{t('modals.create_project.no_template.text')}</WppTypography>
              </Flex>
            </Flex>
          </WppCard>

          {isTemplatesLoading ? (
            <Flex direction="column" gap={24}>
              <WppSkeleton variant="rectangle" height="200px" width="100% " />
              <WppSkeleton variant="rectangle" height="200" width="100% " />
            </Flex>
          ) : (
            templates.map(template => (
              <div
                key={template.id}
                onClickCapture={event => {
                  // we need this handler due to `WppCardGroup` click implementation: it relies on the `capture` phase
                  if (event.target instanceof Element && event.target.classList.contains(TEMPLATE_PREVIEW_CLASS)) {
                    event.stopPropagation()

                    showTemplatePreview({
                      templateId: template.id,
                      handleSubmit: () => setValue('templateId', template?.id),
                      btnText: t('templates.btn_select')!,
                    })
                    return
                  }
                }}
                data-testid="project-modal-template"
              >
                <TemplateCard template={template} withPreview />
              </div>
            ))
          )}
        </Flex>
      </WppCardGroup>
      <Flex justify="center" ref={setRef} className={styles.nextPageSpinner}>
        {isFetching && <WppSpinner size="m" />}
      </Flex>
    </Flex>
  )
}
