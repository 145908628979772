import { useMemo } from 'react'
import * as zod from 'zod'

import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useProject } from 'hooks/useProject'
import { LinearPreset } from 'pages/project/components/canvas/components/viewSettingsModal/presets'
import { queryClient } from 'providers/osQueryClient/utils'
import { PresetType, ProjectPartKey } from 'types/projects/projectViewSettings'

export const MAX_LINKS_COUNT = 20
export const externalLink = zod.object({
  url: zod
    .string()
    .regex(
      new RegExp(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      ),
      'Please specify a valid URL.',
    )
    .min(1),
  alias: zod.string().optional(),
})

export const activityValidationScheme = zod
  .object({
    name: zod
      .string()
      .min(1, 'Name should contain at least 1 character')
      .max(64, 'Name should have 64 characters at most'),

    description: zod.string().max(150, 'Description should have 150 characters at most').optional(),
  })
  .passthrough()

export const applicationValidationScheme = zod
  .object({
    name: zod.string().max(64, 'Name should have 64 characters at most'),
    description: zod.string().max(150, 'Description should have 150 characters at most').optional(),
  })
  .passthrough()

export const useUiPartEnabled = (isEditable?: boolean) => {
  const projectContext = useProject()

  const config = useMemo(
    () =>
      projectContext && projectContext.viewSettings
        ? projectContext.viewSettings
        : {
            ...LinearPreset[PresetType.DEFAULT],
            preset: PresetType.DEFAULT,
          },
    [projectContext],
  )

  return (key: ProjectPartKey) => {
    // all UI parts are available in the edit mode
    if (isEditable) return true

    return !!config && config[key]
  }
}

export const getFileFromUrl = (url: string) => {
  const regex = /[?&]file=([^&#]*)/i
  const match = url.match(regex)
  if (match && match[1]) {
    return decodeURIComponent(match[1])
  }
}

export const getProjectIdFromUrl = (url: string) => {
  const regex = /\/project\/([^/]+)/
  const match = url.match(regex)
  if (match && match[1]) {
    return match[1]
  }
}

let prevColumnId = 10000
const regColumn: Record<string, number> = {}
export const getColumnDroppableId = (phaseItemId: string): number => {
  if (!regColumn[phaseItemId]) {
    regColumn[phaseItemId] = prevColumnId++
  }
  return regColumn[phaseItemId]
}
export const getColumnIdByDroppable = (droppableId: number): string | undefined => {
  return Object.keys(regColumn).find(key => regColumn[key] === droppableId)
}

let prevActivityId = 1
const regActivity: Record<string, number> = {}
export const getActivityDroppableId = (phaseItemId: string): number => {
  if (!regActivity[phaseItemId]) {
    regActivity[phaseItemId] = prevActivityId++
  }

  return regActivity[phaseItemId]
}

export const getActivityIdByDroppable = (droppableId: number): string | undefined => {
  return Object.keys(regActivity).find(key => regActivity[key] === droppableId)
}

export const invalidateCanvas = async (isMemberChanged?: boolean) => {
  await Promise.all(
    [
      isMemberChanged ? queryClient.invalidateQueries([ApiQueryKeys.MEMBERS]) : null,
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR]),
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID]),
    ].filter(Boolean),
  )
}
