import { fetchWorkflowApiLinear, fetchWorkflowApiFluid } from 'api/canvas/fetchers/fetchWorkflowApi'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ConnectionType } from 'pages/project/components/canvas/components/fluidCanvas/utils'
import { initData } from 'pages/project/components/canvas/utils'
import { ActivityItem, ContainerType, PhaseItemType } from 'types/projects/workflow'

export const useFetchWorkflowApiLinear = createUseQuery({
  queryKey: ApiQueryKeys.PROJECT_WORKFLOW_LINEAR,
  fetcher: fetchWorkflowApiLinear,
  selector: res => {
    return res?.data || initData
  },
})
export const useFetchWorkflowApiFluid = createUseQuery({
  queryKey: ApiQueryKeys.PROJECT_WORKFLOW_FLUID,
  fetcher: fetchWorkflowApiFluid,
  selector: res => {
    let data = res?.data

    if (data) {
      // temporary filter data connections, @see https://jira.uhub.biz/browse/WPPLONOP-15513
      data = {
        ...data,
        connections: data.connections.filter(connection => connection.type !== ConnectionType.DATA),
      }
    }

    if (data) {
      // // TODO tmp hack (I hope so), to fix BE bug - orderNumber is not always starting from 0
      data.items.forEach(item => {
        if (!(item as ActivityItem).items) {
          return
        }

        ;(item as ActivityItem).items
          .sort(({ orderNumber: orderNumberA }, { orderNumber: orderNumberB }) => {
            return orderNumberA === orderNumberB ? 0 : orderNumberA < orderNumberB ? -1 : 1
          })
          .forEach((item, index) => {
            if (item.orderNumber !== index) {
              console.warn(
                `Order number in inner app ${item.id} is not correct, fixing  ${item.orderNumber} => ${index}`,
              )
            }

            item.orderNumber = index
          })
      })

      // TODO tmp hack#2 (I hope so), to fix BE bug - orderNumberInRoot is not correct (e.g. all eq 0)
      //  @see https://jira.uhub.biz/browse/WPPLONOP-15058
      const containersMap = Object.fromEntries(data.containers.map(container => [container.id, container]))
      const itemsMap = Object.fromEntries(data.items.map(item => [item.id, item]))
      data.containers.forEach(container => {
        if (container.itemType === PhaseItemType.Application && container.containerType === ContainerType.NESTED) {
          const rootContainer = containersMap[container.rootContainerId!]
          const activity = itemsMap[rootContainer.itemId] as ActivityItem
          const orderNumberInRoot = activity.items.find(item => item.application.id === container.itemId)?.orderNumber

          // @see https://jira.uhub.biz/browse/WPPLONOP-15051
          if (!Number.isInteger(orderNumberInRoot)) {
            console.warn(`Incorrect response, activity from container  ${container.id} has no item ${container.itemId}`)
            return
          }

          if (container.orderNumberInRoot !== orderNumberInRoot) {
            console.warn(
              `Order number in nested container ${container.id} is not correct, fixing  ${container.orderNumberInRoot} => ${orderNumberInRoot}`,
            )
          }

          container.orderNumberInRoot = orderNumberInRoot
        }
      })

      return data
    }

    return null
  },
})
