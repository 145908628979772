// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D7HlI{width:40px;height:40px;background:var(--wpp-grey-color-200);border-radius:100%}.tc8tf{color:var(--wpp-grey-color-700);text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/members/components/memberModal/MemberModal.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,oCAAA,CACA,kBAAA,CAGF,OACE,+BAAA,CACA,wBAAA","sourcesContent":[".icon {\n  width: 40px;\n  height: 40px;\n  background: var(--wpp-grey-color-200);\n  border-radius: 100%;\n}\n\n.subTitle {\n  color: var(--wpp-grey-color-700);\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `D7HlI`,
	"subTitle": `tc8tf`
};
export default ___CSS_LOADER_EXPORT___;
