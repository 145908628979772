import { projectApi } from 'api'
import { LinearData } from 'pages/project/components/canvas/utils'
import { Workflow } from 'types/projects/workflow'

export interface PatchWorkflowParams {
  id: string

  phases: {
    id: string
    orderNumber: number
  }[]

  // optimistic update data
  optimisticData: Pick<LinearData, 'columnOrder'>
}

export const patchWorkflowApi = ({ id, phases }: PatchWorkflowParams) =>
  projectApi.patch<Workflow>(`/projects/${id}/canvas/linear`, { phases })
