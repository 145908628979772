// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iXKaw{height:max-content;margin-left:20px}.Grs3X{margin-left:0}.JyCFJ{position:relative;min-height:130px}.vtqIn{box-sizing:content-box;padding-bottom:80px}.lTtUQ{width:348px}.lTtUQ.uYTNd{margin-right:20px}.lTtUQ.tqJQK{margin-left:20px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/phase/DragPhase.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,gBAAA,CAGF,OACE,aAAA,CAGF,OACE,iBAAA,CACA,gBAAA,CAGF,OAEE,sBAAA,CACA,mBAAA,CAGF,OACE,WAAA,CAEA,aACE,iBAAA,CAGF,aACE,gBAAA","sourcesContent":[".dragPhaseWrapper {\n  height: max-content;\n  margin-left: 20px;\n}\n\n.isPhaseDragging {\n  margin-left: 0;\n}\n\n.column {\n  position: relative;\n  min-height: 130px; // in order to be able to place tasks into empty phases\n}\n\n.isSomeItemDragging {\n  // to avoid reduce of the column minimum height\n  box-sizing: content-box;\n  padding-bottom: 80px;\n}\n\n.phasePlaceholder {\n  width: 348px; // same as set in `Phase.module.scss`\n\n  &.placeholderLeft {\n    margin-right: 20px;\n  }\n\n  &.placeholderRight {\n    margin-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragPhaseWrapper": `iXKaw`,
	"isPhaseDragging": `Grs3X`,
	"column": `JyCFJ`,
	"isSomeItemDragging": `vtqIn`,
	"phasePlaceholder": `lTtUQ`,
	"placeholderLeft": `uYTNd`,
	"placeholderRight": `tqJQK`
};
export default ___CSS_LOADER_EXPORT___;
