import { useOs } from '@wpp-open/react'
import { useCallback } from 'react'

import { useCheckCaasRequirements } from 'hooks/application/useCheckCaasRequirements'
import { useProject } from 'hooks/useProject'
import { NativeAppType } from 'types/products/nativeApp'
import { ApplicationItem } from 'types/projects/workflow'
import { simulateLinkClick } from 'utils/link'

interface Props {
  application: ApplicationItem
}

export const useOpenApplication = ({ application }: Props) => {
  const {
    osApi: { navigation },
  } = useOs()

  const projectContext = useProject()
  const project = projectContext?.project

  const openApp = navigation.openApp
  const { isCaasAppHavePermission, isCaasHasCurrentWorkspace } = useCheckCaasRequirements(application)

  const handleOpenApplication = useCallback(async () => {
    const { isOutdated, name } = application

    if (!project) {
      console.warn(`Can't run "${name}" application, project is absent.`)
      return
    }

    const canRunApp = isCaasAppHavePermission && isCaasHasCurrentWorkspace && !isOutdated
    if (!canRunApp) {
      console.warn(`Can't run "${name}" application, please check app configuration.`)
      return
    }

    if (application.type === NativeAppType.EXTERNAL_LINK) {
      simulateLinkClick({ href: application.externalAppVersions[0].config.url!, target: '_blank' })
      return
    }

    openApp(application.appInstanceId)
  }, [application, isCaasAppHavePermission, isCaasHasCurrentWorkspace, openApp, project])

  return { handleOpenApplication }
}
