import * as zod from 'zod'

import { ProjectType } from 'types/projects/projects'

const typeEnum = zod.enum([ProjectType.BLANK, ProjectType.PITCH, ProjectType.CAMPAIGN, ProjectType.WORKSHOP])

export const modalValidationScheme = zod
  .object({
    name: zod.string().min(3, 'This field is required and should have at least 3 symbols'),
    type: zod.nativeEnum(typeEnum.enum),
    description: zod.string().max(600, 'Description should have 600 characters at most'),
    brand: zod.string(),
    market: zod.string(),
    client: zod.string().optional(),
    dueDate: zod.date().array().optional(),
  })
  .refine(
    ({ brand, market, client, type }) => (type === ProjectType.BLANK ? true : [brand, market, client].some(Boolean)),
    {
      message: 'Please enter client of your project.',
      path: ['client'],
    },
  )

type FiltersAmount<T> = {
  [K in keyof T]: T[K][] | undefined | string | boolean
}

export const getAppliedFilters = <T>(filters: FiltersAmount<T>): number => {
  return Object.values(filters)
    .filter(value => {
      if (typeof value === 'boolean') {
        return value
      }
      return Array.isArray(value) && value.length > 0
    })
    .flat().length
}
