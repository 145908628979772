import React, { FC, useMemo } from 'react'

import { PreviewActivity } from 'pages/project/components/canvas/components/item/activity/PreviewActivity'
import { PreviewApplication } from 'pages/project/components/canvas/components/item/application/PreviewApplication'
import { ActivityItem, ApplicationItem, PhaseItemType } from 'types/projects/workflow'
import { WorkflowTemplatePhaseItem } from 'types/projects/workflowTemplate'

interface Props {
  phaseItem: WorkflowTemplatePhaseItem
  index: number
  variant?: 'primary' | 'secondary'
}

export const TemplatePhaseItem: FC<Props> = ({ phaseItem }) => {
  if (phaseItem.itemType === PhaseItemType.Application) {
    const item = phaseItem.item as ApplicationItem
    return <PreviewApplication key={phaseItem.id} application={item} isDisabled templateView />
  }

  const item = phaseItem.item as ActivityItem
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sortedExternalLinksByNewest = useMemo(() => {
    return [...(item.externalLinks || [])]?.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
  }, [item?.externalLinks])

  return (
    <PreviewActivity
      key={phaseItem.id}
      activityItem={item}
      isDisabled
      fileAction
      sortedExternalLinks={sortedExternalLinksByNewest}
      templateView
    />
  )
}
