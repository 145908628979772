import { MayBeNull } from '@wpp-open/core'

export interface Task {
  id: string
  itemId: string
  targetType: TaskTargetType
  projectId: string
  name: string
  description: string
  status: TaskStatus
  startDate?: string
  endDate?: string
  assignUser: string
  location: Location
}

export enum TaskTargetType {
  ACTIVITY = 'activity',
  APPLICATION = 'application',
  PHASE = 'phase',
}

export enum TaskStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export interface Location {
  phase: MayBeNull<LocationItem>
  activity: MayBeNull<LocationItem>
  application: MayBeNull<LocationItem>
}

interface LocationItem {
  id: string
  name: string
  assignUser?: MayBeNull<string>
}

export enum TaskDueDate {
  OVERDUE = 'OVERDUE',
  THIS_WEEK = 'THIS_WEEK',
  NEXT_WEEK = 'NEXT_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  NEXT_MONTH = 'NEXT_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  NEXT_YEAR = 'NEXT_YEAR',
}

export interface TasksFilter {
  search?: string
  archived?: boolean
  dueDateRanges?: (keyof typeof TaskDueDate)[]
}
