import { WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'

import { useProjectAppApi } from 'api/projects/queries/useProjectAppApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { Svg404Icon } from 'components/svg/Svg404Icon'
import { t } from 'i18next'
import { AppDetailsModalActions } from 'pages/project/components/canvas/components/item/application/detailsModal/AppDetailsModalActions'
import { AppDetailsModalContent } from 'pages/project/components/canvas/components/item/application/detailsModal/AppDetailsModalContent'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

export const SideModalLoadingSkeleton = () => (
  <Flex className={styles.tasksSkeletonContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
  </Flex>
)

interface Props extends NiceModalWrappedProps {
  appId: string
  phaseId: string
}

const AppDetailsModal = ({ appId, onClose, onCloseComplete, isOpen, phaseId }: Props) => {
  const { data: application, isLoading } = useProjectAppApi({ params: { id: appId } })

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="m"
      data-testid="app-details-modal"
    >
      {isLoading ? (
        <WppSkeleton slot="header" variant="rectangle" height="32px" width="399px" />
      ) : (
        <WppTypography slot="header" type="2xl-heading" data-testid="app-name">
          {application?.name ?? t('modals.app_details_modal.title')}
        </WppTypography>
      )}

      <Flex slot="body" direction="column" gap={12} className={styles.tasksContainer}>
        {isLoading ? (
          <SideModalLoadingSkeleton />
        ) : (
          <>
            {!application ? (
              <Flex justify="center" align="center" className={styles.noResultsContainer} direction="column" gap={24}>
                <Svg404Icon className={styles.image} />
                <Flex justify="center" align="center" direction="column" gap={8}>
                  <WppTypography type="l-strong" className={styles.greyColor1000}>
                    {t('modals.app_details_modal.no_results_title')}
                  </WppTypography>
                  <WppTypography type="xs-body" className={styles.greyColor800}>
                    {t('modals.app_details_modal.no_results_description')}
                  </WppTypography>
                </Flex>
              </Flex>
            ) : (
              <AppDetailsModalContent application={application} />
            )}
          </>
        )}
      </Flex>
      <Flex slot="actions" justify="end" gap={12}>
        {isLoading ? (
          <Flex justify="end">
            <WppSkeleton variant="rectangle" height="40px" width="119px" />
          </Flex>
        ) : (
          <AppDetailsModalActions application={application} onClose={onClose} phaseId={phaseId} />
        )}
      </Flex>
    </SideModal>
  )
}

export const { showModal: showAppDetailsModal, useModal: useShowAppDetailsModal } = createProjectModal<Props>(
  AppDetailsModal,
  'app-details-modal',
)
