import {
  WppTypography,
  WppDivider,
  WppButton,
  WppTag,
  WppIconApp,
  WppIconFolder,
  WppSkeleton,
} from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { NoRecordsSvg } from 'components/svg/NoRecordsSvg'
import { useAssignMember } from 'hooks/useAssignMember'
import { Calendar } from 'pages/project/components/canvas/components/phase/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/phase/ResponsiblePerson'
import { TaskLocation } from 'pages/project/components/tasks/components/taskDetailsModal/TaskLocation'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { Task, TaskTargetType } from 'types/projects/tasks'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

export const taskTargetTypeMap = {
  [TaskTargetType.ACTIVITY]: {
    icon: <WppIconFolder />,
    name: 'project.tasks.item_type.activity',
  },
  [TaskTargetType.APPLICATION]: {
    icon: <WppIconApp />,
    name: 'project.tasks.item_type.application',
  },
  [TaskTargetType.PHASE]: {
    icon: <WppIconFolder />,
    name: 'project.tasks.item_type.phase',
  },
}

export const SideModalLoadingSkeleton = () => (
  <Flex className={styles.tasksContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
  </Flex>
)

interface Props extends NiceModalWrappedProps {
  task: Task | null
  isLoading: boolean
}

export const TaskPreview = ({ task, isLoading, onClose, onCloseComplete, isOpen, id }: Props) => {
  const { t } = useTranslation()

  const assignMember = useAssignMember(task?.assignUser)

  return (
    <SideModal
      id={id}
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="m"
    >
      <WppTypography slot="header" type="2xl-heading">
        {t('project.tasks.details_modal.title')}
      </WppTypography>
      <Flex slot="body" direction="column" gap={24} className={styles.tasksContainer}>
        {isLoading ? (
          <SideModalLoadingSkeleton />
        ) : (
          <>
            {!task ? (
              <Flex justify="center" align="center" className={styles.noResultsContainer} direction="column" gap={8}>
                <NoRecordsSvg className={styles.image} />
                <WppTypography type="m-strong" className={styles.greyColor1000}>
                  {t('project.tasks.details_modal.no_results')}
                </WppTypography>
              </Flex>
            ) : (
              <>
                <Flex direction="column" gap={12}>
                  <WppTypography type="xl-heading">{task.name ?? ''}</WppTypography>

                  <Flex gap={24} align="center">
                    <WppTag variant="neutral" label={t(`project.tasks.status.${task.status}`)!} />

                    <ResponsiblePerson
                      assignMember={assignMember}
                      size="xs"
                      data-testid="task-item-assignee"
                      showName
                    />

                    <Calendar startDate={task?.startDate} endDate={task.endDate} data-testid="task-item-dates" />
                  </Flex>
                </Flex>

                {task.description && (
                  <Flex gap={4} direction="column">
                    <WppTypography type="s-strong" className={styles.greyColor800}>
                      {t('project.tasks.details_modal.description_label')}
                    </WppTypography>
                    <WppTypography type="s-midi" className={styles.greyColor1000}>
                      {task.description}
                    </WppTypography>
                  </Flex>
                )}
                <WppDivider />

                <Flex gap={24} direction="column">
                  <Flex gap={20} align="center">
                    <Flex inline direction="column" className={styles.icon} justify="center" align="center">
                      {taskTargetTypeMap[task.targetType].icon}
                    </Flex>
                    <Flex direction="column">
                      <WppTypography type="xs-midi" className={styles.greyColor800}>
                        {t('project.tasks.details_modal.task_type_label')}
                      </WppTypography>
                      <WppTypography type="s-body">{t(taskTargetTypeMap[task.targetType].name)}</WppTypography>
                    </Flex>
                  </Flex>
                  <TaskLocation location={task.location} />
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>
      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="primary" size="m" onClick={onClose}>
          {t('common.btn_done')}
        </WppButton>
      </Flex>
    </SideModal>
  )
}
