import { MicroAppCustomProps } from '@wpp-open/core'
import { OsProvider } from '@wpp-open/react'

import { App } from 'app/App'
import 'i18n/i18n'
import { ApiProvider } from 'providers/api/ApiProvider'
import { OsQueryClientProvider } from 'providers/osQueryClient'
import { TaxonomyProvider } from 'providers/taxonomy/TaxonomyProvider'

export const Root = (props: MicroAppCustomProps) => {
  return (
    <OsQueryClientProvider>
      <OsProvider {...props}>
        <ApiProvider>
          <TaxonomyProvider>
            <App />
          </TaxonomyProvider>
        </ApiProvider>
      </OsProvider>
    </OsQueryClientProvider>
  )
}
