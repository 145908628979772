import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { useDeleteProjectFileApi } from 'api/projects/mutation/useDeleteProjectFileApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { invalidateCanvas } from 'pages/project/components/canvas/components/item/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  name: string
  fileId: string
  isInFluid?: boolean

  onDelete?: () => void
}

const FileDeleteModal = ({ name, fileId, isInFluid, isOpen, onClose, onCloseComplete, onDelete }: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const [isDeleting, setDeleting] = useState(false)

  const { mutateAsync: deleteFile } = useDeleteProjectFileApi()

  const deleteFileAction = useCallback(async () => {
    setDeleting(true)
    try {
      await deleteFile({ fileId })
      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES_FETCHER])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FILES])
      invalidateCanvas()
      onClose()
      onDelete?.()

      showToast({ type: 'success', message: t('project.files.toast_success_message')! })
    } catch (e) {
      showToast({ type: 'error', message: t('project.files.toast_error_delete_message')! })
    } finally {
      setDeleting(false)
    }
  }, [deleteFile, fileId, onClose, onDelete, showToast, t])

  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete}>
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center" data-testid="delete-group-modal-title">
          {t('project.files.remove_container.delete_file')}
        </Flex>
      </WppTypography>
      <WppTypography slot="body" type="s-body" data-testid="delete-group-modal-text">
        <WppTypography>
          <Trans
            i18nKey={
              isInFluid
                ? 'project.files.remove_container.delete_fluid_confirm'
                : 'project.files.remove_container.delete_confirm'
            }
            values={{ query: name }}
            components={{ bold: <WppTypography type="m-strong" /> }}
          />
        </WppTypography>
      </WppTypography>
      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} size="s" variant="secondary">
          {t('common.btn_cancel')}
        </WppButton>
        <WppButton loading={isDeleting} onClick={deleteFileAction} size="s" variant="destructive">
          {t('common.btn_delete')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showFileDeleteModal } = createNiceModal<Props>(FileDeleteModal, 'delete-file-modal')
