import { WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'components/common/noData/NoData.module.scss'

export const NoData = () => (
  <WppTypography type="l-strong" className={styles.noData}>
    -
  </WppTypography>
)
