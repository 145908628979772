import { WppIconTrash } from '@platform-ui-kit/components-library-react'
import { useCallback, MouseEvent } from 'react'
import { getBezierPath, EdgeProps, BaseEdge, getSmoothStepPath, useStoreApi } from 'reactflow'

import styles from 'pages/project/components/canvas/components/fluidCanvas/components/fluidEdges/CustomEdge.module.scss'
import { NodeType } from 'pages/project/components/canvas/components/fluidCanvas/utils'
import { useDeleteFluidConnection } from 'pages/project/components/canvas/hooks/useDeleteFluidConnection'

const foreignObjectSize = 24

export const CustomEdge = (props: EdgeProps) => {
  const flowStore = useStoreApi()
  const nodes = flowStore.getState().getNodes()
  const nodesMap = Object.fromEntries(nodes.map(node => [node.id, node]))

  const { id, sourceX, targetX, sourcePosition, targetPosition, data, source, target } = props
  let { sourceY, targetY } = props

  if (nodesMap[source].type === NodeType.APP_NODE && nodesMap[source].parentNode) {
    const innerOffset = parseFloat(
      document.body.style.getPropertyValue(`--inner-start-${nodesMap[source].parentNode}`) || '0',
    )
    sourceY += innerOffset
  }

  if (nodesMap[target].type === NodeType.APP_NODE && nodesMap[target].parentNode) {
    const innerOffset = parseFloat(
      document.body.style.getPropertyValue(`--inner-start-${nodesMap[target].parentNode}`) || '0',
    )
    targetY += innerOffset
  }

  const [edgePath, labelX, labelY] = data?.isInnerEdge
    ? getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, offset: 50 })
    : getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      })

  const { deleteConnection } = useDeleteFluidConnection({ projectId: data.projectId })

  const onRemoveEdgeHandle = useCallback(
    async (evt: MouseEvent, id: string) => {
      evt.stopPropagation()
      await deleteConnection(id)
    },
    [deleteConnection],
  )

  return (
    <>
      <BaseEdge path={edgePath} {...props} />
      {!data.isInactive && !data?.preview && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={labelX - foreignObjectSize / 2}
          y={labelY - foreignObjectSize / 2}
          className={styles.edgebuttonForeignObject}
          requiredExtensions="http://www.w3.org/1999/xhtml"
          data-testid="sequence-connection"
        >
          {data?.canEdit && (
            <WppIconTrash
              size="s"
              slot="left"
              className={styles.edgeButton}
              onClick={event => onRemoveEdgeHandle(event, id)}
              data-testid="connection-remove-icon"
            />
          )}
        </foreignObject>
      )}
    </>
  )
}
