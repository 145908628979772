import { WppInlineMessage, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/workspaceWarning/WorkspaceWarning.module.scss'
import { ContextHierarchy } from 'types/projects/projects'

export const WorkspaceWarning = ({
  contextHierarchy,
  withWarning = false,
}: {
  contextHierarchy: ContextHierarchy[]
  withWarning?: boolean
}) => {
  const { t } = useTranslation()

  return (
    !!contextHierarchy?.length && (
      <Flex direction="column" gap={12}>
        {withWarning && (
          <WppInlineMessage
            size="m"
            message={t('project.about.incorrect_workspace')}
            type="warning"
            showTooltipFrom={100}
          />
        )}
        <Flex gap={26}>
          {contextHierarchy.map(el => (
            <Flex direction="column">
              <WppTypography className={styles.title} type="s-midi">
                {el.title}
              </WppTypography>
              <WppTypography className={styles.fontColor} type="m-strong">
                {el.name || '-'}
              </WppTypography>
            </Flex>
          ))}
        </Flex>
      </Flex>
    )
  )
}
