import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/projectSkeleton/components/workflow/WorkflowSkeleton.module.scss'
import { canvasSkeleton, fluidSkeleton } from 'pages/project/components/canvas/Canvas'

export const WorkflowSkeleton = ({ isCanvasLoading }: { isCanvasLoading: boolean }) => {
  return (
    <Flex direction="column" className={styles.container} gap={24}>
      {isCanvasLoading ? canvasSkeleton() : fluidSkeleton()}
    </Flex>
  )
}
