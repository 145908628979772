import { useMemo } from 'react'

import { useAgenciesListApi } from 'api/agencies/queries/useAgenciesListApi'

export const useAgencies = () => {
  const { data: agencies } = useAgenciesListApi({
    params: { itemsPerPage: 1000 },
  })

  const agenciesMap = useMemo(() => Object.fromEntries(agencies.map(agency => [agency.id, agency])), [agencies])

  return { agencies, agenciesMap }
}
