import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  showAppDetailsModal,
  useShowAppDetailsModal,
} from 'pages/project/components/canvas/components/item/application/detailsModal/AppDetailsModal'

export const useOpenAppPreviewModal = () => {
  const { visible, hide } = useShowAppDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, id, phaseId } = Object.fromEntries(params.entries()) as {
      view: string
      id: string
      phaseId: string
    }

    if (view && view === 'appDetailsPreview') {
      showAppDetailsModal({ appId: id, onClose: setParams, phaseId })
    } else visible && hide()
  }, [hide, params, setParams, visible])
}
