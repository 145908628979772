import { MayBeNull, NavigationTree } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { createContext, PropsWithChildren } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { useFetchWorkflowApiFluid, useFetchWorkflowApiLinear } from 'api/canvas/queries/useFetchWorkflowApi'
import { useMembersListApi } from 'api/projects/queries/useMembersListApi'
import { useProjectApi } from 'api/projects/queries/useProjectApi'
import { useProjectViewSettingsApi } from 'api/projects/queries/useProjectViewSettingsApi'
import { ProjectSkeleton } from 'pages/components/projectSkeleton/ProjectSkeleton'
import { LinearData } from 'pages/project/components/canvas/utils'
import { Members } from 'types/members/members'
import { ProcessType, Project, ProjectStatus } from 'types/projects/projects'
import { ProjectViewSettings } from 'types/projects/projectViewSettings'
import { FluidWorkflow } from 'types/projects/workflow'

export const LOAD_ALL = 100_000

interface ProjectProps {
  tree: NavigationTree
  project: Project
  isProjectLoading: boolean
  viewSettings: ProjectViewSettings
  members: Members[]
  isMembersLoading: boolean
  canvas: LinearData
  isCanvasLoading: boolean
  isFluidLoading: boolean
  fluidData: MayBeNull<FluidWorkflow>
  isInactive: boolean
}

export const ProjectContext = createContext<ProjectProps>(null!)

export const ProjectProvider = ({ children }: PropsWithChildren<{}>) => {
  const { projectId } = useParams()

  const { osContext } = useOs()
  const { navigationTree } = osContext

  const { data: project, isLoading: isProjectLoading } = useProjectApi({
    params: { id: projectId! },
    enabled: !!projectId,
  })

  const { data: viewSettings, isLoading: isViewSettingsLoading } = useProjectViewSettingsApi({
    params: projectId!,
    enabled: !!projectId,
  })

  const { data: members, isLoading: isMembersLoading } = useMembersListApi({
    params: { itemsPerPage: LOAD_ALL, id: projectId! },
    enabled: !!projectId,
  })

  const { data: canvas, isLoading: isCanvasLoading } = useFetchWorkflowApiLinear({
    params: { id: projectId! },
    enabled: !!projectId && project?.processType === ProcessType.LINEAR,
  })

  const { data: fluidData, isLoading: isFluidLoading } = useFetchWorkflowApiFluid({
    params: { id: projectId! },
    enabled: !!projectId && project?.processType === ProcessType.FLUID,
  })

  if (isProjectLoading && !project) {
    return <ProjectSkeleton isCanvasLoading={isCanvasLoading} />
  }

  if (isViewSettingsLoading) {
    return <ProjectSkeleton isCanvasLoading={isCanvasLoading} />
  }

  if (!project || !navigationTree) {
    return <Navigate replace to="/404" />
  }

  return (
    <ProjectContext.Provider
      value={{
        tree: navigationTree,
        project,
        isProjectLoading: isProjectLoading,
        viewSettings: viewSettings!,
        members,
        isMembersLoading,
        canvas,
        isCanvasLoading,
        isFluidLoading,
        fluidData,
        isInactive: project.status !== ProjectStatus.ACTIVE,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}
