import { FC, useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { FluidTemplateSvg } from 'components/svg/FluidTemplateSvg'
import styles from 'pages/components/phasesIndicator/PhasesIndicator.module.scss'
import { ProcessType } from 'types/projects/projects'
import { WorkflowTemplate } from 'types/projects/workflowTemplate'

interface Props {
  stats: WorkflowTemplate['phasesStats']
  processType: WorkflowTemplate['processType']
}

export const PhasesIndicator: FC<Props> = ({ stats, processType }: Props) => {
  // Columns height = (number of the apps in the phase / max number of apps inside one phase ) * 100
  const uiConfig: number[] = useMemo(() => {
    const maxAppsCount = Math.max(...stats.map(({ numberOfItems }) => numberOfItems))
    if (!maxAppsCount) {
      return stats.map(() => 100)
    }

    return stats.map(({ numberOfItems }) => (numberOfItems / maxAppsCount) * 100)
  }, [stats])

  return (
    <Flex gap={2} data-testid="template-phases-indicator">
      {processType === ProcessType.FLUID ? (
        <FluidTemplateSvg />
      ) : (
        uiConfig.map((height, idx) => <div key={idx} className={styles.phase} style={{ height: `${height}%` }} />)
      )}
    </Flex>
  )
}
