import { WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo, useRef, useState } from 'react'

import styles from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal.module.scss'
import { NativeAppVersionDTO } from 'types/products/nativeApp'

interface Props {
  appId: string
  options: NativeAppVersionDTO[]
  selected: Record<string, string>
  handleVersions: (version: Record<string, string>) => void
}

export const SELECT_VERSION_CLASS_TRIGGER = 'selectVersionClassTrigger'
export const SELECT_ITEM_CLASS_TRIGGER = 'selectItemClassTrigger'

export const SelectVersion = ({ appId, options, handleVersions, selected }: Props) => {
  const ref = useRef<HTMLWppSelectElement>(null)

  // Sorting function based on custom order
  const sortByCustomOrder = useCallback((a: NativeAppVersionDTO, b: NativeAppVersionDTO) => {
    const order: Record<string, number> = {
      PRODUCTION: 1,
      DEMO: 2,
      DEVELOPMENT: 3,
    }
    return order[a.versionType] - order[b.versionType]
  }, [])

  const sortedOptions = useMemo(() => options.sort(sortByCustomOrder), [options, sortByCustomOrder])
  const [value, setValue] = useState(selected[appId] || sortedOptions[0]?.id)

  return (
    <div
      onClickCapture={event => {
        // we need this handler due to `WppCardGroup` click implementation: it relies on the `capture` phase
        if (event.target instanceof Element && event.target.classList.contains(SELECT_ITEM_CLASS_TRIGGER)) {
          const version = event.target.getAttribute('data-version-value') || ''

          setValue(version)
          handleVersions({ ...selected, [appId]: version || value })

          event.stopPropagation()
          return
        }

        if (event.target instanceof Element && event.target.classList.contains(SELECT_VERSION_CLASS_TRIGGER)) {
          event.stopPropagation()
          ref.current?.click()
        }
      }}
    >
      <WppSelect
        value={value}
        type="text"
        size="s"
        className={clsx(SELECT_VERSION_CLASS_TRIGGER, styles.selectVersionMenu, styles.wrapText)}
        ref={ref}
        placeholder={sortedOptions[0]?.name}
        dropdownConfig={{
          trigger: 'focusin',
          hideOnClick: true,
          onHidden: () => {
            ref.current?.blur()
          },
        }}
      >
        {sortedOptions.map(option => (
          <WppListItem
            key={option.id}
            value={option.id}
            data-testid="context-app-launch"
            className={SELECT_ITEM_CLASS_TRIGGER}
            data-version-value={option.id}
          >
            <span slot="label" className={SELECT_ITEM_CLASS_TRIGGER} data-version-value={option.id}>
              {option.name} {`(${option.versionType.toLowerCase()})`}
            </span>
          </WppListItem>
        ))}
      </WppSelect>
    </div>
  )
}
