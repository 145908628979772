import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Identifier } from 'dnd-core'
import { CSSProperties, useContext, useMemo, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { useAssignMember } from 'hooks/useAssignMember'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/canvas/components/item/activity/DragActivityItem.module.scss'
import { Application } from 'pages/project/components/canvas/components/item/application/Application'
import { showEditAppModal } from 'pages/project/components/canvas/components/item/application/EditAppModal'
import placeholderStyles from 'pages/project/components/canvas/components/placeholder/Placeholder.module.scss'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { useUpdateItem } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { LinearDispatchContext } from 'pages/project/components/canvas/LinearProvider'
import { DnDItem, DragContainerType, DropPosition, getDropPosition } from 'pages/project/components/canvas/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AgencyDTOBrief } from 'types/agencies/agency'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ActivityApplicationItem, PhaseItemType } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

interface Props {
  projectId: string
  activityApplicationItem: ActivityApplicationItem
  index: number
  isIAssignToThisPhase?: boolean
  isIAssignToThisActivity?: boolean
  agencies?: { [k: string]: AgencyDTOBrief }
  isEditable: boolean
  variant?: 'primary' | 'secondary'
  phaseId: string
  isInactive: boolean
  isDraggingDisabled?: boolean
}

export const DragActivityItem = ({
  activityApplicationItem,
  index,
  isIAssignToThisPhase,
  isIAssignToThisActivity,
  isEditable,
  isInactive,
  variant,
  phaseId,
  isDraggingDisabled,
}: Props) => {
  const { isPermitted } = useIsPermitted()
  const { hasRole } = useHasProjectRole()
  const { dropOnInnerApp } = useContext(LinearDispatchContext)
  const { application, id } = activityApplicationItem
  const { project } = useProject()

  const {
    osContext: { userDetails },
  } = useOs()

  const assignMember = useAssignMember(application.assignUser)

  const isOwnerOrGlobal = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const isMeAssignToThisApp = useMemo(
    () => isEqualEmails(userDetails.email, assignMember?.email),
    [userDetails, assignMember],
  )
  const isAssignedToParent = isIAssignToThisPhase || isIAssignToThisActivity

  const dndRestricted = isAssignedToParent ? false : !isOwnerOrGlobal
  const dragDisabled = dndRestricted || isDraggingDisabled

  const showAction =
    isEditable && (isOwnerOrGlobal || isIAssignToThisPhase || isMeAssignToThisApp || isIAssignToThisActivity)

  const isDisabled = isEditable && !showAction
  const { updateItem } = useUpdateItem({
    id: application.id,
    type: PhaseItemType.Application,
    name: application.name,
    projectId: project.id,
  })

  const toggleAssignee = async (newAssignee: ResponsibleUser) => {
    const isDeselecting = assignMember?.id === newAssignee.id
    await updateItem({ assignUser: isDeselecting ? null : newAssignee })
  }

  const ref = useRef<HTMLDivElement>(null)
  const [hoverPosition, setHoverPosition] = useState<DropPosition | null>(null)
  const [placeholderHeight, setPlaceholderHeight] = useState<MayBeNull<number>>(null)

  const [{ handlerId, isOverCurrent }, drop] = useDrop<
    DnDItem,
    void,
    { handlerId: MayBeNull<Identifier>; isOverCurrent: boolean }
  >(
    {
      accept: DragContainerType.Task,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
          isOverCurrent: monitor.isOver({ shallow: true }),
        }
      },
      canDrop(dndItem) {
        return dndItem.type !== PhaseItemType.Activity && !dndRestricted
      },
      hover(dndItem: DnDItem, monitor) {
        if (dndItem.id === activityApplicationItem.id || !monitor.canDrop()) {
          setHoverPosition(null)
          return
        }
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        const offset = monitor.getClientOffset()
        setHoverPosition(hoverBoundingRect && offset ? getDropPosition(hoverBoundingRect, offset) : null)
        setPlaceholderHeight(dndItem.height ?? null)
      },
      drop(dndItem: DnDItem, monitor) {
        const didDrop = monitor.didDrop()
        if (didDrop) return

        if (!ref.current) return

        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        dropOnInnerApp(
          phaseId,
          activityApplicationItem,
          dndItem,
          getDropPosition(hoverBoundingRect, monitor.getClientOffset()!),
        )
      },
    },
    [dropOnInnerApp, phaseId, dndRestricted],
  )

  const [{ isDragging }, drag] = useDrag(
    {
      type: DragContainerType.Task,
      item: (): DnDItem => {
        return {
          index,
          id: activityApplicationItem.id,
          type: PhaseItemType.Application,
          columnId: phaseId,
          height: ref.current?.getBoundingClientRect().height,
        }
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => !isInactive && !dragDisabled,
    },
    [phaseId, dragDisabled, activityApplicationItem.id],
  )

  drag(drop(ref))

  // @TODO: refactore, using twice
  const toggleEditModal = () => {
    showEditAppModal({ application, isDisabled: isInactive })
  }

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={
        {
          '--placeholder-height': placeholderHeight ? `${placeholderHeight}px` : null,
          display: isDragging ? 'none' : null,
        } as CSSProperties
      }
      className={clsx(styles.dropZone, {
        [placeholderStyles.dropPlaceholderTop]: isOverCurrent && hoverPosition === DropPosition.Above,
        [placeholderStyles.dropPlaceholderBottom]: isOverCurrent && hoverPosition === DropPosition.Below,
      })}
      data-testid="drag-app-in-activity-container"
    >
      <Application
        phaseId={phaseId}
        application={application}
        isEditable={isEditable}
        isDisabled={isDisabled}
        showAction={showAction}
        isInactive={isInactive}
        index={index}
        editApp={toggleEditModal}
        changeAssignee={toggleAssignee}
        changeDates={dates => updateItem({ dates })}
        variant={variant}
        activityId={id}
        isActivityApplication
        changeStatus={status => updateItem({ status })}
      />
    </div>
  )
}
