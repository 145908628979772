import { PieArcDatum, scaleOrdinal } from 'd3'

import { TaskStatus } from 'types/projects/tasks'

export interface PieData {
  name: string
  totalCount: number
  values: ArcValue[]
}

export interface ArcValue {
  type: string
  value: number
}

export const STATUSES_ORDER: Record<Exclude<TaskStatus, TaskStatus.ARCHIVED>, number> = {
  [TaskStatus.COMPLETED]: 1,
  [TaskStatus.IN_PROGRESS]: 2,
  [TaskStatus.TO_DO]: 3,
}

export const AVAILABLE_STATUSES: Exclude<TaskStatus, TaskStatus.ARCHIVED>[] = [
  TaskStatus.TO_DO,
  TaskStatus.IN_PROGRESS,
  TaskStatus.COMPLETED,
]

export const STATUS_MAP: Record<Exclude<TaskStatus, TaskStatus.ARCHIVED>, string> = {
  [TaskStatus.TO_DO]: 'To do',
  [TaskStatus.IN_PROGRESS]: 'In progress',
  [TaskStatus.COMPLETED]: 'Completed',
}

export const calcTranslate = (data: PieArcDatum<ArcValue>, move = 4) => {
  const moveAngle = data.startAngle + (data.endAngle - data.startAngle) / 2
  return `translate(${-move * Math.cos(moveAngle + Math.PI / 2)}, ${-move * Math.sin(moveAngle + Math.PI / 2)})`
}

export const colorScale = scaleOrdinal()
  .domain(AVAILABLE_STATUSES)
  .range([
    'var(--wpp-grey-color-400)',
    'var(--wpp-dataviz-color-seq-warning-300)',
    'var(--wpp-dataviz-color-seq-positive-500)',
  ])
