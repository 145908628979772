// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{background-color:var(--wpp-grey-color-100)}.jA4Vq{height:calc(100vh - var(--wpp-os-header-height));overflow:hidden}.zYcYi{width:100%;max-width:1920px;margin:0 auto;height:calc(100vh - var(--wpp-os-header-height));padding-left:270px}.szh8Z{--wpp-nav-sidebar-width: 270px;--wpp-nav-sidebar-title-margin: 0;--wpp-nav-sidebar-item-nested-label-padding: "0 0 0 4px"}.szh8Z::part(nav-sidebar){top:unset;bottom:unset}.IOtei{height:100%;overflow-y:auto}.iszDQ::part(label){color:var(--wpp-grey-color-800);font-weight:400}.LJZnr{color:var(--wpp-grey-color-600)}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss","webpack://./src/pages/projectDemo/ProjectDemo.module.scss"],"names":[],"mappings":"AAAA,MACE,0CAAA,CAGF,OACE,gDAAA,CACA,eAAA,CCJF,ODQE,UAAA,CACA,gBAAA,CACA,aAAA,CCPA,gDAAA,CACA,kBAAA,CAGF,OACE,8BAAA,CACA,iCAAA,CACA,wDAAA,CAEA,0BACE,SAAA,CACA,YAAA,CAIJ,OACE,WAAA,CACA,eAAA,CAIA,oBACE,+BAAA,CACA,eAAA,CAIJ,OACE,+BAAA","sourcesContent":[":root {\n  background-color: var(--wpp-grey-color-100);\n}\n\n.heightContainer {\n  height: calc(100vh - var(--wpp-os-header-height));\n  overflow: hidden;\n}\n\n@mixin containerWidthBoundaries {\n  width: 100%;\n  max-width: 1920px;\n  margin: 0 auto;\n}\n","@use 'src/app/App.module' as strictContainer;\n\n.container {\n  @include strictContainer.containerWidthBoundaries;\n\n  height: calc(100vh - var(--wpp-os-header-height));\n  padding-left: 270px;\n}\n\n.sidebar {\n  --wpp-nav-sidebar-width: 270px;\n  --wpp-nav-sidebar-title-margin: 0;\n  --wpp-nav-sidebar-item-nested-label-padding: '0 0 0 4px';\n\n  &::part(nav-sidebar) {\n    top: unset;\n    bottom: unset;\n  }\n}\n\n.infoSection {\n  height: 100%;\n  overflow-y: auto;\n}\n\n.grey800 {\n  &::part(label) {\n    color: var(--wpp-grey-color-800);\n    font-weight: 400;\n  }\n}\n\n.icon {\n  color: var(--wpp-grey-color-600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heightContainer": `jA4Vq`,
	"container": `zYcYi`,
	"sidebar": `szh8Z`,
	"infoSection": `IOtei`,
	"grey800": `iszDQ`,
	"icon": `LJZnr`
};
export default ___CSS_LOADER_EXPORT___;
