import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import styles from 'pages/project/components/tasks/components/statusText/StatusText.module.scss'
import { TaskStatus } from 'types/projects/tasks'

interface Props {
  statusKey: TaskStatus
  small?: boolean
  className?: string
}

export const StatusText = ({ statusKey, small, className }: Props) => {
  const { t } = useTranslation()

  return (
    <WppTypography
      type={small ? 'xs-body' : 's-body'}
      className={clsx(styles[statusKey], className)}
      data-testid="task-status"
    >
      {t(`project.tasks.status.${statusKey}`)}
    </WppTypography>
  )
}
