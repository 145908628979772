import {
  WppActionButton,
  WppButton,
  WppIconApp,
  WppIconPair,
  WppIconPin,
  WppIconPlus,
  WppListItem,
  WppMenuContext,
  WppSkeleton,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useContext, useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ReactFlowProvider } from 'reactflow'

import { useCreatePhaseApi } from 'api/canvas/mutation/useCreatePhaseApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/canvas/Canvas.module.scss'
import { showAppPickerModal } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import FluidCanvas from 'pages/project/components/canvas/components/fluidCanvas/FluidCanvas'
import { showAddEditActivityModal } from 'pages/project/components/canvas/components/item/activity/AddEditActivityModal'
import { invalidateCanvas } from 'pages/project/components/canvas/components/item/utils'
import { LockOverlay } from 'pages/project/components/canvas/components/lockOverlay/LockOverlay'
import { DragPhase } from 'pages/project/components/canvas/components/phase/DragPhase'
import { showSaveAsTemplateModal } from 'pages/project/components/canvas/components/saveAsTemplateModal/SaveAsTemplateModal'
import { useCanvasLock } from 'pages/project/components/canvas/hooks/useCanvasLock'
import { LinearProvider, LinearValueContext } from 'pages/project/components/canvas/LinearProvider'
import { useAgencies } from 'pages/project/hooks/useAgencies'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { queryClient } from 'providers/osQueryClient/utils'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { PhaseItem } from 'types/projects/workflow'

export const canvasSkeleton = () => (
  <Flex direction="row" gap={24} className={styles.canvasContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="350px" />
    <WppSkeleton variant="rectangle" height="100%" width="350px" />
    <WppSkeleton variant="rectangle" height="100%" width="350px" />
    <WppSkeleton variant="rectangle" height="100%" width="350px" />
    <WppSkeleton variant="rectangle" height="100%" width="350px" />
  </Flex>
)

export const fluidSkeleton = () => (
  <Flex className={styles.canvasContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="100%" />
  </Flex>
)

const actionsSkeleton = () => (
  <Flex direction="row" gap={24} className={styles.actionsContainer}>
    <WppSkeleton variant="rectangle" height="32px" width="300px" />
  </Flex>
)

const Canvas = () => {
  const { project, canvas, isCanvasLoading, fluidData, isFluidLoading, isInactive } = useProject()
  const { canvasData } = useContext(LinearValueContext)
  const { t } = useTranslation()

  const { showToast } = useToast()

  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const canCreateTemplate = isPermitted(AppPermissions.ORCHESTRATION_WORKFLOW_TEMPLATE_CREATE)

  const [phasesAmount, setPhasesAmount] = useState(0)
  const lastPhaseId = canvasData.columnOrder[canvasData.columnOrder.length - 1]
  const { isLocked, isLockLoading } = useCanvasLock(project.id)

  const isLockedPrevious = usePrevious(isLocked)

  useEffect(() => {
    if (isLockedPrevious === true && isLocked === false) {
      invalidateCanvas()
    }
  }, [isLocked, isLockedPrevious])

  const openAppPickerModal = async () => {
    showAppPickerModal({
      phaseId: lastPhaseId,
      projectId: project.id,
      selectedCanvas: project.processType,
    })
  }
  const { agenciesMap } = useAgencies()

  useMemo(() => {
    if (project.processType === ProcessType.LINEAR) {
      setPhasesAmount(canvas?.columnOrder?.length ?? 0)
    } else {
      setPhasesAmount(fluidData?.containers?.length ?? 0)
    }
  }, [canvas?.columnOrder?.length, fluidData?.containers?.length, project.processType])

  const { mutateAsync: handleCreatePhase, isLoading: creatPhaseLoading } = useCreatePhaseApi()

  const saveAsTemplate = () => {
    showSaveAsTemplateModal()
  }

  const createNewPhase = async () => {
    try {
      await handleCreatePhase({
        projectId: project.id,
        name: 'New Phase',
      })

      showToast({ type: 'success', message: t('project.canvas.toast.add_a_phase') })
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
    } catch (e) {
      showToast({ type: 'error', message: t('project.canvas.toast.failed_operation_create', { query: 'phase' }) })

      console.error(e)
    }
  }

  const isItemsDraggingDisabled = useMemo(() => {
    if (canvasData.columnOrder.length > 1) return false
    const phase = canvasData.columns[canvasData.columnOrder[0]]
    return phase?.taskIds.length < 2
  }, [canvasData.columnOrder, canvasData.columns])

  const addActivityModal = () => {
    showAddEditActivityModal({
      projectId: project.id,
      phaseId: lastPhaseId,
      selectedCanvas: ProcessType.FLUID,
    })
  }

  const isLoading = isCanvasLoading || isFluidLoading || isLockLoading
  const isSaveTemplateDisabled =
    isLoading ||
    isInactive ||
    (project.processType === ProcessType.FLUID && !fluidData?.items?.length) ||
    (project.processType === ProcessType.LINEAR && !phasesAmount)

  return (
    <>
      <Flex
        direction="column"
        className={project.processType === ProcessType.LINEAR ? styles.bodyLinear : styles.bodyFluid}
      >
        <Flex justify="end" className={clsx({ [styles.linearActionsRow]: project.processType === ProcessType.LINEAR })}>
          {isLoading ? (
            actionsSkeleton()
          ) : (
            <>
              {isOwnerOrGlobalManage && (
                <Flex gap={12}>
                  {canCreateTemplate && (
                    <WppActionButton
                      onClick={saveAsTemplate}
                      disabled={isSaveTemplateDisabled}
                      hidden={project.processType === ProcessType.LINEAR && !phasesAmount}
                      data-testid="save-as-template-button"
                    >
                      <WppIconPin slot="icon-start" />
                      {t('project.canvas.btn_save_as_template')}
                    </WppActionButton>
                  )}

                  {project.processType === ProcessType.LINEAR ? (
                    <>
                      {phasesAmount < 20 && !!phasesAmount && (
                        <WppButton
                          size="s"
                          onClick={createNewPhase}
                          disabled={isCanvasLoading || isInactive}
                          loading={creatPhaseLoading}
                          data-testid="add-phase-button"
                        >
                          <WppIconPlus slot="icon-start" />
                          {t('project.canvas.btn_add_phase')}
                        </WppButton>
                      )}
                    </>
                  ) : (
                    <WppMenuContext data-testid="context-add" className={styles.fluidCtxMenu}>
                      <WppButton
                        size="s"
                        slot="trigger-element"
                        disabled={isCanvasLoading || isInactive}
                        loading={creatPhaseLoading}
                        data-testid="add-item-button"
                      >
                        <WppIconPlus slot="icon-start" />
                        {t('project.canvas.btn_add_item')}
                      </WppButton>
                      <div>
                        <WppListItem onWppChangeListItem={() => openAppPickerModal()} data-testid="context-add-app">
                          <WppIconApp slot="left" />
                          <p slot="label">{t('project.canvas.application')}</p>
                        </WppListItem>
                        <WppListItem onWppChangeListItem={addActivityModal} data-testid="context-add-activity">
                          <WppIconPair slot="left" />
                          <p slot="label">{t('project.canvas.activity')}</p>
                        </WppListItem>
                      </div>
                    </WppMenuContext>
                  )}
                </Flex>
              )}
            </>
          )}
        </Flex>
        {isLoading ? (
          isCanvasLoading ? (
            <div className={styles.canvasLinearWrapper}>{canvasSkeleton()}</div>
          ) : (
            fluidSkeleton()
          )
        ) : (
          <>
            {isLocked && <LockOverlay />}
            {project.processType === ProcessType.LINEAR ? (
              <div className={styles.canvasLinearWrapper}>
                <DndProvider backend={HTML5Backend}>
                  <Flex className={styles.canvasContainer}>
                    {!phasesAmount && (
                      <EmptyState
                        title={t('project.canvas.empty_canvas_title')}
                        description={t('project.canvas.empty_canvas_description')}
                        testToken="app-data"
                      >
                        <WppButton
                          size="m"
                          onClick={createNewPhase}
                          disabled={isCanvasLoading || isInactive}
                          loading={creatPhaseLoading}
                          data-testid="add-phase-button"
                        >
                          <WppIconPlus slot="icon-start" />
                          {t('project.canvas.btn_add_phase')}
                        </WppButton>
                      </EmptyState>
                    )}
                    {canvasData.columnOrder.map((order, index) => {
                      const column = canvasData.columns[order]
                      const tasks = canvasData.columns[order].taskIds.map(id => canvasData.tasks[id]) as PhaseItem[]

                      return (
                        <DragPhase
                          key={column.id + index}
                          column={column}
                          tasks={tasks}
                          index={index}
                          agencies={agenciesMap}
                          projectId={project.id}
                          selectedCanvas={project.processType}
                          isDraggingDisabled={canvasData.columnOrder.length === 1 || isInactive}
                          isItemsDraggingDisabled={isItemsDraggingDisabled || isInactive}
                          isInactive={isInactive}
                        />
                      )
                    })}
                  </Flex>
                </DndProvider>
              </div>
            ) : (
              <>
                {fluidData && (
                  <ReactFlowProvider>
                    <FluidCanvas projectId={project.id} fluidData={fluidData} isInactive={isInactive} />
                  </ReactFlowProvider>
                )}
              </>
            )}
          </>
        )}
      </Flex>
    </>
  )
}

export const CanvasTab = () => (
  <LinearProvider>
    <Canvas />
  </LinearProvider>
)
