import { WppCard, WppTypography, WppAccordion } from '@platform-ui-kit/components-library-react'
import { FC } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAssignMember } from 'hooks/useAssignMember'
import { AttachedFile } from 'pages/project/components/canvas/components/attachedFile/AttachedFile'
import styles from 'pages/project/components/canvas/components/item/activity/AppActivityItem.module.scss'
import { PreviewApplication } from 'pages/project/components/canvas/components/item/application/PreviewApplication'
import { ExternalLinksPreviewList } from 'pages/project/components/canvas/components/item/ExternalLinksPreviewList'
import { useUiPartEnabled } from 'pages/project/components/canvas/components/item/utils'
import { Calendar } from 'pages/project/components/canvas/components/phase/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/phase/ResponsiblePerson'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import { useReadWriteStorageFlag } from 'pages/project/components/canvas/hooks/useSaveFlagToStorage'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem, ExternalLink } from 'types/projects/workflow'

interface Props {
  activityItem: ActivityItem
  isDisabled: boolean
  phaseId?: string
  sortedExternalLinks?: ExternalLink[]
  fileAction?: boolean
  templateView?: boolean
}

export const PreviewActivity: FC<Props> = ({
  activityItem,
  isDisabled,
  phaseId,
  sortedExternalLinks,
  fileAction,
  templateView,
}) => {
  const { state, toggleState } = useReadWriteStorageFlag(activityItem.id, !templateView)

  const isUIPartEnabled = useUiPartEnabled(false)

  const { name, startDate, endDate, task, files, description } = activityItem
  const assignMember = useAssignMember(activityItem.assignUser)

  const showTask = isUIPartEnabled(ProjectPartKey.StatusBar) && task?.status === TaskStatus.COMPLETED
  const showCalendar = isUIPartEnabled(ProjectPartKey.Dates) && startDate && endDate
  const subBur = showTask ? (
    <StatusText statusKey={task?.status} data-testid="preview-activity-task-status" small />
  ) : showCalendar ? (
    <Calendar startDate={startDate} endDate={endDate} small hideIcon data-testid="canvas-activity-dates" />
  ) : null

  return (
    <WppCard
      className={styles.activityExpandableContainer}
      variant="secondary"
      size="s"
      data-testid="activity-item-preview"
    >
      <WppAccordion
        expanded={state}
        withDivider={false}
        className={styles.activityAccordion}
        size="s"
        onWppChange={toggleState}
        data-testid="activity-accordion"
      >
        <Flex slot="header" justify="between" align="center" className={styles.activityHeader} gap={8}>
          <Flex direction="column">
            <Truncate lines={2} type="s-midi" data-testid="activity-name">
              {name}
            </Truncate>

            {subBur}
          </Flex>

          {isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && assignMember && (
            <ResponsiblePerson assignMember={assignMember} size="xs" hideIcon data-testid="phase-item-assignee" />
          )}
        </Flex>

        <Flex direction="column" gap={8} className={styles.activityContentWrapper}>
          {isUIPartEnabled(ProjectPartKey.Descriptions) && description && (
            <WppTypography className={styles.greyColor800} title={description} type="xs-body" data-testid="description">
              {description}
            </WppTypography>
          )}

          {activityItem.items?.length > 0 && (
            <Flex direction="column" data-id="activity-droppable-container" gap={8}>
              {activityItem.items.map((t, i) => (
                <PreviewApplication
                  application={t.application}
                  isDisabled={isDisabled}
                  phaseId={phaseId}
                  key={i}
                  inner
                  templateView={templateView}
                />
              ))}
            </Flex>
          )}

          {isUIPartEnabled(ProjectPartKey.ActivityFilesAndLinks) && (
            <>
              {!!files.length && (
                <Flex direction="column" data-testid="activity-files">
                  <ShowMoreItems maxToShow={2}>
                    {files.map(file => (
                      <AttachedFile
                        preview
                        variant="secondary"
                        file={file}
                        key={file.id}
                        activityId={activityItem.id}
                        isDisabled={isDisabled}
                        fileAction={fileAction}
                      />
                    ))}
                  </ShowMoreItems>
                </Flex>
              )}

              {!!sortedExternalLinks?.length && (
                <Flex direction="column" className={styles.externalLinksContainer}>
                  <ExternalLinksPreviewList
                    activityId={activityItem.id}
                    links={sortedExternalLinks}
                    isDisabled={isDisabled}
                    variant="secondary"
                  />
                </Flex>
              )}
            </>
          )}
        </Flex>
      </WppAccordion>
    </WppCard>
  )
}
