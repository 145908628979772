// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g2nF6{margin-left:auto;opacity:0}.w6Vvp{--card-padding-m: 16px;height:100%}.w6Vvp:hover .g2nF6{opacity:1}.zjhwx{height:100%}.nfmUm{--wpp-typography-color: var(--wpp-grey-color-800);margin-bottom:12px}.um2NK{margin:12px 0 24px;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow:hidden;text-overflow:ellipsis}.WvyhI{margin-top:auto}.xvd2V{margin-top:12px}`, "",{"version":3,"sources":["webpack://./src/pages/components/templateCard/TemplateCard.module.scss","webpack://./src/pages/project/components/canvas/utils.scss"],"names":[],"mappings":"AAEA,OACE,gBAAA,CAEA,SAAA,CAGF,OACE,sBAAA,CAEA,WAAA,CAGE,oBACE,SAAA,CAKN,OACE,WAAA,CAGF,OACE,iDAAA,CAEA,kBAAA,CAGF,OACE,kBAAA,CCnBA,mBAAA,CACA,2BAAA,CACA,oBAH+B,CAI/B,eAAA,CACA,sBAAA,CDoBF,OACE,eAAA,CAGF,OACE,eAAA","sourcesContent":["@import '~pages/project/components/canvas/utils';\n\n.btnPreview {\n  margin-left: auto;\n  // take into account the `.templateCard:hover` implementation\n  opacity: 0;\n}\n\n.templateCard {\n  --card-padding-m: 16px;\n\n  height: 100%;\n\n  &:hover {\n    .btnPreview {\n      opacity: 1;\n    }\n  }\n}\n\n.cardBody {\n  height: 100%;\n}\n\n.phaseInfo {\n  --wpp-typography-color: var(--wpp-grey-color-800);\n\n  margin-bottom: 12px;\n}\n\n.description {\n  margin: 12px 0 24px;\n\n  @include multilineEllipsis();\n}\n\n.divider {\n  margin-top: auto;\n}\n\n.footer {\n  margin-top: 12px;\n}\n","@mixin activeInlineButton() {\n  padding: 6px;\n  border-radius: 6px;\n  cursor: pointer;\n\n  &:focus-within,\n  &:hover {\n    background: var(--wpp-grey-color-300);\n  }\n}\n\n@mixin multilineEllipsis($lines: 2) {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: $lines;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnPreview": `g2nF6`,
	"templateCard": `w6Vvp`,
	"cardBody": `zjhwx`,
	"phaseInfo": `nfmUm`,
	"description": `um2NK`,
	"divider": `WvyhI`,
	"footer": `xvd2V`
};
export default ___CSS_LOADER_EXPORT___;
