import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { LockStatus } from 'api/canvas/fetchers/canvasLockApi'
import { useCanvasLockApi } from 'api/canvas/queries/useCanvasLockApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { useConditionalIdle } from 'pages/project/components/canvas/hooks/useConditionalIdle'
import { queryClient } from 'providers/osQueryClient/utils'
import { routesManager } from 'utils/routesManager'
import { useRemoveAllModals } from 'utils/useRemoveAllModals'

const UPDATE_INTERVAL = 30e3
const INACTIVE_INTERVAL = 60e3

export const useCanvasLock = (projectId: string) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const navigate = useNavigate()
  const { removeAllModals } = useRemoveAllModals()

  const { data: lockData, isLoading: isLockLoading } = useCanvasLockApi({
    params: { id: projectId },
    refetchInterval: UPDATE_INTERVAL,
    keepPreviousData: true,
  })
  const isLocked = lockData?.status === LockStatus.Locked
  const isIdle = useConditionalIdle(!isLocked, INACTIVE_INTERVAL)

  useEffect(() => {
    return () => {
      // we need loading flag on component mount w/o stale data
      queryClient.removeQueries([ApiQueryKeys.PROJECT_CANVAS_LOCK])
    }
  }, [projectId])

  useEffect(() => {
    if (isIdle) {
      showToast({
        type: 'success',
        message: t('project.canvas.toast.inactivity_redirect'),
      })

      removeAllModals()
      navigate(routesManager.project.workflow.getURL({ id: projectId }))
    }
  }, [isIdle, isLocked, navigate, projectId, removeAllModals, showToast, t])

  return { isLockLoading, isLocked, lockedBy: lockData?.data.email }
}
