import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteFluidConnectionApi } from 'api/canvas/mutation/useDeleteFluidConnectionApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { queryClient } from 'providers/osQueryClient/utils'
import { FluidWorkflow } from 'types/projects/workflow'

export function useDeleteFluidConnection({ projectId }: { projectId: string }) {
  const { showToast } = useToast()
  const { t } = useTranslation()

  const { mutateAsync: handleDeleteConnection, isLoading } = useDeleteFluidConnectionApi({
    onMutate: async removedConnection => {
      const projectFluidQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }]
      await queryClient.cancelQueries(projectFluidQueryKey)

      const previousFluid = queryClient.getQueryData<{ data: FluidWorkflow }>(projectFluidQueryKey)

      if (previousFluid) {
        const updatedData = {
          ...previousFluid.data,
          connections: previousFluid.data.connections.filter(
            connection => connection.id !== removedConnection.connectionId,
          ),
        }

        const newData = { ...previousFluid, data: updatedData }

        queryClient.setQueryData(projectFluidQueryKey, newData)
      }

      return { previousFluid }
    },
    onError: (_error, _, context) => {
      if (context?.previousFluid) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }], context.previousFluid)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])
    },
  })

  const deleteConnection = useCallback(
    async (id: string) => {
      try {
        await handleDeleteConnection({ connectionId: id })
      } catch (error) {
        showToast({
          type: 'error',
          message: t('common.generic_error'),
        })
      }
    },
    [handleDeleteConnection, showToast, t],
  )

  return { deleteConnection, isLoading }
}
