import { Flex } from 'components/common/flex/Flex'
import { styleConfig } from 'pages/dashboard/components/projectsCardView/components/projectStatus/projectStatus.config'
import { ProjectStatus as Status } from 'types/projects/projects'

export const ProjectStatusIcon = ({ status, className }: { status: keyof typeof Status; className?: string }) => {
  return status !== Status.ACTIVE ? (
    <Flex inline className={className}>
      {styleConfig[status].icon}
    </Flex>
  ) : null
}
