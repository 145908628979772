import styles from 'components/svg/common.module.scss'

export const EmptyPieChartSvg = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Illustrations">
      <circle id="Ellipse 2149" cx="60" cy="60" r="60" className={styles.stopColorPrimary100} />
      <path
        id="Star 2"
        d="M106.984 44.7717C107.317 43.5377 109.144 43.5377 109.476 44.7717L109.651 45.4184C109.771 45.8663 110.143 46.2119 110.614 46.3136L110.977 46.392C112.315 46.6815 112.315 48.5109 110.977 48.8004L110.614 48.8788C110.143 48.9805 109.771 49.3261 109.651 49.774L109.476 50.4207C109.144 51.6547 107.317 51.6547 106.984 50.4207L106.81 49.774C106.689 49.3261 106.318 48.9805 105.847 48.8788L105.484 48.8004C104.146 48.5109 104.146 46.6815 105.484 46.392L105.847 46.3136C106.318 46.2119 106.689 45.8663 106.81 45.4184L106.984 44.7717Z"
        className={styles.fillPrimary400}
      />
      <ellipse id="Ellipse 2155" cx="16.8436" cy="68.2501" rx="2.99985" ry="3" className={styles.fillPrimary200} />
      <ellipse id="Ellipse 2156" cx="13.0937" cy="59.25" rx="1.49993" ry="1.5" className={styles.fillPrimary200} />
      <ellipse id="Ellipse 2157" cx="59.9999" cy="110.25" rx="2.24989" ry="2.25" className={styles.fillPrimary200} />
      <path
        id="Star 4"
        d="M64.2459 8.3676C63.9134 7.13365 62.0864 7.13365 61.7538 8.3676L61.5795 9.0143C61.4588 9.46223 61.0871 9.8078 60.6165 9.90955L60.2537 9.98798C58.9151 10.2774 58.9151 12.1069 60.2537 12.3963L60.6165 12.4747C61.0871 12.5765 61.4588 12.9221 61.5795 13.37L61.7538 14.0167C62.0864 15.2506 63.9134 15.2506 64.2459 14.0167L64.4202 13.37C64.5409 12.9221 64.9127 12.5765 65.3833 12.4747L65.746 12.3963C67.0847 12.1069 67.0847 10.2774 65.746 9.98798L65.3833 9.90955C64.9127 9.8078 64.5409 9.46223 64.4202 9.0143L64.2459 8.3676Z"
        className={styles.stopColorPrimary300}
      />
      <circle
        id="Ellipse 2157_2"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="matrix(-1 0 0 1 56.25 15.2307)"
        className={styles.fillPrimary400}
      />
      <g id="Group 26839">
        <g id="Paper">
          <rect id="Rectangle 5677" x="27.75" y="40.5" width="51" height="60" rx="6" fill="#CAB8FF" />
          <g id="Rectangle 5676" filter="url(#filter0_d_55699_4985)">
            <path
              d="M49.5 85.5H88.5C91.8137 85.5 94.5 82.8137 94.5 79.5V37.5L82.5 25.5H49.5C46.1863 25.5 43.5 28.1863 43.5 31.5V79.5C43.5 82.8137 46.1863 85.5 49.5 85.5Z"
              fill="white"
            />
          </g>
          <path id="Rectangle 5678" d="M82.5 37.5H94.5L82.5 25.5V37.5Z" fill="#CAB8FF" />
        </g>
        <g id="Lens">
          <g id="Vector" filter="url(#filter1_b_55699_4985)">
            <path
              d="M52.2874 77.5748C67.0817 77.5748 79.0748 65.5817 79.0748 50.7874C79.0748 35.9931 67.0817 24 52.2874 24C37.4931 24 25.5 35.9931 25.5 50.7874C25.5 65.5817 37.4931 77.5748 52.2874 77.5748Z"
              fill="white"
              fillOpacity="0.4"
            />
          </g>
          <g id="Vector_2">
            <path
              d="M62.57 43.752L59.3228 40.5048L52.2874 47.5403L45.252 40.5048L42.0048 43.752L49.0403 50.7874L42.0048 57.8228L45.252 61.07L52.2874 54.0345L59.3228 61.07L62.57 57.8228L55.5345 50.7874L62.57 43.752Z"
              fill="#5E00B5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M68.3312 72.2431C57.8389 80.1094 42.8883 79.2714 33.3458 69.729C22.8847 59.2678 22.8847 42.307 33.3458 31.8458C43.807 21.3847 60.7678 21.3847 71.229 31.8458C80.7714 41.3883 81.6094 56.3389 73.7431 66.8312L94.5 87.5881L89.0881 93L68.3312 72.2431ZM67.9818 66.4818C59.314 75.1496 45.2608 75.1496 36.593 66.4818C27.9252 57.814 27.9252 43.7608 36.593 35.093C45.2608 26.4252 59.314 26.4252 67.9818 35.093C76.6496 43.7608 76.6496 57.814 67.9818 66.4818Z"
              fill="#5E00B5"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_55699_4985"
        x="31.5"
        y="17.5"
        width="75"
        height="84"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.709804 0 0 0 0.302 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_55699_4985" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_55699_4985" result="shape" />
      </filter>
      <filter
        id="filter1_b_55699_4985"
        x="10.1667"
        y="8.66667"
        width="84.2409"
        height="84.2415"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.66667" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_55699_4985" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_55699_4985" result="shape" />
      </filter>
    </defs>
  </svg>
)
