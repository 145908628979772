import { WppDivider, WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'

export const FileModalSkeleton = () => (
  <>
    <Flex direction="column" slot="body" gap={12}>
      <WppSkeleton variant="rectangle" height="35px" width="320px" />

      <WppSkeleton variant="rectangle" height="24px" width="150px" />
      <WppSkeleton variant="rectangle" height="24px" width="150px" />
    </Flex>
    <WppDivider />
    <WppSkeleton variant="rectangle" height="45px" width="100%" />
  </>
)

export const FileModalError = () => {
  const { t } = useTranslation()

  return (
    <>
      <WppTypography slot="header" type="2xl-heading" />
      <Flex slot="body" style={{ height: '100%' }} direction="column" justify="center" align="center" gap={8}>
        <WppTypography type="m-strong">{t('modals.errors.default.title')}</WppTypography>
        <WppTypography type="xs-body" data-testid="file-details-modal-no-file-text">
          {t('modals.errors.default.description')}
        </WppTypography>
      </Flex>
    </>
  )
}
