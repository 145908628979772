import { MayBeNull } from '@wpp-open/core'
import { HierarchyLevelType } from '@wpp-open/core/types/tenant'

import { Members } from 'types/members/members'

export interface Project {
  id: string
  name: string
  description?: string
  contextWorkspace?: string
  contextHierarchy: ContextHierarchy[]
  type: ProjectType
  startDate?: MayBeNull<string>
  endDate?: MayBeNull<string>
  status: ProjectStatus
  ownerId: string
  createdAt: string

  owner?: Members
  owners?: Members[]
  members?: Members[]
  processType: ProcessType
}

export interface ContextHierarchy {
  title: HierarchyLevelType
  name: MayBeNull<string>
  value: MayBeNull<string>
}

export interface UpdateProjectFormDTO {
  name?: string
  description?: MayBeNull<string>
  contextWorkspace?: string | null
  type?: ProjectType
  processType?: ProcessType
  status?: ProjectStatus
  updateAllTasks?: boolean
}

export type AllHierarchyLevels = Record<HierarchyLevelType, string[]>
export type FilteredHierarchyLevels = Omit<AllHierarchyLevels, HierarchyLevelType.Tenant>

type LowercaseKeys<T> = {
  [K in keyof T as Lowercase<string & K>]: T[K]
}

export interface LowercaseHierarchyLevels extends Partial<LowercaseKeys<AllHierarchyLevels>> {}

export interface ProjectFilter extends LowercaseHierarchyLevels {
  search?: string
  ownership?: ProjectOwnership
  status: ProjectStatus[]
  type: ProjectType[]
  workspace?: string[]
}

export interface TemplateFilter {
  inputText?: string
  processType?: ProcessType
}

export enum ProjectType {
  BLANK = 'BLANK',
  PITCH = 'PITCH',
  CAMPAIGN = 'CAMPAIGN',
  WORKSHOP = 'WORKSHOP',
}

export enum ProcessType {
  LINEAR = 'LINEAR',
  FLUID = 'FLUID',
}

export enum ProjectStatus {
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ProjectOwnership {
  ALL = 'ALL',
  MY = 'MY',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
}
