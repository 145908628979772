import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react'
import { WppDatepicker } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { format } from 'date-fns'
import { useMemo, useRef, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { Calendar } from 'pages/project/components/canvas/components/phase/Calendar'
import styles from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline.module.scss'
import { DATE_FORMAT } from 'utils/dateFormat'

const dateFormat = DATE_FORMAT.DD_MM_YYYY

interface Props {
  startDate?: string
  endDate?: string
  onChange: (dates: Date[]) => void
  defaultOverdueColor?: boolean
}

export const SelectDateInline = ({ startDate, endDate, onChange, defaultOverdueColor }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const datepickerRef = useRef<MayBeNull<HTMLWppDatepickerElement>>(null)

  const instanceRef = useRef(0)

  const mapValue = useMemo(() => {
    instanceRef.current = instanceRef.current + 1
    if (!startDate?.length || !endDate?.length) return undefined
    return [startDate, endDate].map(date => format(new Date(date), dateFormat))
  }, [startDate, endDate])

  // setup Floating UI
  const { x, y, strategy, refs, reference, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(-22), flip({ padding: 10 })],
  })

  const click = useClick(context, { event: 'click' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'dialog' })

  // setup Floating UI
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

  const handleButtonDateClick = async () => {
    setIsOpen(true)

    // Use setTimeout to ensure that the datepicker is shown after state update
    setTimeout(async () => {
      const datepickerInstance = await datepickerRef?.current?.getInstance()
      datepickerInstance?.show()
    }, 50)
  }

  return (
    <>
      <Flex
        className={clsx(styles.dateEmptyLabel, { [styles.dateLabel]: startDate || endDate })}
        {...getReferenceProps({ ref: reference })}
        onClick={handleButtonDateClick}
      >
        <Calendar startDate={startDate} endDate={endDate} defaultOverdueColor={defaultOverdueColor} />
      </Flex>

      <FloatingPortal>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <Flex
              direction="column"
              key={instanceRef.current}
              className={styles.floatingCard}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1000,
              }}
              {...getFloatingProps()}
            >
              <WppDatepicker
                ref={datepickerRef}
                className={styles.datePickerInline}
                value={mapValue}
                range
                locale={{ dateFormat }}
                onClick={e => e.stopPropagation()}
                onWppChange={e => onChange(e.detail.date as Date[])}
                onWppDateClear={() => {
                  if (startDate || endDate) onChange([])
                  // hide datepicker with suppressing hiding error (caused by `WppDatepicker` wrapper around `AirDatepicker`)
                  datepickerRef.current
                    ?.getInstance()
                    .then(airPicker => airPicker?.hide())
                    .catch(() => void 0)
                }}
                data-testid={`inline-datepicker-${mapValue}`}
              />
            </Flex>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  )
}
