import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Node, XYPosition } from 'reactflow'

import { ApplicationMoveRequest } from 'api/canvas/fetchers/moveApplicationApi'
import { useMoveApplicationApi } from 'api/canvas/mutation/useMoveApplicationApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { FluidAppPatchAction } from 'pages/project/components/canvas/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { ActivityItem, ApplicationItem, ContainerType, FluidWorkflow } from 'types/projects/workflow'

export function useUpdateFluidApp({ projectId }: { projectId: string }) {
  const { showToast } = useToast()
  const { t } = useTranslation()

  const { mutateAsync: handleMoveApp, isLoading } = useMoveApplicationApi({
    onMutate: async updatedAppData => {
      const projectFluidQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }]
      await queryClient.cancelQueries(projectFluidQueryKey)

      const previousFluid = queryClient.getQueryData<{ data: FluidWorkflow }>(projectFluidQueryKey)

      const { fluidOptimisticAction } = updatedAppData

      if (previousFluid && fluidOptimisticAction) {
        let updatedData = {
          ...previousFluid.data,
        }

        const rootContainer = previousFluid.data.containers.find(
          container => container.itemId === updatedAppData.activityId,
        )

        const oldContainer = previousFluid.data.containers.find(({ itemId }) => itemId === updatedAppData.id)
        const oldRoot = previousFluid.data.containers.find(({ id }) => id === oldContainer!.rootContainerId)

        switch (fluidOptimisticAction) {
          case FluidAppPatchAction.Reorder:
            // swap items position after movement
            const sortedChildren = previousFluid.data.containers
              .filter(container => container.rootContainerId === rootContainer?.id)
              .sort(({ orderNumberInRoot: orderNumberA }, { orderNumberInRoot: orderNumberB }) => {
                return orderNumberA === orderNumberB ? 0 : orderNumberA! < orderNumberB! ? -1 : 1
              })

            // move element in Array
            const updatedContainer = previousFluid.data.containers.find(
              container => container.itemId === updatedAppData.id,
            )!
            sortedChildren.splice(updatedContainer.orderNumberInRoot!, 1)
            sortedChildren.splice(updatedAppData.orderNumber!, 0, updatedContainer)

            const reorderedMap = Object.fromEntries(
              sortedChildren
                .map((container, idx) => ({
                  ...container,
                  orderNumberInRoot: idx,
                }))
                .map(node => [node.id, node]),
            )

            updatedData = {
              ...previousFluid.data,
              containers: previousFluid.data.containers.map(container => {
                if (reorderedMap[container.itemId]) {
                  return reorderedMap[container.itemId]
                }
                return container
              }),
            }

            break
          case FluidAppPatchAction.MoveIn:
            updatedData = {
              ...previousFluid.data,
              containers: previousFluid.data.containers.map(container => {
                if (container.itemId === updatedAppData.id) {
                  return {
                    ...container,
                    rootContainerId: rootContainer?.id,
                    containerType: ContainerType.NESTED,
                    orderNumberInRoot: updatedAppData.orderNumber,
                  }
                }

                if (
                  container.rootContainerId === rootContainer!.id &&
                  container.orderNumberInRoot! >= updatedAppData.orderNumber!
                ) {
                  return {
                    ...container,
                    orderNumberInRoot: container.orderNumberInRoot! + 1,
                  }
                }

                return container
              }),
              items: previousFluid.data.items.map(item => {
                if (item.id === updatedAppData.activityId) {
                  const application = previousFluid.data.items.find(
                    ({ id }) => id === updatedAppData.id,
                  ) as ApplicationItem

                  return {
                    ...item,
                    items: [
                      ...(item as ActivityItem).items,
                      {
                        id: crypto.randomUUID(),
                        orderNumber: updatedAppData.orderNumber!,
                        activityItemId: updatedAppData.activityId,
                        application,
                      },
                    ],
                  }
                }
                return item
              }),
            }

            // and same for items, as we must keep these 2 structures in sync
            updatedData.items = updatedData.items.map(item => {
              if (item.id === rootContainer?.itemId) {
                const children = (item as ActivityItem).items.map(item => {
                  if (item.application.id !== updatedAppData.id && item.orderNumber >= updatedAppData.orderNumber!) {
                    return {
                      ...item,
                      orderNumber: item.orderNumber + 1,
                    }
                  }

                  return item
                })

                return {
                  ...item,
                  items: children,
                }
              }
              return item
            })

            // update order in the source container, if we have any

            if (oldRoot && oldContainer) {
              updatedData.containers = updatedData.containers.map(container => {
                if (
                  container.rootContainerId! === oldRoot.id &&
                  container.orderNumberInRoot! > oldContainer.orderNumberInRoot!
                ) {
                  return {
                    ...container,
                    orderNumberInRoot: container.orderNumberInRoot! - 1,
                  }
                }
                return container
              })

              // and same for items, as we must keep these 2 structures in sync
              updatedData.items = updatedData.items.map(item => {
                if (item.id === oldRoot?.itemId) {
                  const children = (item as ActivityItem).items
                    .filter(({ application }) => application.id !== updatedAppData.id)
                    .sort(({ orderNumber: orderNumberA }, { orderNumber: orderNumberB }) => {
                      return orderNumberA === orderNumberB ? 0 : orderNumberA < orderNumberB ? -1 : 1
                    })
                    .map((item, idx) => ({
                      ...item,
                      orderNumber: idx,
                    }))

                  return {
                    ...item,
                    items: children,
                  }
                }
                return item
              })
            }

            break

          case FluidAppPatchAction.MoveOut:
            updatedData = {
              ...previousFluid.data,
              // update pulled out node
              containers: previousFluid.data.containers.map(container => {
                if (container.itemId === updatedAppData.id) {
                  return {
                    ...container,
                    rootContainerId: undefined,
                    containerType: ContainerType.ROOT,
                    coordinateX: updatedAppData.coordinateX!,
                    coordinateY: updatedAppData.coordinateY!,
                  }
                }
                return container
              }),
              items: previousFluid.data.items.map(item => {
                if (item.id === oldRoot?.itemId) {
                  return {
                    ...item,
                    items: (item as ActivityItem).items.filter(
                      ({ application }) => application.id !== updatedAppData.id,
                    ),
                  }
                }
                return item
              }),
            }

            // keep orderNumber sequence continuous (no gap)
            updatedData.containers = updatedData.containers.map(container => {
              if (
                container.rootContainerId === oldContainer!.rootContainerId &&
                container.orderNumberInRoot! > oldContainer!.orderNumberInRoot!
              ) {
                return {
                  ...container,
                  orderNumberInRoot: container.orderNumberInRoot! - 1,
                }
              }
              return container
            })

            // and same for items, as we must keep these 2 structures in sync
            updatedData.items = updatedData.items.map(item => {
              if (item.id === oldRoot?.itemId) {
                const children = (item as ActivityItem).items
                  .sort(({ orderNumber: orderNumberA }, { orderNumber: orderNumberB }) => {
                    return orderNumberA === orderNumberB ? 0 : orderNumberA < orderNumberB ? -1 : 1
                  })
                  .map((item, idx) => ({
                    ...item,
                    orderNumber: idx,
                  }))

                return {
                  ...item,
                  items: children,
                }
              }
              return item
            })

            break

          default:
            const _exhaustiveCheck: never = fluidOptimisticAction
            throw new Error(_exhaustiveCheck)
        }

        queryClient.setQueryData(projectFluidQueryKey, { ...previousFluid, data: updatedData })
      }

      return { previousFluid }
    },
    onError: (_error, _, context) => {
      if (context?.previousFluid) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }], context.previousFluid)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])
    },
  })

  const moveApp = useCallback(
    async (droppedNode: Node, position: XYPosition, patchBody: ApplicationMoveRequest) => {
      try {
        await handleMoveApp({ ...patchBody })
        droppedNode.data.oldPosition = { ...position }
      } catch (error) {
        showToast({
          type: 'error',
          message: t('common.generic_error'),
        })
      }
    },
    [handleMoveApp, showToast, t],
  )

  return { moveApp, isLoading }
}
