import { projectApi } from 'api'
import { FluidTemplateDetail, LinearTemplateDetail } from 'types/projects/workflowTemplate'

export type TemplateDetail = LinearTemplateDetail | FluidTemplateDetail

interface Props {
  id: string
}

export const fetchWorkflowTemplateByIdApi = ({ id }: Props) =>
  projectApi.get<TemplateDetail>(`/workflow-templates/${id}`)
