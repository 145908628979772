import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  maxToShow: number
}

export const ShowMoreItems = ({ children, maxToShow }: Props & { children: ReactNode[] }) => {
  const { t } = useTranslation()
  const [showAll, setShowAll] = useState(false)

  const childrenToRender = children.length <= maxToShow ? children : children.slice(0, maxToShow)
  const btn = showAll ? (
    <WppActionButton onClick={() => setShowAll(false)}>
      <WppIconChevron direction="up" slot="icon-start" />
      {t('project.canvas.show_less')}
    </WppActionButton>
  ) : (
    <WppActionButton onClick={() => setShowAll(true)} data-testid="show-more-button">
      +&nbsp;
      {`${children.length - maxToShow} more`}
    </WppActionButton>
  )

  return (
    <>
      {showAll ? children : childrenToRender}
      {children.length > maxToShow && btn}
    </>
  )
}
