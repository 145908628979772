import styles from 'components/svg/common.module.scss'

export const Svg404Icon = (props: JSX.IntrinsicElements['svg']) => (
  <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="60" cy="60" r="60" className={styles.fillPrimary100} />
    <g opacity="0.89" filter="url(#filter0_d_62387_16073)">
      <path
        d="M18.75 36C18.75 32.6863 21.4363 30 24.75 30H95.25C98.5637 30 101.25 32.6863 101.25 36V84C101.25 87.3137 98.5637 90 95.25 90H24.75C21.4363 90 18.75 87.3137 18.75 84V36Z"
        className={styles.fillWhite}
      />
    </g>
    <path
      d="M18.75 36C18.75 32.6863 21.4363 30 24.75 30H95.25C98.5637 30 101.25 32.6863 101.25 36V37.5H18.75V36Z"
      className={styles.fillPrimary300}
    />
    <ellipse cx="10.0936" cy="48" rx="2.24989" ry="2.25" className={styles.fillPrimary300} />
    <path
      d="M63.2541 13.6178C63.5866 12.3839 65.4136 12.3839 65.7462 13.6178L65.9205 14.2645C66.0412 14.7125 66.4129 15.058 66.8835 15.1598L67.2463 15.2382C68.5849 15.5277 68.5849 17.3571 67.2463 17.6465L66.8835 17.725C66.4129 17.8267 66.0412 18.1723 65.9205 18.6202L65.7462 19.2669C65.4136 20.5009 63.5866 20.5009 63.2541 19.2669L63.0798 18.6202C62.9591 18.1723 62.5873 17.8267 62.1167 17.725L61.754 17.6465C60.4153 17.3571 60.4153 15.5277 61.754 15.2382L62.1167 15.1598C62.5873 15.058 62.9591 14.7125 63.0798 14.2645L63.2541 13.6178Z"
      className={styles.fillPrimary300}
    />
    <circle cx="72.75" cy="21.9805" r="1.5" className={styles.fillPrimary400} />
    <path
      d="M40.7736 74.3073V69.7247H30V65.4456L39.8935 50.7571H45.9632V65.4456H48.7552V69.7247H45.9632V74.3073H40.7736ZM35.2199 65.4456H40.7736V57.2212L35.2199 65.4456Z"
      className={styles.fillPrimary600}
    />
    <path
      d="M59.9209 74.7928C53.3961 74.7928 50.9075 69.7247 50.9075 62.5322C50.9075 55.3396 53.214 50.2715 59.9209 50.2715C66.901 50.2715 68.9951 55.3396 68.9951 62.5322C68.9951 69.7247 66.4762 74.7928 59.9209 74.7928ZM56.1577 69.6033H63.7448V55.461H56.1577V69.6033Z"
      className={styles.fillPrimary600}
    />
    <path
      d="M82.0184 74.3073V69.7247H71.2448V65.4456L81.1383 50.7571H87.208V65.4456H90V69.7247H87.208V74.3073H82.0184ZM76.4647 65.4456H82.0184V57.2212L76.4647 65.4456Z"
      className={styles.fillPrimary600}
    />
    <path
      d="M52.9844 103.272C53.317 102.038 55.1439 102.038 55.4765 103.272L55.6508 103.918C55.7715 104.366 56.1432 104.712 56.6138 104.814L56.9765 104.892C58.3151 105.181 58.3151 107.011 56.9765 107.3L56.6138 107.379C56.1432 107.481 55.7715 107.826 55.6508 108.274L55.4765 108.921C55.1439 110.155 53.317 110.155 52.9844 108.921L52.8102 108.274C52.6894 107.826 52.3177 107.481 51.8472 107.379L51.4844 107.3C50.1458 107.011 50.1458 105.181 51.4844 104.892L51.8472 104.814C52.3177 104.712 52.6894 104.366 52.8102 103.918L52.9844 103.272Z"
      className={styles.fillPrimary400}
    />
    <ellipse cx="73.8436" cy="102" rx="2.24989" ry="2.25" className={styles.fillPrimary200} />
    <defs>
      <filter
        id="filter0_d_62387_16073"
        x="6.75"
        y="22"
        width="106.5"
        height="84"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.709804 0 0 0 0.302 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_62387_16073" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_62387_16073" result="shape" />
      </filter>
    </defs>
  </svg>
)
