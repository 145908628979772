import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { arc, pie, PieArcDatum, select } from 'd3'
import { useLayoutEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/TasksStatusBoard.module.scss'
import {
  ArcValue,
  AVAILABLE_STATUSES,
  calcTranslate,
  colorScale,
  PieData,
  STATUS_MAP,
  STATUSES_ORDER,
} from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/utils'
import { TaskStatus } from 'types/projects/tasks'

interface Props {
  pieName?: string
  height: number
  width: number
  tasksCount: Record<Exclude<TaskStatus, TaskStatus.ARCHIVED>, number>
}

export const PieChart = ({ height, width, tasksCount, pieName }: Props) => {
  const { t } = useTranslation()
  const ref = useRef<SVGSVGElement | null>(null)

  const data: PieData = useMemo(
    () => ({
      name: pieName || t('overview.chart.pie_all_tasks'),
      totalCount: Object.values(tasksCount).reduce((a, b) => a + b),
      values: Object.entries(tasksCount).map(([key, value]) => ({
        type: key as Exclude<TaskStatus, TaskStatus.ARCHIVED>,
        value,
      })),
    }),
    [pieName, t, tasksCount],
  )

  const moreThanOneStatus = useMemo(
    () => Object.entries(tasksCount).filter(([_, value]) => !!value).length >= 2,
    [tasksCount],
  )

  useLayoutEffect(() => {
    const svg = select(ref.current)

    const duration = 250
    const radius = height / 2
    const padding = 18 // animation padding, calcTranslate(v, 6) where v = Pi, so 3.14*6= ~18

    const g = svg
      .append('g')
      .datum(data)
      .style('font-family', 'sans-serif')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)

    const pieChart = pie<ArcValue>()
      .startAngle(0)
      .endAngle(3 * Math.PI)
      .value(d => d.value)
      // @ts-ignore
      .sort((a, b) => STATUSES_ORDER[a.type] - STATUSES_ORDER[b.type])

    const text = g.append('g').attr('text-anchor', 'middle').style('-webkit-perspective', 1000)

    text
      .append('text')
      .attr('class', 'pie-name')
      .attr('data-testid', 'pie-name')
      .text(d => d.name)
      .attr('dy', '-18px')
      .attr('font-size', '14px')
      .attr('font-family', 'Inter')
      .attr('line-height', '22px')

    text
      .append('text')
      .attr('class', 'pie-count')
      .attr('data-testid', 'pie-count')
      .attr('dy', '22px')
      .text(d => d.totalCount)
      .attr('font-size', '36px')
      .attr('font-family', 'Inter')
      .attr('line-height', '48px')

    const arcs = pieChart(data.values)

    const arcG = g
      .selectAll('.arc')
      .data(arcs)
      .enter()
      .append('g')
      .attr('class', d => `arc arc_${d.data.type.toLowerCase()}`)
      .attr('data-testid', d => `arc_${d.data.type.toLowerCase()}`)
      .style('cursor', 'pointer')
      .style('opacity', 0.8)
      .on('mouseover', (event, v) => {
        select(event.currentTarget)
          .transition()
          .duration(duration)
          .attr('transform', moreThanOneStatus ? calcTranslate(v, 6) : 'translate(0, 0)')
          .style('opacity', 1)

        // @ts-ignore
        select('.pie-name').text(STATUS_MAP[v.data.type])
        select('.pie-count').text(v.data.value)
      })
      .on('mouseout', event => {
        select(event.currentTarget)
          .transition()
          .duration(duration)
          .attr('transform', 'translate(0, 0)')
          .style('opacity', 0.8)

        select('.pie-name').text(data.name)
        select('.pie-count').text(data.totalCount)
      })

    const arcPath = arc<ArcValue>()
      .outerRadius(radius - padding)
      .innerRadius((radius - padding) / 1.5)

    arcG
      .append('path')
      // arcPath build svg path based on datum, due to TS types mismatch better to cast it as string
      .attr('d', arcPath as unknown as string)
      .attr('stroke', 'white')
      .attr('fill', (d: PieArcDatum<ArcValue>) => colorScale(d.data.type) as string)

    return () => {
      svg.selectAll('*').remove()
    }
  }, [height, width, data, moreThanOneStatus])

  return (
    <Flex justify="center" direction="column" align="center">
      <svg id="chart" ref={ref} viewBox={`0 0 ${width} ${height}`} width={width} height={height} />
      <Flex justify="center" gap={16}>
        {AVAILABLE_STATUSES.map(status => (
          <Flex gap={6} key={status} align="center">
            <div className={clsx(styles.circle, styles[status])} />
            <WppTypography data-testid={`pie-legend-${status.toLowerCase()}`} type="xs-body">
              {STATUS_MAP[status]}
            </WppTypography>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
