import { WppButton, WppTypography, WppIconError, WppActionButton } from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { AxiosError } from 'axios'
import { useCallback, useState, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUpdateProjectApi } from 'api/projects/mutation/useUpdateProjectApi'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { Modal } from 'components/surface/modal/Modal'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useForm } from 'hooks/form/useForm'
import { useHierarchy } from 'hooks/useHierarchy'
import { useToast } from 'hooks/useToast'
import { ProjectForm } from 'pages/components/projectModal/components/projectForm/ProjectForm'
import { normalizeProjectFormDate, getInitialProjectFormDate } from 'pages/components/projectModal/utils'
import { modalValidationScheme } from 'pages/dashboard/components/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { Project } from 'types/projects/projects'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { findNavigationTreeParentNodeAzId, findNavigationTreeType } from 'utils/navigationTree'

interface Props extends NiceModalWrappedProps {
  project: Project
}

const EditProjectModal = ({ isOpen, onClose, onCloseComplete, project }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const [showDataLossWarning, setShowDataLossWarning] = useState(false)
  const {
    osContext: { navigationTree },
  } = useOs()

  const { hierarchyOrder } = useHierarchy()
  const { mapping } = navigationTree

  const { mutateAsync: updateProject } = useUpdateProjectApi()
  const { name, type, description, contextWorkspace, processType, startDate, endDate, contextHierarchy } = project

  const workspaceData = findNavigationTreeParentNodeAzId(contextWorkspace, mapping)
  const workspaceFormFields = hierarchyOrder.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.toLowerCase()]:
        findNavigationTreeType(currentValue.toUpperCase() as HierarchyLevelType, workspaceData)?.azId || '',
    }),
    {},
  )

  const isWorkspaceValid = !!contextWorkspace && !!workspaceData.length

  const defaultValues = useMemo(
    () => ({
      name,
      type,
      description: description || '',
      processType,
      ...workspaceFormFields,
      dueDate: getInitialProjectFormDate({ startDate, endDate }),
    }),
    [description, workspaceFormFields, name, type, processType, startDate, endDate],
  )

  const form = useForm({ defaultValues, validationSchema: modalValidationScheme })
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
    getValues,
  } = form

  const onSubmit = handleSubmit(async () => {
    await handleSave()
  })

  const handleSave = useCallback(async () => {
    setShowDataLossWarning(false)

    const { type, description, processType, dueDate, name, ...rest } = getValues()

    const selectedWorkspace = hierarchyOrder.map(order => rest[order as keyof typeof rest] || '')
    const contextWorkspace = [...selectedWorkspace].reverse().find(el => el !== '') || ''

    try {
      await updateProject({
        id: project.id,
        project: {
          name: name.trim(),
          type,
          description: description?.trim() || null,
          contextWorkspace: contextWorkspace || null,
          processType,
          ...normalizeProjectFormDate(dueDate),
        },
      })

      await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FETCHER])
      await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS])
      await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_INFINITE])
      tableActions.reload([TableKey.PROJECT_LIST])

      onClose()
      reset()
    } catch (e) {
      const message =
        (e as AxiosError)?.response?.status === 409
          ? t('modals.create_project.toast_error_duplicate')
          : t('modals.create_project.toast_error_common')
      showToast({
        type: 'error',
        message,
      })
      console.error(e)
    }
  }, [getValues, hierarchyOrder, updateProject, project.id, onClose, reset, t, showToast])

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen && !showDataLossWarning}
          formConfig={{ onSubmit }}
          onWppSideModalClose={onClose}
          onWppSideModalCloseComplete={onCloseComplete}
          size="m"
          data-testid="create-project-modal"
        >
          <WppTypography slot="header" type="2xl-heading">
            {t('modals.edit_project.title')}
          </WppTypography>

          <div slot="body">
            <ProjectForm contextHierarchy={isWorkspaceValid ? [] : contextHierarchy} />
          </div>

          <Flex slot="actions" justify="end" gap={12}>
            <WppButton variant="secondary" size="m" onClick={onClose}>
              {t('modals.create_project.btn_cancel')}
            </WppButton>
            <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
              {t('modals.edit_project.btn_save')}
            </WppButton>
          </Flex>
        </SideModal>
      </FormProvider>

      <Modal open={showDataLossWarning} size="s">
        <WppTypography slot="header" type="xl-heading">
          <Flex gap={8} align="center">
            <WppIconError />
            {t('modals.edit_project.draft_confirmation.title')}
          </Flex>
        </WppTypography>
        <WppTypography slot="body" type="s-body">
          {t('modals.edit_project.draft_confirmation.message')}
        </WppTypography>

        <Flex slot="actions" gap={12} justify="end">
          <WppActionButton onClick={() => setShowDataLossWarning(false)} variant="secondary">
            {t('common.btn_no')}
          </WppActionButton>
          <WppButton onClick={handleSave} size="s" variant="destructive">
            {t('common.btn_yes')}
          </WppButton>
        </Flex>
      </Modal>
    </>
  )
}

export const { showModal: showProjectEditModal } = createNiceModal<Props>(EditProjectModal, 'edit-project-modal')
