// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n5qL2::part(card){padding:4px 6px 4px 8px}.n5qL2.DvrCY::part(card){padding:5px 8px;box-shadow:none}._7SPz{overflow:hidden}._7SPz:hover{text-decoration:underline}.aErou{margin-left:auto}.LVkU8{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/item/ExternalLinkView.module.scss"],"names":[],"mappings":"AACE,mBACE,uBAAA,CAIA,yBACE,eAAA,CACA,eAAA,CAKN,OACE,eAAA,CAEA,aACE,yBAAA,CAIJ,OACE,gBAAA,CAGF,OACE,eAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".externalLinkContainer {\n  &::part(card) {\n    padding: 4px 6px 4px 8px;\n  }\n\n  &.preview {\n    &::part(card) {\n      padding: 5px 8px;\n      box-shadow: none;\n    }\n  }\n}\n\n.externalLink {\n  overflow: hidden;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.externalLinkMenu {\n  margin-left: auto;\n}\n\n.textBreak {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"externalLinkContainer": `n5qL2`,
	"preview": `DvrCY`,
	"externalLink": `_7SPz`,
	"externalLinkMenu": `aErou`,
	"textBreak": `LVkU8`
};
export default ___CSS_LOADER_EXPORT___;
