// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yyS6P{padding:4px}.r6ybK{padding:6px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/phase/ResponsiblePerson.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,WAAA","sourcesContent":[".avatarFilled {\n  padding: 4px;\n}\n\n.avatarEmpty {\n  padding: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarFilled": `yyS6P`,
	"avatarEmpty": `r6ybK`
};
export default ___CSS_LOADER_EXPORT___;
